import {
  IExcelResponse,
  IFormattedExcel,
  ReceiptResponseType,
} from 'model/types';
import { addComma } from './common';

export const removeLetter = (text: string) => {
  return text.replace(/[^0-9]/g, '');
};

export const comma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatRefundPrice = (
  originPrice: string,
  paymentPrice: string
) => {
  return +originPrice - +paymentPrice;
};
export const formatPaymentPrice = (num: string) => {
  const vat = Math.floor((+num / 11) * 0.7);
  return vat < 100 ? +num - vat : Math.ceil((+num - vat) / 100) * 100;
};
export const formatNumber = (num: number) => {
  return String(num).length === 1 ? `0${num}` : num;
};
export const formatReceiptForm = (
  data: ReceiptResponseType,
  passportNumber: string
) => {
  return data?.map(
    ({
      category,
      purchaseSn,
      refundAfter,
      businessNumber,
      franchiseeName,
      saleDate,
      sellerName,
      storeAddress,
      storeTelNumber,
      taxFreeStoreNumber,
      totalAmount,
      totalRefund,
      expireDate,
      totalVat,
      barcodeS3Path,
    }) => ({
      purchaseSn,
      refundAfter,
      barcodeS3Path,
      rowDataArr: [
        {
          title: '지정번호',
          subTitle: 'Certificate No.',
          content: taxFreeStoreNumber,
        },
        {
          title: '판매일',
          subTitle: 'Date of Sale',
          content: saleDate,
        },
        {
          title: '판매자',
          subTitle: 'Retailer',
          content: sellerName,
        },
        { title: '상호', subTitle: 'Name of Company', content: franchiseeName },
        { title: '사업자번호', subTitle: 'Reg.No.', content: businessNumber },
        { title: '주소', subTitle: 'Address', content: storeAddress },
        {
          title: '대표자 / 연락처',
          content: `${sellerName} /    ${storeTelNumber}`,
          isLast: true,
        },
        {
          title: '물품구매내역',
          subTitle: 'Description of Goods',
          content: '',
        },
        {
          title: category,
          subTitle: '단가',
          hasComma: true,
          content: addComma(totalAmount),
        },
        { title: '', subTitle: '수량', content: '01' },
        {
          title: '',
          subTitle: '금액',
          hasComma: true,
          content: addComma(totalAmount),
        },
        ...(refundAfter
          ? [{ title: '결제유형', subTitle: '', content: '' }]
          : []),
        {
          title: '판매총액',
          subTitle: 'Total Payment',
          content: addComma(totalAmount),
          hasComma: true,
        },
        {
          title: '부가가치세',
          subTitle: 'V.A.T',
          content: addComma(totalVat),
          hasComma: true,
        },
        ...(refundAfter
          ? [
              {
                title: '개별소비세',
                subTitle: 'I.C.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '교육세',
                subTitle: 'E.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '농어촌특별세',
                subTitle: 'S.T.R.D',
                content: '0',
                hasComma: true,
              },
              {
                title: '새액계',
                subTitle: 'Total Tax',
                content: addComma(totalVat),
                hasComma: true,
              },
              {
                title: `제비용`,
              },
              {
                title: `(송금비용)`,
                subTitle: 'Administrative',
              },
              {
                title: '',
                subTitle: 'Charge',
                content: '',
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: `환급액`,
              },
              {
                title: `(송금세액)`,
                subTitle: 'Net Refund',
              },
              {
                title: '',
                subTitle: 'Value',
                content: addComma(totalRefund),
                hasComma: true,
              },
            ]
          : [
              {
                title: '즉시환급상당액',
                subTitle: 'Tax-free Amount',
                hasComma: true,
                content: addComma(totalRefund),
              },
            ]),
        ...(!refundAfter
          ? [
              {
                title: '결제금액',
                subTitle: 'Purchase Price',
                hasComma: true,
                content: addComma(+totalAmount - +totalRefund),
                isLast: true,
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: '반출유효기간',
                subTitle: 'Export Expiry',
              },
              {
                title: '',
                subTitle: 'Date',
                content: expireDate,
                isLast: true,
              },
            ]
          : []),

        {
          title: '구매자',
          subTitle: 'Customer in Passport',
        },
        {
          title: '여권번호',
          subTitle: 'Passport No.',
          content: passportNumber,
        },
        { title: '성명', subTitle: 'Passport Name', content: '' },
        { title: '국적', subTitle: 'Nationality', content: '' },
        { title: '주소', subTitle: 'Address for Refund', content: '' },
        { title: '', subTitle: '', content: '' },
        { title: '서명', subTitle: 'Signature' },
        {
          info: 'I agree above info is used only for Refund',
          isLast: !refundAfter,
        },
        ...(refundAfter
          ? [
              {
                title: '세관반출확인',
                subTitle: 'Stamp For Verification Of Export',
              },
            ]
          : []),
      ],
    })
  );
};

export const formatPrintReceiptForm = (
  data: ReceiptResponseType,
  passportNumber: string
) => {
  return data?.map(
    ({
      barcodeS3Path,
      category,
      purchaseSn,
      refundAfter,
      businessNumber,
      franchiseeName,
      saleDate,
      sellerName,
      storeAddress,
      storeTelNumber,
      taxFreeStoreNumber,
      totalAmount,
      totalRefund,
      expireDate,
      totalVat,
      refundIndex,
    }) => ({
      refundIndex,
      barcodeS3Path,
      purchaseSn,
      rowDataArr: [
        {
          title: '지정번호',
          subTitle: 'Certificate No.',
          content: taxFreeStoreNumber,
        },
        {
          title: '판매일',
          subTitle: 'Date of Sale',
          content: saleDate,
        },
        {
          title: '판매자',
          subTitle: 'Retailer',
          content: sellerName,
        },
        { title: '상호', subTitle: 'Name of Company', content: franchiseeName },
        { title: '사업자번호', subTitle: 'Reg.No.', content: businessNumber },
        { title: '주소', subTitle: 'Address', content: storeAddress },
        {
          title: '대표자 / 연락처',
          content: `${sellerName} /    ${storeTelNumber}`,
          isLast: true,
        },
        {
          title: '물품구매내역',
          subTitle: 'Description of Goods',
          content: '',
        },
        {
          title: category,
          subTitle: '단가',
          hasComma: true,
          content: addComma(totalAmount),
        },
        { title: '', subTitle: '수량', content: '01' },
        {
          title: '',
          subTitle: '금액',
          hasComma: true,
          content: addComma(totalAmount),
        },
        ...(refundAfter
          ? [{ title: '결제유형', subTitle: '', content: '' }]
          : []),
        {
          title: '판매총액',
          subTitle: 'Total Payment',
          content: addComma(totalAmount),
          hasComma: true,
        },
        {
          title: '부가가치세',
          subTitle: 'V.A.T',
          content: addComma(totalVat),
          hasComma: true,
        },
        ...(refundAfter
          ? [
              {
                title: '개별소비세',
                subTitle: 'I.C.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '교육세',
                subTitle: 'E.T',
                content: '0',
                hasComma: true,
              },
              {
                title: '농어촌특별세',
                subTitle: 'S.T.R.D',
                content: '0',
                hasComma: true,
              },
              {
                title: '새액계',
                subTitle: 'Total Tax',
                content: addComma(totalVat),
                hasComma: true,
              },
              {
                title: `제비용(송금비용)`,
                subTitle: 'Administrative Charge',
                content: '',
              },
            ]
          : []),
        ...(!refundAfter
          ? [
              {
                title: '즉시환급상당액',
                subTitle: 'Tax-free Amount',
                hasComma: true,
                content: addComma(totalRefund),
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: `환급액(송금세액)`,
                subTitle: 'Net Refund Value',
                content: addComma(totalRefund),
                hasComma: true,
              },
            ]
          : []),
        ...(!refundAfter
          ? [
              {
                title: '결제금액',
                subTitle: 'Purchase Price',
                hasComma: true,
                content: addComma(totalAmount),
                isLast: true,
              },
            ]
          : []),
        ...(refundAfter
          ? [
              {
                title: '반출유효기간',
                subTitle: 'Export Expiry Date',
                content: expireDate,
                isLast: true,
              },
            ]
          : []),
        {
          title: '구매자',
          subTitle: 'Customer in Passport',
        },
        {
          title: '여권번호',
          subTitle: 'Passport No.',
          content: passportNumber,
        },
        { title: '성명', subTitle: 'Passport Name', content: '' },
        { title: '국적', subTitle: 'Nationality', content: '' },
        { title: '주소', subTitle: 'Address for Refund', content: '' },
        { title: '', subTitle: '', content: '' },
        { title: '서명', subTitle: 'Signature' },
        {
          info: 'I agree above info is used only for Refund',
          isLast: !refundAfter,
        },
        ...(refundAfter
          ? [
              {
                title: '세관반출확인',
                subTitle: 'Stamp For Verification Of Export',
                content: '',
              },
            ]
          : []),
      ],
    })
  );
};
export const getFormattedTableData = (
  data: IExcelResponse
): IFormattedExcel => {
  const {
    doubleEyeDto,
    rhinoplastyDto,
    breastDto,
    liposuctionDto,
    faceLiftDto,
    facialBoneDto,
    teethWhiteningDto,
    orthognathicDto,
    nevusDto,
    pimpleDto,
    hairRemovalDto,
    hairTransplantationDto,
    tatooDto,
    lipolysisDto,
    skinDto,
    othersDto,
  } = data.productAllDto;

  return {
    ...data,
    productAllDto: [
      {
        label: ' ① 쌍커풀수술 Double Eye-lid',
        price: doubleEyeDto?.price,
        vat: doubleEyeDto?.vat,
      },
      {
        label: ' ② 코성형수술 Rhinoplasty',
        price: rhinoplastyDto?.price,
        vat: rhinoplastyDto?.vat,
      },
      {
        label: ' ③ 유방수술 Breast Surgery',
        price: breastDto?.price,
        vat: breastDto?.vat,
      },
      {
        label: ' ④ 지방흡인술 Liposuction',
        price: liposuctionDto?.price,
        vat: liposuctionDto?.vat,
      },
      {
        label: ' ⑤ 주름살제거술 Face Lift',
        price: faceLiftDto?.price,
        vat: faceLiftDto?.vat,
      },
      {
        label: ' ⑥ 안면윤곽술 Facial bone contouring ',
        price: facialBoneDto?.price,
        vat: facialBoneDto?.vat,
      },
      {
        label: ' ⑦ 치아성형술 Teeth Whitening, Laminate, gingivoplasty',
        price: teethWhiteningDto?.price,
        vat: teethWhiteningDto?.vat,
      },
      {
        label: ' ⑧ 악안면 교정술 Orthognathic surgery',
        price: orthognathicDto?.price,
        vat: orthognathicDto?.vat,
      },
      {
        label:
          ' ⑨ 색소모반ㆍ주근깨ㆍ흑색점ㆍ기미치료술 Nevus pigmentosus, Freckles, Lentigo, Chloasma',
        price: nevusDto?.price,
        vat: nevusDto?.vat,
      },
      {
        label: ' ⑩ 여드름 치료술 Pimple',
        price: pimpleDto?.price,
        vat: pimpleDto?.vat,
      },
      {
        label: ' ⑪ 제모술 Hair removal',
        price: hairRemovalDto?.price,
        vat: hairRemovalDto?.vat,
      },
      {
        label: ' ⑫ 탈모치료술, 모발이식술 Hair loss , Hair transplantation',
        price: hairTransplantationDto?.price,
        vat: hairTransplantationDto?.vat,
      },
      {
        label:
          ' ⑬ 문신술 및 문신제거술, 피어싱 Tatoo, Tattoo removal, piercing ',
        price: tatooDto?.price,
        vat: tatooDto?.vat,
      },
      {
        label: ' ⑭ 지방융해술 Lipolysis',
        price: lipolysisDto?.price,
        vat: lipolysisDto?.vat,
      },
      {
        label: `⑮ 피부재생술, 피부미백술, 항노화치료술 및 모공축소술\nSkin regeneration, Skin Whitening, Anti-aging, Pore tightening`,
        price: skinDto?.price,
        vat: skinDto?.vat,
      },
      {
        label: ' ⑯ 기타(\t\t\t) Others',
        price: othersDto?.price,
        vat: othersDto?.vat,
      },
    ],
  };
};
