import { useHistory } from 'react-router';
import { routes } from 'routes';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import BtnWithIcon from 'components/MedicalReceipt/BtnWithIcon';
import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState, initialGlobalState } from 'store';
import { getIsFrequentCountry } from 'utils/common';

function BottomSection() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { isRefundInfoRegistered, nationality, haveSeenReceipt } =
		useAppSelector((state) => state) as IGlobalState;
	const customerNationToken = localStorage.getItem('customerNation');
	const currNation = isMobile ? customerNationToken : nationality;

	const onClickRefundInfo = () => {
		if (isRefundInfoRegistered) {
			history.push(routes.medicalRefundInfo);
		} else {
			history.push(routes.medicalCustomerRefund);
		}
	};

	const onClickReceiptInquiry = () => {
		dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: true } });
		history.push(routes.medicalReceipt);
	};

	const onOpenLogoutModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: {
				isActive: true,
				type: 'CUSTOM',
				data: {
					title: nationality === 'KOR' ? '로그아웃 할까요?' : 'Sign Out',
					content:
						nationality === 'KOR'
							? '다른 여권번호로 조회하려면 로그아웃 하세요.'
							: `Sign out to look up\nanother passport number.`,
					btnText: nationality === 'KOR' ? '로그아웃' : 'Sign Out',
					subBtnText: nationality === 'KOR' ? '취소' : 'Cancel',
					btnCallback: logout,
				},
			},
		});
	};

	const logout = () => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: initialGlobalState,
		});
		localStorage.removeItem('login');
		localStorage.removeItem('departureDate');
		localStorage.removeItem('customerNation');
		history.push(routes.medical);
	};

	return (
		<Container>
			<MainContent>
				<Title>
					{nationality === 'KOR' ? '환급절차' : 'Tax Refund Process'}
				</Title>
				<RefundProcedure dir="column">
					<BtnWithIcon
						title={
							currNation === 'KOR'
								? `정보 입력 (3개월 이내)`
								: 'Enter information within 3 months'
						}
						description={
							currNation === 'KOR'
								? `${
										isRefundInfoRegistered
											? '세관 확인 후 환급액 지급까지 영업일 기준 21일 정도\n소요됩니다. '
											: '환급액은 고객님 계좌로 지급됩니다.\n송금에 필요한 정보를 3개월 이내 작성해주세요.'
								  }`
								: getIsFrequentCountry(nationality)
								? 'We’ll send your refund to your account.\nComplete your account information.'
								: `We will send an email within 10 days to request the information needed for processing refunds. Please check your mailbox`
						}
						isCompleted={isRefundInfoRegistered}
						onClick={onClickRefundInfo}
						margin="0 0 28px"
					/>
					<BtnWithIcon
						title={
							currNation === 'KOR' ? '환급 전표 보기' : 'View refund receipt'
						}
						description=""
						isCompleted={haveSeenReceipt}
						onClick={onClickReceiptInquiry}
						margin="0 0 28px"
					/>
				</RefundProcedure>
			</MainContent>
			<BtnContainer>
				<TextButton
					title={nationality === 'KOR' ? '로그아웃' : 'Log out'}
					onClick={onOpenLogoutModal}
					color="#1856CD"
					bgColor="#fff"
					outline
				/>
			</BtnContainer>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	overflow-y: auto;
`;
const MainContent = styled.div`
	background-color: #ffffff;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	width: 100%;
	-webkit-box-shadow: 0px 20px 20px -20px rgba(174, 191, 204, 0.5);
	box-shadow: 0px -12px 16px -2px rgba(174, 191, 204, 0.5);
	padding: 36px 20px 0;
	margin-top: 20px;
	border-bottom: 8px solid #f5f6f7;
`;
const Title = styled.h3`
	font-size: 18px;
	line-height: 150%;
	font-weight: 700;
	color: #3a3b3e;
`;
const RefundProcedure = styled(FlexWrap)`
	width: 100%;
	margin-top: 20px;
`;
const BtnContainer = styled.div`
	padding: 20px 20px 56px;
	background-color: #fff;
`;
export default BottomSection;
