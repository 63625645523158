import styled from 'styled-components';
import FlexWrap from 'components/_common/FlexWrap';
import DdayItem from './DdayItem';
import CurrentStatus from './CurrentStatus';
import RefundAmount from './RefundAmount';
import FaqButton from 'components/MedicalFaq/FaqButton';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { IGlobalState } from 'store';
import { useAppSelector } from 'hooks/useReduxHooks';
import ICON_CS from 'assets/common/icon_cs.png';

function TopSection() {
	const { nationality } = useAppSelector((state) => state) as IGlobalState;
	const history = useHistory();

	const goToFaq = () => {
		history.push(routes.medicalFaq);
	};

	return (
		<Container dir="column" justifyContent="center">
			<HeaderWrapper>
				<DdayItem />
				<FaqButton type="button" onClick={goToFaq}>
					<FaqImage src={ICON_CS} alt="" />
					{nationality === 'KOR' ? '문의하기' : 'Inquiry'}
				</FaqButton>
			</HeaderWrapper>
			<CurrentStatus />
			<RefundAmount />
		</Container>
	);
}

export default TopSection;

const Container = styled(FlexWrap)`
	padding: 0 20px 20px;
	width: 375px;
	@media ${({ theme }) => theme.mobile} {
		width: 100%;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 28px;
`;

const FaqImage = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 6px;
`;
