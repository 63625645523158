import FaqButton from 'components/_common/FaqButton';
import ModalFrame from 'components/_common/ModalFrame';
import React, { useState } from 'react';
import styled from 'styled-components';
import ICON_DEPARTURE from 'assets/common/icon_departure.png';
import ICON_CHECKBOX from 'assets/common/icon_check.png';
import ICON_CHECKBOX_FILL from 'assets/common/icon_check_fill.png';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import DepartureCompleteModal from './DepartureComplete.modal';
import { IGlobalState } from 'store';
import DepartureRefundInfoModal from './DepartureRefundInfo.modal';
import { useMutation } from 'react-query';
import { updateKorDeparture } from 'api';
import CalendarModal from '../CalendarModal';

function DepartureConfirmModal() {
	const dispatch = useAppDispatch();
	const [isDeparture, setIsDeparture] = useState(false);
	const [isOpenComplete, setIsOpenComplete] = useState(false);
	const [isOpenCalendar, setIsOpenCalendar] = useState(false);
	const { isRefundInfoRegistered, passportNumber, departureDate } =
		useAppSelector((state) => state) as IGlobalState;

	const onCloseModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};

	const onCloseDepartureModal = () => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: {
				isDepartureCheckRequired: false,
			},
		});
	};

	const { mutate: updateIsDeparture } = useMutation(() => {
		const params = {
			passportNumber: passportNumber!,
			departureDate,
			isDeparted: true,
		};
		return updateKorDeparture(params);
	});

	return (
		<ModalFrame
			title={
				<TitleWrapper>
					<img src={ICON_DEPARTURE} alt="" width={24} height={24} />
					<Title>출국 여부 확인</Title>
				</TitleWrapper>
			}
			content={
				<GuideWrapper>
					<GuideText>환급액 송금을 위해 출국확인이 필요합니다.</GuideText>
					<GuideText>정상적으로 출국하셨다면 출국완료을 눌러주세요.</GuideText>
					<CheckboxWrapper>
						<HideInput
							type="checkbox"
							checked={isDeparture}
							onChange={() => setIsDeparture(!isDeparture)}
						/>
						{isDeparture ? (
							<img src={ICON_CHECKBOX_FILL} alt="" width={24} height={24} />
						) : (
							<img src={ICON_CHECKBOX} alt="" width={24} height={24} />
						)}

						<CheckboxTxt isDeparture={isDeparture}>출국완료</CheckboxTxt>
					</CheckboxWrapper>
				</GuideWrapper>
			}
			buttons={
				<>
					<FaqButton
						status="opacity"
						width="100%"
						padding="10px 0"
						fontSize={16}
						onClick={() => setIsOpenCalendar(true)}
					>
						출국일 변경
					</FaqButton>
					<FaqButton
						status="active"
						width="100%"
						padding="10px 0"
						fontSize={16}
						onClick={() => {
							setIsOpenComplete(true);
							updateIsDeparture();
						}}
						disabled={!isDeparture}
					>
						확인
					</FaqButton>
					{isOpenCalendar && (
						<CalendarModal
							onClose={() => setIsOpenCalendar(false)}
							onPressOk={onCloseDepartureModal}
						/>
					)}
					{isRefundInfoRegistered && isOpenComplete && (
						<DepartureCompleteModal />
					)}
					{!isRefundInfoRegistered && isOpenComplete && (
						<DepartureRefundInfoModal />
					)}
				</>
			}
		/>
	);
}

export default DepartureConfirmModal;

type StyleProps = {
	isDeparture: boolean;
};

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	margin-bottom: 8px;
`;
const Title = styled.strong`
	display: block;
	font-size: 16px;
`;

const GuideWrapper = styled.ul`
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
`;

const GuideText = styled.p`
	color: #5f6165;
	text-align: center;
	font-size: 14px;
	line-height: 150%; /* 21px */
`;

const CheckboxWrapper = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 24px;
	cursor: pointer;
`;

const HideInput = styled.input`
	display: none;
`;

const CheckboxTxt = styled.span<StyleProps>`
	color: ${({ isDeparture }) => (isDeparture ? '#246CF6' : '#cbccce')};
	user-select: none;
`;
