import React, { useState } from 'react';
import styled from 'styled-components';

import ImagePreviewModal from 'components/_common/ImagePreviewModal';
import { IReceiptInfo } from 'types/ktp';

interface IProps {
  info: IReceiptInfo;
}

function Receipt({ info }: IProps) {
  const { file, image } = info;
  const [isPreviewMoal, setIsPreviewModal] = useState(false);

  const onTogglePreview = () => {
    setIsPreviewModal(!isPreviewMoal);
  };

  return (
    <>
      {isPreviewMoal && (
        <ImagePreviewModal
          imgUrl={image}
          onTogglePreview={onTogglePreview}
          setIsPreviewModal={setIsPreviewModal}
        />
      )}
      <ListItem onClick={onTogglePreview}>{file.name}</ListItem>
    </>
  );
}

const ListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #246cf6;
`;

export default Receipt;
