import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { IGlobalState } from 'store';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import { getMedicalRefundInfo, registerMedicalRefundInfo } from 'api';
import BankInfoForm from 'components/MedicalReceipt/BankInfoForm';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import RefundInfoForm from 'components/MedicalReceipt/RefundInfoForm';
import FlexWrap from 'components/_common/FlexWrap';
import LoadingView from 'components/_common/LoadingView';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IRefundInfoForm, IRegisterRefundInfoResponse } from 'model/types';
import Input from 'components/_common/Input';
import { IAddressTranslation } from 'types/ktp';
import { tanslateChFromToEnAddress } from 'utils/translate';
import CashPickupGuideButton from 'components/MedicalCustomerRefund/CashPickupGuideButton';

function MedicalRefundInfo() {
	const [selectedTransfer, setSelectedTransfer] = useState('');
	const [selectedAccountInfo, setSelectedAccountInfo] = useState('');
	const [selectedBank, setSelectedBank] = useState('');
	const [selectedGender, setSelectedGender] = useState('');
	const [selectedAccountType, setSelectedAccountType] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedTown, setSelectedTown] = useState('');
	const [isEditMode, setIsEditMode] = useState(false);
	const [isBankCardInquiry, setIsBankCardInquiry] = useState(false);
	const [isHkgNation, setIsHkgNation] = useState(false);

	const history = useHistory();
	const { passportNumber, nationality, email } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const {
		handleSubmit,
		register,
		getValues,
		watch,
		setValue,
		formState: { errors },
	} = useForm<IRefundInfoForm>();
	const { firstName, lastName, phoneNumber, bank } = watch();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (nationality === 'CHN' || nationality === 'HKG') {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					nationality: isHkgNation ? 'HKG' : 'CHN',
				},
			});
		}
	}, [isHkgNation]);

	useEffect(() => {
		if (!isEditMode) return;
		setValue('aliPayAccount', '');
		setValue('cardNumber', '');
		setValue('bank', '');
		setValue('bankAccount', '');
		setValue('accountInfo', '');
		setValue('routingNumber', '');
		setValue('city', '');
		setValue('stateProvinceRegion', '');
		setValue('zipPostalCode', '');
		setValue('address', '');
		setValue('branchInformation', '');
		setValue('bsbNumber', '');
		setValue('transitNumber', '');
		setValue('cashPickup', '');
		setValue('ibanNumber', '');
		setValue('swiftCode', '');
		setValue('birth', '');
		setValue('addressDetail', '');
		setValue('addressDetail2', '');
		setValue('accountType', '');
		setValue('institutionNumber', '');
		setValue('gender', '');
		setValue('citizenId', '');
		setValue('country', '');
		setValue('firstNameEng', '');
		setValue('lastNameEng', '');
		setValue('accountHolder', '');
		setValue('stateProvinceRegionEng', '');
		setValue('cityEng', '');
		setValue('addressEng', '');
		setValue('addressDetailEng', '');
	}, [selectedTransfer]);

	const { isFetching } = useQuery<any, string>(
		'RefundInfo',
		() => getMedicalRefundInfo(passportNumber),
		{
			retry: false,
			onSuccess: async (data) => {
				setValue('firstName', data.firstName);
				setValue('lastName', data.lastName);
				setValue('phoneNumber', data.phoneNumber);
				setValue('transferMethod', data.transferMethod);
				setValue('aliPayAccount', data.aliPayAccount);
				setValue('cardNumber', data.cardNumber);
				setValue('bank', data.bank);
				setValue('bankAccount', data.bankAccount);
				setValue('accountInfo', data.accountInfo);
				setValue('routingNumber', data.routingNumber);
				setValue('stateProvinceRegion', data.stateProvinceRegion);
				setValue('zipPostalCode', data.zipPostalCode);
				setValue('address', data.address);
				setValue('branchInformation', data.branchInformation);
				setValue('bsbNumber', data.bsbNumber);
				setValue('transitNumber', data.transitNumber);
				setValue('cashPickup', data.cashPickup);
				setValue('ibanNumber', data.ibanNumber);
				setValue('swiftCode', data.swiftCode);
				setValue('birth', data.birth);
				setValue('addressDetail', data.addressDetail);
				setValue('addressDetail2', data.addressDetail2);
				setValue('accountType', data.accountType);
				setValue('institutionNumber', data.institutionNumber);
				setValue('gender', data.gender);
				setValue('citizenId', data.citizenId);
				setValue('country', data.country);
				setValue('firstNameEng', data.firstNameEng);
				setValue('lastNameEng', data.lastNameEng);
				setValue('directInput', data.directInput);
				setValue('accountHolder', data.accountHolder);
				setValue('stateProvinceRegionEng', data.stateProvinceRegionEng);
				setValue('cityEng', data.cityEng);
				setValue('addressEng', data.addressEng);
				setValue('addressDetailEng', data.addressDetailEng);
				setSelectedTransfer(data.transferMethod);
				setSelectedAccountInfo(data.accountInfo);
				setSelectedAccountType(data.accountType);
				setSelectedBank(data.bank);
				setSelectedGender(data.gender);
				setIsBankCardInquiry(data.isBankCardInquiry || true);
				setIsHkgNation(data.isHkgNation || false);
				await setSelectedRegion(data.stateProvinceRegion);
				setValue('city', data.city);
				setSelectedTown(data.city);
				if (nationality === 'TWN') {
					setSelectedBank(data.cashPickup);
				}
			},
		}
	);

	//@ts-ignore
	const { isLoading, mutate: updateMutation } = useMutation<
		IRegisterRefundInfoResponse,
		AxiosError,
		any
	>((payload) => registerMedicalRefundInfo(payload), {
		retry: false,
		onSuccess: () => {
			alert('Refund information\nhas been modified.');
			history.push(routes.medicalHome);
		},
	});

	const onSubmit = async () => {
		const isChineseNationality = nationality === 'CHN';

		let enAddress: IAddressTranslation = {
			stateProvinceRegionEng: '',
			cityEng: '',
			addressEng: '',
			addressDetailEng: '',
		};

		if (isChineseNationality) {
			const { stateProvinceRegion, city, address, addressDetail } = getValues();

			enAddress = (await tanslateChFromToEnAddress(
				stateProvinceRegion,
				city,
				address,
				addressDetail
			)) as IAddressTranslation;
		}

		updateMutation({
			passportNumber,
			accountInfo: {
				...getValues(),
				gender: selectedGender,
				accountType: selectedAccountType,
				accountInfo: selectedAccountInfo,
				isHkgNation,
			},
			register: true,
		});
	};

	return (
		<ContainerWrapper className='notranslate'>
			<ReceiptLayout>
				{isLoading && (
					<LoadingContainer>
						<LoadingView />
					</LoadingContainer>
				)}
				<Title>
					{nationality === 'KOR'
						? `${passportNumber}님의\n송금 정보를 한 번 더 확인해주세요`
						: 'Refund information'}
				</Title>
				<Wrapper>
					<RefundInfoForm
						register={register}
						getValues={getValues}
						setValue={setValue}
						errors={errors}
						disabled={!isEditMode}
						bankName={bank}
						isMedical
					/>
					<Input
						value={email}
						label='Email'
						isRequired
						disabled
						placeholder='Email'
						margin={[0, 0, 24]}
					/>
					{isFetching ? (
						<LoadingView />
					) : (
						<>
							<BankInfoForm
								register={register}
								getValues={getValues}
								errors={errors}
								disabled={!isEditMode}
								selectedTransfer={selectedTransfer}
								setSelectedTransfer={setSelectedTransfer}
								selectedAccountInfo={selectedAccountInfo}
								setSelectedAccountInfo={setSelectedAccountInfo}
								selectedBank={selectedBank}
								setSelectedBank={setSelectedBank}
								selectedAccountType={selectedAccountType}
								setSelectedAccountType={setSelectedAccountType}
								setValue={setValue}
								selectedGender={selectedGender}
								setSelectedGender={setSelectedGender}
								isBankCardInquiry={isBankCardInquiry}
								setIsBankCardInquiry={setIsBankCardInquiry}
								selectedRegion={selectedRegion}
								setSelectedRegion={setSelectedRegion}
								selectedTown={selectedTown}
								setSelectedTown={setSelectedTown}
								isHkgNation={isHkgNation}
								isRefundInfoPage
							/>
							{nationality === 'TWN' && (
								<CashPickupWrapper>
									<CashPickupGuideButton />
								</CashPickupWrapper>
							)}
						</>
					)}
				</Wrapper>
				<ButtonWrapper justifyContent='flex-end'>
					{!isEditMode && (
						<TextButton
							title={nationality === 'KOR' ? '수정' : 'EDIT'}
							onClick={() => setIsEditMode(true)}
							isActive
						/>
					)}
					{isEditMode && (
						<TextButton
							title={nationality === 'KOR' ? '확인' : 'OK'}
							isActive
							disabled={
								!Boolean(firstName && lastName && phoneNumber) ||
								(nationality === 'CHN' && !isBankCardInquiry)
							}
							onClick={handleSubmit(onSubmit)}
							bgColor={
								!Boolean(firstName && lastName && phoneNumber) ||
								(nationality === 'CHN' && !isBankCardInquiry)
									? '#E9F0FE'
									: '#246cf6'
							}
							color={
								!Boolean(firstName && lastName && phoneNumber) ||
								(nationality === 'CHN' && !isBankCardInquiry)
									? '#1856CD'
									: '#E9F0FE'
							}
						/>
					)}
				</ButtonWrapper>
			</ReceiptLayout>
		</ContainerWrapper>
	);
}

export default MedicalRefundInfo;

const ContainerWrapper = styled.div``;
const Title = styled.h2`
	width: 100%;
	font-size: 21px;
	line-height: 150%;
	margin: 30px 0 20px;
	text-align: left;
	color: #1856cd;
	white-space: pre-wrap;
`;
const Wrapper = styled.div`
	width: 335px;
	margin-bottom: 30px;
	overflow: auto;
	padding-bottom: 58px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
	}
`;
const ButtonWrapper = styled(FlexWrap)`
	width: 100%;
	gap: 20px;
	padding: 0 20px;
	position: absolute;
	bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		position: fixed;
	}
`;
const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.6);
`;
const CashPickupWrapper = styled.div`
	margin-bottom: 40px;
`;
