import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import ContainerLayout from 'components/_common/ContainerLayout';
import InnerLayout from 'components/_common/InnerLayout';
import Header from 'components/MedicalReceipt/Header';

interface IProps {
  children?: ReactNode;
  bgColor?: string;
  verticalCenter?: boolean;
  hasPadding?: boolean;
  style?: any;
}
function ReceiptLayout({
  children,
  bgColor,
  verticalCenter = false,
  hasPadding = true,
  style,
}: IProps) {
  return (
    <Container style={style}>
      <Header />
      <ContentContainer
        hasPadding={hasPadding}
        bgColor={bgColor}
        verticalCenter={verticalCenter}
      >
        <Inner>{children}</Inner>
      </ContentContainer>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ContentContainer = styled(ContainerLayout)<{
  bgColor?: string;
  verticalCenter: boolean;
  hasPadding: boolean;
}>`
  position: relative;
  margin: 0 auto;
  width: 375px;
  display: flex;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
  ${(props) =>
    props.verticalCenter &&
    css`
      @media ${(props) => props.theme.desktop} {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    `}
  padding:${(props) => (props.hasPadding ? '90px 20px 0' : '90px 0 0')};
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const Inner = styled(InnerLayout)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default ReceiptLayout;
