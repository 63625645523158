import React, {
	FormEventHandler,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { debounce } from 'lodash-es';

import { checkKorDeparture, medicalPassportValidate } from 'api';
import PassportInputForm from 'components/MedicalPassportValidate/PassportInputForm';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import { IMedicalPassportValidateRespones } from 'model/types';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

export interface IErrorState {
	message: string;
}
function MedicalPassportValidate() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const [passportNumber, setPassportNum] = useState('');
	const [error, setError] = useState<IErrorState | undefined>();

	const {
		passportNumber: currPassportNumber,
		departureDate: currDepartureDate,
	} = useAppSelector((state) => state) as IGlobalState;

	const onClickCofirm = () => {
		history.push(routes.medicalLeaflet, { passportNumber });
	};

	const { mutate: getKorDeparture } = useMutation(
		() => checkKorDeparture(passportNumber),
		{
			onSuccess: ({ isDepartureCheckRequired }) => {
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						isDepartureCheckRequired,
					},
				});
			},
		}
	);

	const medicalPassport = useMutation<
		IMedicalPassportValidateRespones,
		AxiosError,
		string
	>((payload) => medicalPassportValidate(payload), {
		retry: false,
		onSuccess: ({
			existDirectRefund,
			departureDate,
			customerNation,
			hasOverPrice,
			firstRefundDate,
		}) => {
			if (customerNation === 'KOR') getKorDeparture();
			// 전액 결제일때만 저장
			if (existDirectRefund) {
				departureDate &&
					localStorage.setItem('medicalDepartureDate', departureDate);
				customerNation &&
					localStorage.setItem('medicalCustomerNation', customerNation);
			}
			localStorage.setItem('login', passportNumber);

			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					departureDate,
					passportNumber,
					existDirectRefund,
					nationality: customerNation,
					hasOverPrice,
					firstRefundDate,
				},
			});
			if (!existDirectRefund) {
				history.push({
					pathname: routes.medicalReceipt,
				});
				return;
			}
			if (departureDate) {
				history.push({
					pathname: routes.medicalHome,
				});
				return;
			}
			history.push({
				pathname: routes.medicalDeparture,
			});
		},
		onError: ({ data }: any) => {
			setError({
				message: 'Please verify your Passport Number and try again.​​',
			});
			if (data.code === 'T0001') {
				dispatch({
					type: 'UPDATE_HOME_MODAL_STATE',
					payload: {
						isActive: true,
						type: 'CUSTOM',
						data: {
							title: 'Unable to Verify passport.',
							content: `${passportNumber.toUpperCase()}\n\nDid you enter passport number correctly?\nIf yes, press [Confirm] to continue,`,
							subBtnText: 'Cancel',
							btnText: 'Confirm',
							btnCallback: onClickCofirm,
						},
					},
				});
			}
		},
	});

	useEffect(() => {
		if (currPassportNumber && currDepartureDate) {
			history.push('/home');
		}
	}, []);

	const onChangePassport: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		setPassportNum(e.target.value.toUpperCase().replace(/\s+/g, ''));
	};

	// 여러번 여권조회 중복호출 방지
	const debouncedMutation = useCallback(
		debounce((formattedPassportNumber) => {
			medicalPassport.mutate(formattedPassportNumber);
		}, 500),
		[medicalPassport]
	);

	const onSubmitPassportNumber:
		| FormEventHandler<HTMLFormElement>
		| undefined = (e) => {
		e.preventDefault();
		if (!passportNumber) {
			return;
		}
		const formattedPassportNumber = passportNumber
			.toUpperCase()
			.replace(/\s+/g, '')
			.replace(/[\u200B\u200C\u200D\uFEFF]/g, '');
		// 여권조회 호출
		debouncedMutation(formattedPassportNumber);
	};

	return (
		<>
			<ReceiptLayout>
				<Wrapper>
					<PassportInputForm
						onChange={onChangePassport}
						onSubmit={onSubmitPassportNumber}
						passportNumber={passportNumber}
						{...(error && { error })}
					/>
				</Wrapper>
			</ReceiptLayout>
		</>
	);
}

export default MedicalPassportValidate;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 20px;
	padding-bottom: 60px;
`;
