export const transferMethodChn = ['Ali Pay'];

// 24.08.27 중국 은행 목록 변경
// - Bank of China 中国銀行 중국은행
// - Industrial and Commercial Bank of China Limited 中国工商银行 중국공상은행
// - Hua Xia Bank 华夏银行 화하은행
// - Agriculture Bank of China 中国农业银行 중국농업은행
// - China Construction Bank 中国建设银行 중국건설은행

export const chnBankList = [
	'中国银行 BOC',
	'中国工商银行 ICBC',
	'华夏银行 HXB',
	'中国农业银行 ABC',
	'中国建设银行 CCB',
	// '交通银行 BOCOM',
	// '光大银行 CEB',
	// '上海银行 SHB',
	// '临商银行 LSB',
];

export const chnBankTraditionList = [
	'中國銀行 BOC',
	'中國工商銀行 ICBC',
	'華夏銀行 HXB',
	'中國農業銀行 ABC',
	'中國建設銀行 CCB',
	// '交通銀行 BOCOM',
	// '光大銀行 CEB',
	// '上海銀行 SHB',
	// '臨商銀行 LSB',
];

export const chnBankabbreviationList = [
	'BOC',
	'ICBC',
	'HXB',
	'ABC',
	'CCB',
	// 'BOCOM',
	// 'CEB',
	// 'SHB',
	// 'LSB',
];

export const hkgBankList = [
	'AAREAL BANK AG, WIESBADEN, GERMANY [552]',
	'ABN AMRO BANK N.V. [307]',
	'AGRICULTURAL BANK OF CHINA LIMITED FRANKFURT BRANCH [A10]',
	'AGRICULTURAL BANK OF CHINA LIMITED [222]',
	'AGRICULTURAL BANK OF CHINA LIMITED, SINGAPORE BRANCH [340]',
	'AGRICULTURAL BANK OF CHINA, BEIJING [400]',
	'AIRSTAR BANK LIMITED [395]',
	'ALLAHABAD BANK [256]',
	'ALLIED BANKING CORPORATION (HK) LTD. [402]',
	'ANT BANK (HONG KONG) LIMITED [393]',
	'AUSTRALIA AND NEW ZEALAND BANKING GROUP LTD [152]',
	'AXIS BANK LIMITED [261]',
	'BANCA MONTE DEI PASCHI DI SIENA S.P.A. - HONG KONG BRANCH [234]',
	'BANCO BILBAO VIZCAYA ARGENTARIA, S.A. [147]',
	'BANCO DO BRASIL S. A., TOKYO BRANCH [356]',
	'BANCO SANTANDER, S.A. [267]',
	'BANGKOK BANK PUBLIC COMPANY LTD [049]',
	'BANK FOR FOREIGN TRADE OF VIETNAM [553]',
	'BANK J. SAFRA SARASIN LTD [278]',
	'BANK JULIUS BAER & CO. LTD. [320]',
	'BANK OF AMERICA, NATIONAL ASSOCIATION [055]',
	'BANK OF BARODA [259]',
	'BANK OF CHINA (ELUOSI) [283]',
	'BANK OF CHINA (HONG KONG) LIMITED [012]',
	'BANK OF CHINA (HONG KONG) LIMITED [014]',
	'BANK OF CHINA (HONG KONG) LIMITED [019]',
	'BANK OF CHINA (HONG KONG) LIMITED [026]',
	'BANK OF CHINA (HONG KONG) LIMITED [030]',
	'BANK OF CHINA (HONG KONG) LIMITED [031]',
	'BANK OF CHINA (HONG KONG) LIMITED [033]',
	'BANK OF CHINA (HONG KONG) LIMITED [036]',
	'BANK OF CHINA (HONG KONG) LIMITED [064]',
	'BANK OF CHINA (HONG KONG) LIMITED [070]',
	'BANK OF CHINA (HUNGARY) CLOSE LIMITED [290]',
	'BANK OF CHINA (MALAYSIA) BERHAD [282]',
	'BANK OF CHINA LIMITED JAKARTA BRANCH [284]',
	'BANK OF CHINA LIMITED JOHANNESBURG BRANCH [298]',
	'BANK OF CHINA LIMITED LUXEMBOURG BRANCH [301]',
	'BANK OF CHINA LIMITED MANILA BRANCH [279]',
	'BANK OF CHINA LIMITED MILAN BRANCH [291]',
	'BANK OF CHINA LIMITED NEW YORK BRANCH [304]',
	'BANK OF CHINA LIMITED PARIS BRANCH [303]',
	'BANK OF CHINA LIMITED PHNOM PENH BRANCH [327]',
	'BANK OF CHINA LIMITED SEOUL BRANCH [285]',
	'BANK OF CHINA LIMITED SINGAPORE BRANCH [286]',
	'BANK OF CHINA LIMITED SYDNEY BRANCH [288]',
	'BANK OF CHINA LIMITED TAIPEI BRANCH [A00]',
	'BANK OF CHINA LIMITED TOKYO BRANCH [280]',
	'BANK OF CHINA LIMITED, HONG KONG BRANCH [338]',
	'BANK OF CHINA LIMITED, PANAMA BRANCH [311]',
	'BANK OF CHINA LONDON BRANCH [292]',
	'BANK OF CHINA LTD. - ABU DHABI [A12]',
	'BANK OF CHINA, LUXEMBOURG S.A. [300]',
	'BANK OF COMMUNICATIONS CO., LTD. HEAD OFFICE, SHANGHAI [428]',
	'BANK OF COMMUNICATIONS CO., LTD. SHENZHEN BRANCH [429]',
	'BANK OF COMMUNICATIONS CO., LTD. [027]',
	'BANK OF COMMUNICATIONS HONG KONG LIMITED [382]',
	'BANK OF COMMUNICATIONS SEOUL BRANCH [349]',
	'BANK OF HANGZHOU, HANGZHOU [555]',
	'BANK OF INDIA [058]',
	'BANK OF JIANGSU CO. LTD., NANJING [506]',
	'BANK OF MONTREAL [086]',
	'BANK OF MONTREAL, GUANGZHOU BRANCH [542]',
	'BANK OF NINGBO CO. LTD., ZHEJIANG [478]',
	'BANK OF SHANGHAI (HONG KONG) LIMITED [372]',
	'BANK OF SINGAPORE LIMITED [272]',
	'BANK OF TAIWAN [201]',
	'BANK SINOPAC [241]',
	'BANK SINOPAC [244]',
	'BANK SINOPAC, LOS ANGELES BRANCH [355]',
	'BANK SINOPAC, TAIPEI [431]',
	'BARCLAYS BANK PLC [074]',
	'BDO UNIBANK, INC. [067]',
	'BDO UNIBANK, INC. [BNORHKHH]',
	'BNP PARIBAS SECURITIES SERVICES [275]',
	'BNP PARIBAS WEALTH MANAGEMENT [240]',
	'BNP PARIBAS [056]',
	'BOC CREDIT CARD (INTERNATIONAL) LIMITED [252]',
	'BSI LTD [350]',
	'CAMBODIA MEKONG BANK PUBLIC LIMITED [351]',
	'CANADIAN IMPERIAL BANK OF COMMERCE [092]',
	'CANADIAN IMPERIAL BANK OF COMMERCE, CANADA [366]',
	'CANARA BANK [262]',
	'CATHAY BANK [263]',
	'CATHAY UNITED BANK COMPANY, LIMITED [236]',
	'CHANG HWA COMMERCIAL BANK LTD [206]',
	'CHANGSHA CITY COMMERCIAL BANK [529]',
	'CHINA CITIC BANK INTERNATIONAL LIMITED [018]',
	'CHINA CITIC BANK INTERNATIONAL LIMITED [051]',
	'CHINA CITIC BANK, BEIJING [437]',
	'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [009]',
	'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED [CCBQHKAXWHS]',
	'CHINA CONSTRUCTION BANK CORPORATION [221]',
	'CHINA CONSTRUCTION BANK SEOUL BRANCH [358]',
	'CHINA DEVELOPMENT BANK CORPORATION [276]',
	'CHINA EVERBRIGHT BANK CO., LTD [368]',
	'CHINA EVERBRIGHT BANK, BEIJING [434]',
	'CHINA GUANGFA BANK CO. LTD, BEIJING [447]',
	'CHINA GUANGFA BANK CO., LTD. GUANGZHOU [549]',
	'CHINA MERCHANTS BANK CO., LTD, SINGAPORE BRANCH [A09]',
	'CHINA MERCHANTS BANK CO., LTD. [238]',
	'CHINA MERCHANTS BANK, HEAD OFFICE, SHENZHEN [435]',
	'CHINA MINSHENG BANKING CORP., LTD. [353]',
	'CHINA MINSHENG BANKING CORPORATION LTD., BEIJING [436]',
	'CHINA RESOURCES BANK OF ZHUHAI CO LTD., ZHUHAI [507]',
	'CHIYU BANKING CORPORATION LTD [039]',
	'CHONG HING BANK LTD [041]',
	'CIMB BANK BERHAD [374]',
	'CIMB THAI BANK PUBLIC COMPANY LIMITED [343]',
	'CITIBANK (CHINA) CO LTD, SHENZHEN BRANCH [531]',
	'CITIBANK (HONG KONG) LIMITED [250]',
	'CITIBANK N.A. [006]',
	'COMMERZBANK AG [097]',
	'COMMONWEALTH BANK OF AUSTRALIA [153]',
	'CONCORD BANK LTD., NINGBO [524]',
	'CONTINUOUS LINKED SETTLEMENT BANK INTERNATIONAL [868]',
	'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. (TRADIN [323]',
	'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A. [183]',
	'COOPERATIEVE CENTRALE RAIFFEISEN-BOERENLEENBANK B.A.(TRADING [319]',
	'COUTTS & CO LTD [205]',
	'COUTTS AND CO. LTD., SINGAPORE [513]',
	'CREDIT AGRICOLE (SUISSE) S.A. [339]',
	'CREDIT AGRICOLE CIB [CRLYHKHH]',
	'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK [005]',
	'CREDIT SUISSE AG [233]',
	'CTBC BANK CO., LTD. [229]',
	'DAH SING BANK LTD [040]',
	'DBS BANK (HONG KONG) LIMITED [016]',
	'DBS BANK LTD, HONG KONG BRANCH (INCORPORATED IN SINGAPORE WI [DBSSHKHH]',
	'DEUTSCHE BANK AG [054]',
	'DNB NOR BANK ASA, SINGAPORE BRANCH [328]',
	'DONGGUAN RURAL CREDIT COOPERATIVES UNION, DONGGUAN [439]',
	'DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK [113]',
	'E.SUN COMMERCIAL BANK, LTD [243]',
	'E.SUN COMMERCIAL BANK, LTD., TAIPEI [557]',
	'EAST WEST BANK [258]',
	'EDMOND DE ROTHSCHILD (SUISSE) S.A. [346]',
	'EFG BANK AG [237]',
	'ERSTE GROUP BANK AG [227]',
	'EXPORT-IMPORT BANK OF THAILAND, BANGKOK [440]',
	'FALCON PRIVATE BANK LTD. [253]',
	'FAR EASTERN INTERNATIONAL BANK [260]',
	'FINABANK N.V. [362]',
	'FIRST COMMERCIAL BANK [203]',
	'FUBON BANK (HONG KONG) LIMITED [128]',
	'FUSION BANK LIMITED [391]',
	'GRANIT BANK ZRT [325]',
	'GUIYANG COMMERCIAL BANK, GUIYANG [540]',
	'HABIB BANK ZURICH (HONG KONG) LIMITED [322]',
	'HANA BANK [112]',
	'HANG SENG BANK LTD [024]',
	'HDFC BANK LTD. [308]',
	'HKT Payment Limited [935]',
	'HONG KONG MONETARY AUTHORITY [888]',
	'HONG LEONG BANK BERHAD [248]',
	'HSBC - THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD [004]',
	'HSBC BANGLADESH [450]',
	'HSBC BANK (TAIWAN) LIMITED [464]',
	'HSBC BANK MALAYSIA BERHAD [457]',
	'HSBC BANK PLC [347]',
	'HSBC BANK USA, NATIONAL ASSOCIATION [106]',
	'HSBC BANK VIETNAM LTD [466]',
	'HSBC BRUNEI [451]',
	'HSBC INDIA [452]',
	'HSBC INDONESIA [453]',
	'HSBC JAPAN [454]',
	'HSBC MANILA [458]',
	'HSBC PRIVATE BANK (SUISSE) SA [235]',
	'HSBC SEOUL BRANCH [460]',
	'HSBC SINGAPORE [462]',
	'HSBC SRI LANKA [463]',
	'HSBC THAILAND [465]',
	'HUA NAN COMMERCIAL BANK LTD [198]',
	'HUA NAN COMMERCIAL BANK LTD., TAIPEI [550]',
	'HUA XIA BANK, BEIJING [468]',
	'ICBC STANDARD BANK PLC [341]',
	'ICICI BANK LIMITED [251]',
	'INDIAN OVERSEAS BANK [050]',
	'INDIAN OVERSEAS BANK, HONG KONG [IOBAHKHHTST]',
	'INDIAN OVERSEAS BANK, HONG KONG [IOBAHKHH]',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [029]',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LTD [072]',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD [330]',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED [214]',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA, BEIJING [469]',
	'INDUSTRIAL BANK CO., LTD. HONG KONG BRANCH [FJIBHKHH]',
	'INDUSTRIAL BANK CO., LTD. [377]',
	'INDUSTRIAL BANK COMPANY LTD., FUZHOU [445]',
	'INDUSTRIAL BANK OF KOREA [271]',
	'INDUSTRIAL BANK OF KOREA, HONG [IBKOHKHH]',
	'INDUSTRIAL BANK OF TAIWAN CO., LTD. [274]',
	'INDUSTRIAL BANK OF TAIWAN, TAIPEI [470]',
	'ING BANK N.V. [145]',
	'INTESA SANPAOLO S.P.A. [161]',
	'J.P. MORGAN SECURITIES (ASIA PACIFIC) LIMITED [375]',
	'JIANG YIN RURAL COMMERCIAL BANK, JIANG YIN CITY, JIANG SU [541]',
	"JOINT STOCK COMMERCIAL BANK 'LANTA-BANK' (CJSC) [317]",
	'JOINT STOCK COMMERCIAL BANK FOR INVESTMENT AND DEVELOPMENT O [407]',
	'JPMORGAN CHASE BANK, N.A., HONG KONG BRANCH [CHASHKHP863]',
	'JPMORGAN CHASE BANK, N.A., HONG KONG [007]',
	'JSC AB (BANK OF CHINA KAZAKHSTAN) [305]',
	'JSC CAPITAL BANK [A06]',
	'KASIKORNBANK PUBLIC COMPANY LIMITED [380]',
	'KBC BANK N.V. [178]',
	'KDB ASIA LIMITED [318]',
	'KEB ASIA FINANCE LIMITED [363]',
	'KEB HANA BANK [KOEXHKHH]',
	'KOREA EXCHANGE BANK [046]',
	'KOREA EXCHANGE BANK, KOREA [A02]',
	'LAND BANK OF TAIWAN CO., LTD. HONG KONG [LBOTHKHH]',
	'LAND BANK OF TAIWAN CO., LTD. [264]',
	'LGT BANK AG [342]',
	'LGT BANK AG, HONG KONG BRANCH [BLFLHKHH]',
	'LIVI BANK LIMITED [388]',
	'LIVI BANK LIMITED [LIVIHKHHXXX]',
	'LLOYDS TSB PACIFIC LIMITED [LOYDHKHH]',
	'MACQUARIE BANK LIMITED [344]',
	'MANULIFE (INTERNATIONAL) LIMITED [MFCTHKHHXXX]',
	'MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY [379]',
	'MASHREQBANK PSC [352]',
	'MAYBANK (MALAYAN BANKING BERHAD) [063]',
	'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. [242]',
	'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD., HK [ICBCHKHH]',
	'MELLI BANK PLC [254]',
	'METROPOLITAN BANK & TRUST COMPANY [295]',
	'MITSUBISHI UFJ TRUST AND BANKING CORPORATION [138]',
	'MIZUHO BANK, LTD. [109]',
	'MUFG BANK, LTD. [BOTKHKHH]',
	'Mox Bank Limited [389]',
	'NANYANG COMMERCIAL BANK LTD [043]',
	'NATIONAL AUSTRALIA BANK LTD [150]',
	'NATIONAL BANK OF ABU DHABI [277]',
	'NATIONAL BANK OF CANADA [331]',
	'NATIONAL BANK OF PAKISTAN [060]',
	'NATIXIS [210]',
	'OCBC WING HANG BANK LIMITED [035]',
	'OCBC WING HANG BANK LIMITED [044]',
	'ORIENT EXPRESS BANK [367]',
	'ORIX ASIA LIMITED [373]',
	'OVERSEA-CHINESE BANKING CORPORATION LTD [022]',
	'PHILIPPINE NATIONAL BANK [119]',
	'PICTET & CIE (EUROPE) S.A. [357]',
	'PING AN BANK CO., LTD, SHENZHEN [485]',
	'PORTIGON AG [218]',
	'PT BANK CENTRAL ASIA TBK, INDONESIA [281]',
	'PT. BANK MANDIRI (PERSERO) TBK, HONG KONG BRANCH [480]',
	'PT. BANK NEGARA INDONESIA (PERSERO) TBK. [066]',
	'PUBLIC BANK (HONG KONG) LIMITED [028]',
	'PUNJAB NATIONAL BANK [266]',
	'RAIFFEISEN BANK INTERNATIONAL AG [370]',
	'RAIFFEISEN BANK INTERNATIONAL AG, SINGAPORE BRANCH [354]',
	'ROYAL BANK OF CANADA [080]',
	'SAIGON THUONG TIN COMMERCIAL JOINT STOCK BANK [296]',
	'SHANGHAI COMMERCIAL BANK LTD [025]',
	'SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD [345]',
	'SHANGHAI PUDONG DEVELOPMENT BANK [SPDBHKHH]',
	'SHANGHAI PUDONG DEVELOPMENT BANK, SHANGHAI [484]',
	'SHENZHEN DEVELOPMENT BANK, SHENZHEN [486]',
	'SHENZHEN RURAL COMMERCIAL BANK [509]',
	'SHINHAN BANK [273]',
	'SKANDINAVISKA ENSKILDA BANKEN AB [316]',
	'SOCIETE GENERALE BANK & TRUST [255]',
	'SOCIETE GENERALE [081]',
	'STANDARD CHARTERED BANK (HONG KONG) LIMITED - EURO CLEARING [818]',
	'STANDARD CHARTERED BANK (HONG KONG) LIMITED [003]',
	'STANDARD CHARTERED BANK HONG KONG BRANCH [249]',
	'STANDARD CHARTERED BANK PLC, LONDON [536]',
	'STANDARD CHARTERED BANK, BANGKOK [521]',
	'STANDARD CHARTERED BANK, BEIJING BRANCH [489]',
	'STANDARD CHARTERED BANK, JAKARTA BRANCH [515]',
	'STANDARD CHARTERED BANK, MALAYSIA BERHAD [519]',
	'STANDARD CHARTERED BANK, NANJING BRANCH [490]',
	'STANDARD CHARTERED BANK, PHILIPPINES BRANCH [517]',
	'STANDARD CHARTERED BANK, SEOUL KOREA [518]',
	'STANDARD CHARTERED BANK, SHANGHAI BRANCH [491]',
	'STANDARD CHARTERED BANK, SHENZHEN BRANCH [492]',
	'STANDARD CHARTERED BANK, SINGAPORE BRANCH [516]',
	'STANDARD CHARTERED BANK, SRI LANKA [522]',
	'STANDARD CHARTERED BANK, TAIWAN [520]',
	'STANDARD CHARTERED BANK, TIANJIN BRANCH [493]',
	'STANDARD CHARTERED BANK, TOKYO [A07]',
	'STANDARD CHARTERED BANK, XIAMEN BRANCH [494]',
	'STANDARD CHARTERED BANK, ZHUHAI BRANCH [495]',
	'STATE BANK OF INDIA [082]',
	'STATE STREET BANK AND TRUST COMPANY [220]',
	'SUMITOMO MITSUI BANKING CORPORATION [065]',
	'SUMITOMO MITSUI TRUST BANK, LIMITED [371]',
	'SUNNY BANK, TAIPEI [496]',
	'SVENSKA HANDELSBANKEN AB (PUBL) [165]',
	'TA CHONG BANK, LTD. [378]',
	'TAI SANG BANK LTD [061]',
	'TAI YAU BANK LTD [038]',
	'TAICHUNG COMMERCIAL BANK, TAICHUNG [498]',
	'TAIPEI FUBON COMMERCIAL BANK CO., LTD. [239]',
	'TAISHIN INTERNATIONAL BANK CO., LTD [245]',
	'TAISHIN INTERNATIONAL BANK, TAIPEI [499]',
	'TAIWAN BUSINESS BANK HONG KONG BRANCH [230]',
	'TAIWAN BUSINESS BANK HONG KONG BRANCH [MBBTHKHH]',
	'TAIWAN COOPERATIVE BANK, HONG KONG BRANCH [TACBHKHH]',
	'TAIWAN COOPERATIVE BANK, LTD. [265]',
	'TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD. [337]',
	'TAIWAN SHIN KONG COMMERCIAL BANK COMPANY LIMITED, TAIPEI [474]',
	'THE BANK OF BERMUDA LIMITED, HONG KONG BRANCH [502]',
	'THE BANK OF EAST ASIA, LTD [015]',
	'THE BANK OF NEW YORK MELLON [139]',
	'THE BANK OF NOVA SCOTIA [076]',
	'THE BANK OF NOVA SCOTIA, SINGAPORE [329]',
	'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD. [047]',
	'THE CHIBA BANK LTD [170]',
	'THE CHUGOKU BANK LTD [202]',
	'THE HACHIJUNI BANK, LTD [188]',
	'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD - USD CLEA [828]',
	'THE HOUSING BANK FOR TRADE AND FINANCE, JORDAN [554]',
	'THE IYO BANK LTD [224]',
	'THE ROYAL BANK OF SCOTLAND N.V. [360]',
	'THE ROYAL BANK OF SCOTLAND PLC [008]',
	'THE RURAL CREDIT COOPERATIVES UNION OF SHUNDE [528]',
	'THE SHANGHAI COMMERCIAL & SAVINGS BANK LTD [269]',
	'THE SHIGA BANK, LTD. [199]',
	'THE SHIZUOKA BANK, LTD [186]',
	'THE SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED [487]',
	'THE TORONTO-DOMINION BANK [085]',
	'TMB BANK PUBLIC COMPANY LIMITED, BANGKOK [500]',
	'TMB BANK PUBLIC COMPANY LIMITED, HONG KONG [501]',
	'TURK EKONOMI BANKASI A.S. [312]',
	'UBS AG, HONG KONG [103]',
	'UCO BANK [045]',
	'UNICREDIT BANK AG HONG KONG BRANCH [BVBEHKHH]',
	'UNICREDIT BANK AG [164]',
	'UNION BANK OF INDIA [268]',
	'UNITED OVERSEAS BANK LTD [071]',
	'UNITED SUBURBAN AND RURAL CREDIT COOPERATIVE WUXI, WUXI [504]',
	'VIETNAM JOINT STOCK COMMERCIAL BANK FOR INDUSTRY AND TRADE [293]',
	'VIETNAM TECHNOLOGICAL AND COMMERCIAL JOINT STOCK BANK, HANOI [505]',
	'WELAB BANK LIMITED [390]',
	'WELLS FARGO BANK, N.A. [180]',
	'WELLS FARGO BANK, N.A., LONDON BRANCH [297]',
	'WESTPAC BANKING CORPORATION [151]',
	'WING LUNG BANK LTD [020]',
	'WOORI BANK [118]',
	'WOORI BANK, KOREA [A05]',
	'XIAMEN BANK CO., LTD., XIAMEN [547]',
	'ZA BANK LIMITED [387]',
	'ZA BANK LIMITED [AABLHKHH]',
	'ZAO INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MOSCOW) [A04]',
	'ZIBO CITY COMMERCIAL BANK, SHANDONG [525]',
];

type HkgInfoListType = {
	[key: string]: {
		streetList: string[];
	};
};

export const hkgInfoList: HkgInfoListType = {
	Hongkong: {
		streetList: [
			'Central and Western',
			'Eastern',
			'Southern',
			'Wan Chai',
			'Direct Input',
		],
	},
	Kowlong: {
		streetList: [
			'Kowlong City',
			'Kwun Tong',
			'Sham Shui Po',
			'Wong Tai Sin',
			'Yau Tsim Mong',
			'Direct Input',
		],
	},
	'The New Territories': {
		streetList: [
			'Islands',
			'Kwai Tsing',
			'North',
			'Sai Kung',
			'Sha Tin',
			'Tai Po',
			'Tsuen Wan',
			'Then Mun',
			'Yuen Long',
			'Direct Input',
		],
	},
};

export const usaBankList = ['Bank Transfer Anywhere'];

export const jpnBankList = [
	'26 [5448]',
	'27 [0038]',
	'77 Bank [0125]',
	'77 Bank, Ltd. [0125]',
	'加古川市南農業協同組合 [7274]',
	'加茂信用金庫 [1380]',
	'加美よつば農業協同組合 [3710]',
	'加賀農業協同組合 [5943]',
	'肝付吾平町農業協同組合 [9347]',
	'甘楽富岡農業協同組合 [4608]',
	'甲府市農業協同組合 [5222]',
	'甲府信用金庫 [1385]',
	'甲子信用組合 [2272]',
	'甲賀農業協同組合 [6889]',
	'岡崎信用金庫 [1552]',
	'江東信用組合 [2229]',
	'岡山東農業協同組合 [7768]',
	'岡山商銀信用組合 [2673]',
	'岡山西農業協同組合 [7794]',
	'岡山市農業協同組合 [7755]',
	'巨摩野農業協同組合 [5243]',
	'結城信用金庫 [1242]',
	'京都農業協同組合 [6990]',
	'京都丹の国農業協同組合 [6996]',
	'京都府信用農業協同組合連合会 [3026]',
	'京都府信用漁業協同組合連合会 [9475]',
	'京都北都信用金庫 [1620]',
	'京都市農業協同組合 [6941]',
	'京都信用金庫 [1610]',
	'京都銀行 [0158]',
	'京都中央農業協同組合 [6956]',
	'京都中央信用金庫 [1611]',
	'京都やましろ農業協同組合 [6961]',
	'更別村農業協同組合 [3267]',
	'警視庁職員信用組合 [2271]',
	'京葉銀行 [0522]',
	'京滋信用組合 [2526]',
	'計根別農業協同組合 [3350]',
	'堺市農業協同組合 [7111]',
	'高岡市農業協同組合 [5916]',
	'高岡信用金庫 [1402]',
	'高鍋信用金庫 [1985]',
	'高槻市農業協同組合 [7029]',
	'高崎市農業協同組合 [4563]',
	'高崎信用金庫 [1203]',
	'高山信用金庫 [1532]',
	'高松信用金庫 [1830]',
	'高知市農業協同組合 [8551]',
	'高知信用金庫 [1881]',
	'高知銀行 [0578]',
	'高知県信用農業協同組合連合会 [3039]',
	'高知県信用漁業協同組合連合会 [9488]',
	'古川農業協同組合 [3704]',
	'古川信用組合 [2062]',
	'高千穂地区農業協同組合 [9221]',
	'共立信用組合 [2241]',
	'空知商工信用組合 [2019]',
	'空知信用金庫 [1004]',
	'串間市大束農業協同組合 [9181]',
	'館林信用金庫 [1209]',
	'館山信用金庫 [1264]',
	'掛川市農業協同組合 [6386]',
	'掛川信用金庫 [1513]',
	'九個荘農業協同組合 [7191]',
	'久留米市農業協同組合 [8660]',
	'球磨地域農業協同組合 [9048]',
	'玖珠九重農業協同組合 [9137]',
	'九州幸銀信用組合 [2840]',
	'九重町飯田農業協同組合 [9140]',
	'九州労働金庫 [2990]',
	'九州ひぜん信用金庫 [1933]',
	'菊池地域農業協同組合 [8949]',
	'群馬銀行 [0128]',
	'群馬県信用組合 [2146]',
	'群馬県医師信用組合 [2151]',
	'郡山信用金庫 [1182]',
	'君津市農業協同組合 [4909]',
	'君津信用組合 [2190]',
	'宮古信用金庫 [1152]',
	'宮崎信用金庫 [1980]',
	'宮崎銀行 [0184]',
	'宮崎中央農業協同組合 [9169]',
	'宮崎太陽銀行 [0591]',
	'宮崎県南部信用組合 [2884]',
	'宮崎県信用農業協同組合連合会 [3045]',
	'宮崎県信用漁業協同組合連合会 [9494]',
	'宮城第一信用金庫 [1171]',
	'宮城県漁業協同組合 [9453]',
	'近畿産業信用組合 [2567]',
	'近畿労働金庫 [2978]',
	'根上農業協同組合 [5980]',
	'今金町農業協同組合 [3058]',
	'今津町農業協同組合 [6932]',
	'今治立花農業協同組合 [8401]',
	'紀南農業協同組合 [7576]',
	'岐阜商工信用組合 [2470]',
	'岐阜信用金庫 [1530]',
	'岐阜県信用農業協同組合連合会 [3020]',
	'岐阜県医師信用組合 [2473]',
	'紀北信用金庫 [1585]',
	'紀北川上農業協同組合 [7550]',
	'紀陽銀行 [0163]',
	'埼玉岡部農業協同組合 [4820]',
	'埼玉信用組合 [2167]',
	'埼玉中央農業協同組合 [4780]',
	'埼玉縣信用金庫 [1250]',
	'埼玉県信用農業協同組合連合会 [3011]',
	'埼玉県医師信用組合 [2162]',
	'埼玉ひびきの農業協同組合 [4802]',
	'埼玉みずほ農業協同組合 [4859]',
	'埼玉りそな銀行 [0017]',
	'紀州農業協同組合 [7565]',
	'吉備信用金庫 [1741]',
	'金山農業協同組合 [3987]',
	'金沢市農業協同組合 [6025]',
	'金沢信用金庫 [1440]',
	'金沢中央農業協同組合 [6024]',
	'金沢中央信用組合 [2411]',
	'紀の里農業協同組合 [7543]',
	'那須南農業協同組合 [4518]',
	'那須信用組合 [2125]',
	'那須野農業協同組合 [4507]',
	'南都銀行 [0162]',
	'南日本銀行 [0594]',
	'南駿農業協同組合 [6345]',
	'南彩農業協同組合 [4848]',
	'南筑後農業協同組合 [8689]',
	'南幌町農業協同組合 [3161]',
	'南郷信用金庫 [1986]',
	'南さつま農業協同組合 [9257]',
	'南るもい農業協同組合 [3200]',
	'奈良信用金庫 [1666]',
	'奈良中央信用金庫 [1668]',
	'奈良県農業協同組合 [7387]',
	'女満別町農業協同組合 [3321]',
	'農林中央金庫 [3000]',
	'能登わかば農業協同組合 [6094]',
	'能美農業協同組合 [5982]',
	'尼崎信用金庫 [1688]',
	'多古町農業協同組合 [5011]',
	'多摩信用金庫 [1360]',
	'多野藤岡農業協同組合 [4594]',
	'但馬信用金庫 [1692]',
	'但馬銀行 [0164]',
	'但陽信用金庫 [1696]',
	'丹波ささやま農業協同組合 [7362]',
	'丹波ひかみ農業協同組合 [7353]',
	'淡路信用金庫 [1691]',
	'淡路日の出農業協同組合 [7363]',
	'淡陽信用組合 [2616]',
	'唐津農業協同組合 [8766]',
	'唐津信用金庫 [1930]',
	'多気郡農業協同組合 [6697]',
	'大光銀行 [0532]',
	'大東京信用組合 [2248]',
	'大同信用組合 [2540]',
	'大東銀行 [0514]',
	'大牟田柳川信用金庫 [1908]',
	'大北農業協同組合 [5470]',
	'大分大山町農業協同組合 [9145]',
	'大分信用金庫 [1960]',
	'大分銀行 [0183]',
	'大分県農業協同組合 [9104]',
	'大分県信用農業協同組合連合会 [3044]',
	'大分県信用組合 [2870]',
	'大分県漁業協同組合 [9493]',
	'大分みらい信用金庫 [1962]',
	'碓氷安中農業協同組合 [4613]',
	'大潟村農業協同組合 [3929]',
	'大船渡市農業協同組合 [3598]',
	'大樹町農業協同組合 [3269]',
	'大垣共立銀行 [0152]',
	'大垣西濃信用金庫 [1531]',
	'大田原信用金庫 [1225]',
	'大正銀行 [0555]',
	'大井川農業協同組合 [6377]',
	'大津松茂農業協同組合 [8261]',
	'大地みらい信用金庫 [1028]',
	'大川信用金庫 [1917]',
	'大阪南農業協同組合 [7139]',
	'大阪東部農業協同組合 [7184]',
	'大阪府警察信用組合 [2566]',
	'大阪府信用農業協同組合連合会 [3027]',
	'大阪北部農業協同組合 [7041]',
	'大阪府医師信用組合 [2560]',
	'大阪商工信用金庫 [1636]',
	'大阪市農業協同組合 [7200]',
	'大阪信用金庫 [1630]',
	'大阪貯蓄信用組合 [2548]',
	'大阪中河内農業協同組合 [7164]',
	'大阪泉州農業協同組合 [7087]',
	'大阪協栄信用組合 [2543]',
	'大阪厚生信用金庫 [1633]',
	'大阪シティ信用金庫 [1635]',
	'大和信用金庫 [1667]',
	'大和ネクスト銀行 [0041]',
	'島根銀行 [0565]',
	'島根益田信用組合 [2661]',
	'島根中央信用金庫 [1712]',
	'島根県農業協同組合 [7708]',
	'道南うみ街信用金庫 [1014]',
	'渡島信用金庫 [1013]',
	'陶都信用農業協同組合 [6265]',
	'道東あさひ農業協同組合 [3354]',
	'都留信用組合 [2378]',
	'道北なよろ農業協同組合 [3244]',
	'都城農業協同組合 [9184]',
	'都城信用金庫 [1981]',
	'道央農業協同組合 [3139]',
	'島原雲仙農業協同組合 [8829]',
	'島田信用金庫 [1509]',
	'敦賀美方農業協同組合 [6860]',
	'敦賀信用金庫 [1471]',
	'東葛ふたば農業協同組合 [4962]',
	'東京南農業協同組合 [5055]',
	'東京都信用農業協同組合連合会 [3013]',
	'東京都信用漁業協同組合連合会 [9462]',
	'東京都職員信用組合 [2276]',
	'東京東信用金庫 [1320]',
	'東京三協信用金庫 [1333]',
	'東京消防信用組合 [2274]',
	'東京信用金庫 [1349]',
	'東京証券信用組合 [2215]',
	'東京中央農業協同組合 [5094]',
	'東京厚生信用組合 [2224]',
	'東京あおば農業協同組合 [5097]',
	'東京シティ信用金庫 [1311]',
	'東京スター銀行 [0526]',
	'東京スマイル農業協同組合 [5100]',
	'東京ベイ信用金庫 [1262]',
	'東京みどり農業協同組合 [5072]',
	'東京みらい農業協同組合 [5077]',
	'東京むさし農業協同組合 [5087]',
	'東根市農業協同組合 [3962]',
	'東濃信用金庫 [1533]',
	'東能登川農業協同組合 [6909]',
	'東美濃農業協同組合 [6287]',
	'東邦銀行 [0126]',
	'東部農業協同組合 [9240]',
	'東北銀行 [0124]',
	'東北労働金庫 [2954]',
	'東山口信用金庫 [1789]',
	'桐生信用金庫 [1204]',
	'東西しらかわ農業協同組合 [4132]',
	'東信用組合 [2226]',
	'東神楽農業協同組合 [3219]',
	'東予信用金庫 [1864]',
	'東浴信用組合 [2210]',
	'東宇和農業協同組合 [8477]',
	'東旭川農業協同組合 [3220]',
	'東日本銀行 [0525]',
	'東宗谷農業協同組合 [3259]',
	'東川町農業協同組合 [3227]',
	'東春信用金庫 [1566]',
	'東海労働金庫 [2972]',
	'東和銀行 [0516]',
	'東奥信用金庫 [1104]',
	'東栄信用金庫 [1321]',
	'東とくしま農業協同組合 [8234]',
	'東びわこ農業協同組合 [6912]',
	'杜陵信用組合 [2045]',
	'杜の都信用金庫 [1170]',
	'鈴鹿農業協同組合 [6665]',
	'苓北町農業協同組合 [9072]',
	'鹿本農業協同組合 [8941]',
	'鹿沼相互信用金庫 [1223]',
	'鹿追町農業協同組合 [3276]',
	'鹿児島相互信用金庫 [1991]',
	'鹿児島信用金庫 [1990]',
	'鹿児島銀行 [0185]',
	'鹿児島県信用農業協同組合連合会 [3046]',
	'鹿児島県信用漁業協同組合連合会 [9495]',
	'鹿児島県医師信用組合 [2891]',
	'鹿児島興業信用組合 [2890]',
	'鹿児島いずみ農業協同組合 [9302]',
	'鹿児島きもつき農業協同組合 [9341]',
	'瀧野川信用金庫 [1352]',
	'琉球銀行 [0187]',
	'留萌信用金庫 [1022]',
	'柳川農業協同組合 [8680]',
	'陸別町農業協同組合 [3290]',
	'栗東市農業協同組合 [6885]',
	'綾町農業協同組合 [9177]',
	'利根郡信用金庫 [1208]',
	'利根沼田農業協同組合 [4632]',
	'梨北農業協同組合 [5260]',
	'里浦農業協同組合 [8263]',
	'笠岡信用組合 [2674]',
	'馬路村農業協同組合 [8511]',
	'麻植郡農業協同組合 [8305]',
	'摩周湖農業協同組合 [3337]',
	'幕別町農業協同組合 [3282]',
	'網走信用金庫 [1031]',
	'枚方信用金庫 [1656]',
	'糸島農業協同組合 [8635]',
	'糸魚川信用組合 [2366]',
	'名古屋銀行 [0543]',
	'名古屋青果物信用組合 [2444]',
	'名西郡農業協同組合 [8242]',
	'名取岩沼農業協同組合 [3652]',
	'鳴沢村農業協同組合 [5287]',
	'木更津市農業協同組合 [4902]',
	'木野農業協同組合 [3277]',
	'木曽農業協同組合 [5441]',
	'目黒信用金庫 [1346]',
	'夢みなみ農業協同組合 [4091]',
	'鵡川農業協同組合 [3114]',
	'門別町農業協同組合 [3122]',
	'文化産業信用組合 [2211]',
	'武蔵野銀行 [0133]',
	'尾道市農業協同組合 [8029]',
	'尾鈴農業協同組合 [9203]',
	'美馬農業協同組合 [8312]',
	'尾西信用金庫 [1563]',
	'美瑛町農業協同組合 [3228]',
	'米子信用金庫 [1702]',
	'尾張中央農業協同組合 [6451]',
	'美唄市農業協同組合 [3164]',
	'美幌町農業協同組合 [3320]',
	'米沢信用金庫 [1141]',
	'粕屋農業協同組合 [8626]',
	'飯能信用金庫 [1253]',
	'飯田信用金庫 [1394]',
	'半田信用金庫 [1555]',
	'飯塚信用金庫 [1910]',
	'房総信用組合 [2180]',
	'柏崎農業協同組合 [5720]',
	'柏崎信用金庫 [1375]',
	'白山農業協同組合 [6012]',
	'百十四銀行 [0173]',
	'百五銀行 [0155]',
	'白河信用金庫 [1184]',
	'幡多信用金庫 [1880]',
	'碧海信用金庫 [1560]',
	'兵庫南農業協同組合 [7240]',
	'兵庫六甲農業協同組合 [7213]',
	'兵庫西農業協同組合 [7288]',
	'兵庫信用金庫 [1687]',
	'兵庫県警察信用組合 [2602]',
	'兵庫県信用農業協同組合連合会 [3028]',
	'兵庫県信用漁業協同組合連合会 [9477]',
	'兵庫県信用組合 [2606]',
	'兵庫県医療信用組合 [2605]',
	'兵庫ひまわり信用組合 [2620]',
	'兵庫みらい農業協同組合 [7264]',
	'福岡嘉穂農業協同組合 [8701]',
	'福岡京築農業協同組合 [8730]',
	'福岡大城農業協同組合 [8667]',
	'福岡市農業協同組合 [8633]',
	'福岡市東部農業協同組合 [8632]',
	'福岡信用金庫 [1901]',
	'福江信用組合 [2833]',
	'福岡銀行 [0177]',
	'福岡中央銀行 [0582]',
	'福岡八女農業協同組合 [8668]',
	'福岡県南部信用組合 [2763]',
	'福岡県信用農業協同組合連合会 [3040]',
	'福岡県信用漁業協同組合連合会 [9489]',
	'福岡県中央信用組合 [2773]',
	'福岡県医師信用組合 [2753]',
	'福岡県庁信用組合 [2751]',
	'福岡ひびき信用金庫 [1903]',
	'福光農業協同組合 [5935]',
	'福島信用金庫 [1190]',
	'福島銀行 [0513]',
	'福島県商工信用組合 [2090]',
	'福島県信用漁業協同組合連合会 [9456]',
	'福島さくら農業協同組合 [4196]',
	'福邦銀行 [0537]',
	'福山市農業協同組合 [8047]',
	'福井丹南農業協同組合 [6836]',
	'福井市南部農業協同組合 [6789]',
	'福井市農業協同組合 [6785]',
	'福井信用金庫 [1470]',
	'福井銀行 [0147]',
	'福井池田町農業協同組合 [6838]',
	'福井県信用農業協同組合連合会 [3024]',
	'福井県信用漁業協同組合連合会 [9473]',
	'福井県医師信用組合 [2435]',
	'福泉信用組合 [2430]',
	'本渡五和農業協同組合 [9069]',
	'本別町農業協同組合 [3288]',
	'峰延農業協同組合 [3165]',
	'富里市農業協同組合 [4993]',
	'富士宮農業協同組合 [6357]',
	'富士宮信用金庫 [1507]',
	'富士市農業協同組合 [6355]',
	'富士信用金庫 [1515]',
	'富山市農業協同組合 [5897]',
	'富山信用金庫 [1401]',
	'富山銀行 [0145]',
	'富山第一銀行 [0534]',
	'富山県信用漁業協同組合連合会 [9467]',
	'富山県信用組合 [2404]',
	'富山県医師信用組合 [2402]',
	'北見信用金庫 [1030]',
	'北空知信用金庫 [1010]',
	'北九州農業協同組合 [8692]',
	'北九州銀行 [0191]',
	'北國銀行 [0146]',
	'北群馬信用金庫 [1210]',
	'北郡信用組合 [2083]',
	'北群渋川農業協同組合 [4593]',
	'北大阪農業協同組合 [7025]',
	'北都銀行 [0120]',
	'北陸信用金庫 [1444]',
	'北陸銀行 [0144]',
	'北陸労働金庫 [2970]',
	'北門信用金庫 [1008]',
	'北富士農業協同組合 [5284]',
	'北上信用金庫 [1154]',
	'北石狩農業協同組合 [3145]',
	'北星信用金庫 [1024]',
	'北央信用組合 [2011]',
	'北洋銀行 [0501]',
	'北魚沼農業協同組合 [5693]',
	'北越後農業協同組合 [5554]',
	'北伊勢上野信用金庫 [1581]',
	'北日本銀行 [0509]',
	'北宗谷農業協同組合 [3257]',
	'北蒲みなみ農業協同組合 [5541]',
	'北河内農業協同組合 [7193]',
	'北海道信用金庫 [1001]',
	'北海道信用農業協同組合連合会 [3001]',
	'北海道信用漁業協同組合連合会 [9450]',
	'北海道銀行 [0116]',
	'北海道労働金庫 [2951]',
	'北檜山町農業協同組合 [3056]',
	'北いぶき農業協同組合 [3188]',
	'北おおさか信用金庫 [1645]',
	'北オホーツク農業協同組合 [3297]',
	'北さつま農業協同組合 [9296]',
	'北つくば農業協同組合 [4397]',
	'北はるか農業協同組合 [3248]',
	'北ひびき農業協同組合 [3238]',
	'北びわこ農業協同組合 [6924]',
	'備北信用金庫 [1740]',
	'備前信用金庫 [1743]',
	'比布町農業協同組合 [3224]',
	'備後信用組合 [2696]',
	'肥後銀行 [0182]',
	'浜松磐田信用金庫 [1503]',
	'浜中町農業協同組合 [3335]',
	'氷見伏木信用金庫 [1406]',
	'氷見市農業協同組合 [5920]',
	'飛騨農業協同組合 [6313]',
	'飛騨信用組合 [2476]',
	'斜里町農業協同組合 [3329]',
	'士幌町農業協同組合 [3279]',
	'山口銀行 [0170]',
	'山口中央農業協同組合 [8153]',
	'山口県信用農業協同組合連合会 [3035]',
	'山口県信用組合 [2703]',
	'山口県漁業協同組合 [9484]',
	'山梨信用金庫 [1386]',
	'山梨中央銀行 [0142]',
	'山梨県民信用組合 [2377]',
	'山梨県信用農業協同組合連合会 [3015]',
	'山武郡市農業協同組合 [4929]',
	'山陰合同銀行 [0167]',
	'山田村農業協同組合 [5906]',
	'山形農業協同組合 [3932]',
	'山形市農業協同組合 [3931]',
	'山形信用金庫 [1140]',
	'山形銀行 [0122]',
	'山形第一信用組合 [2085]',
	'山形中央信用組合 [2084]',
	'山形おきたま農業協同組合 [3989]',
	'山形もがみ農業協同組合 [3980]',
	'三島信用金庫 [1506]',
	'三島函南農業協同組合 [6333]',
	'三菱東京ＵＦＪ銀行 [0005]',
	'三菱ＵＦＪ信託銀行 [0288]',
	'三菱UFJ銀行(旧:三菱東京UFJ銀行) [0005]',
	'三方原開拓農業協同組合 [6426]',
	'三十三銀行 [0154]',
	'三原農業協同組合 [8027]',
	'三潴町農業協同組合 [8664]',
	'三井住友信託銀行 [0294]',
	'三井住友銀行 [0009]',
	'三條信用組合 [2361]',
	'三重南紀農業協同組合 [6770]',
	'三重北農業協同組合 [6649]',
	'三重銀行 [-]',
	'三重県信用農業協同組合連合会 [3023]',
	'三重県信用漁業協同組合連合会 [9472]',
	'三次農業協同組合 [8069]',
	'三浦市農業協同組合 [5130]',
	'三条信用金庫 [1373]',
	'三ヶ日町農業協同組合 [6423]',
	'商工組合中央金庫 [2004]',
	'湘南農業協同組合 [5137]',
	'湘南信用金庫 [1282]',
	'上都賀農業協同組合 [4456]',
	'常呂町農業協同組合 [3322]',
	'常陸農業協同組合 [4263]',
	'相馬村農業協同組合 [3390]',
	'桑名三重信用金庫 [1583]',
	'相模原市農業協同組合 [5159]',
	'上士幌町農業協同組合 [3280]',
	'相生市農業協同組合 [7316]',
	'相愛信用組合 [2318]',
	'常陽銀行 [0130]',
	'上越信用金庫 [1376]',
	'上伊那農業協同組合 [5384]',
	'上益城農業協同組合 [8982]',
	'上田信用金庫 [1392]',
	'上川中央農業協同組合 [3225]',
	'相双五城信用組合 [2095]',
	'常総ひかり農業協同組合 [4413]',
	'四国銀行 [0175]',
	'四国労働金庫 [2987]',
	'西京信用金庫 [1336]',
	'西京銀行 [0570]',
	'西多摩農業協同組合 [5037]',
	'西都農業協同組合 [9205]',
	'西東京農業協同組合 [5030]',
	'西武信用金庫 [1341]',
	'西美濃農業協同組合 [6175]',
	'西尾信用金庫 [1561]',
	'西兵庫信用金庫 [1694]',
	'西三河農業協同組合 [6560]',
	'西宇和農業協同組合 [8463]',
	'西印旛農業協同組合 [4996]',
	'西日本シティ銀行 [0190]',
	'西中国信用金庫 [1781]',
	'西春日井農業協同組合 [6456]',
	'西八代郡農業協同組合 [5207]',
	'石動信用金庫 [1413]',
	'石狩市農業協同組合 [3142]',
	'夕張市農業協同組合 [3173]',
	'石川県信用農業協同組合連合会 [3019]',
	'石川県信用漁業協同組合連合会 [9468]',
	'石川県医師信用組合 [2417]',
	'石川かほく農業協同組合 [6062]',
	'石巻商工信用組合 [2061]',
	'石巻信用金庫 [1172]',
	'仙南信用金庫 [1174]',
	'仙北信用組合 [2063]',
	'仙台農業協同組合 [3636]',
	'仙台銀行 [0512]',
	'盛岡信用金庫 [1150]',
	'城南信用金庫 [1344]',
	'城北信用金庫 [1351]',
	'成田市農業協同組合 [4992]',
	'成協信用組合 [2541]',
	'西条市農業協同組合 [8395]',
	'西びわこ農業協同組合 [6935]',
	'洗馬農業協同組合 [5462]',
	'世田谷目黒農業協同組合 [5095]',
	'世田谷信用金庫 [1348]',
	'小浜信用金庫 [1473]',
	'小山農業協同組合 [4490]',
	'小松市農業協同組合 [5962]',
	'小松川信用金庫 [1326]',
	'小田原第一信用組合 [2315]',
	'小樽信用金庫 [1016]',
	'沼津信用金庫 [1505]',
	'昭和信用金庫 [1345]',
	'松本市農業協同組合 [5447]',
	'松本信用金庫 [1391]',
	'松本ハイランド農業協同組合 [5448]',
	'松山市農業協同組合 [8425]',
	'松任市農業協同組合 [5997]',
	'小清水町農業協同組合 [3328]',
	'水島信用金庫 [1734]',
	'須賀川信用金庫 [1185]',
	'宿毛商銀信用組合 [2741]',
	'水戸農業協同組合 [4238]',
	'水戸信用金庫 [1240]',
	'水沢信用金庫 [1156]',
	'水郷つくば農業協同組合 [4344]',
	'水郷つくば農協 [4344]',
	'勝英農業協同組合 [7889]',
	'市原市農業協同組合 [4949]',
	'市場町農業協同組合 [8300]',
	'市川市農業協同組合 [4959]',
	'新居浜市農業協同組合 [8397]',
	'新冠町農業協同組合 [3124]',
	'新宮信用金庫 [1671]',
	'信金中央金庫 [1000]',
	'神奈川銀行 [0530]',
	'神奈川県信用農業協同組合連合会 [3014]',
	'神奈川県医師信用組合 [2304]',
	'神奈川県歯科医師信用組合 [2305]',
	'新得町農業協同組合 [3275]',
	'新砂川農業協同組合 [3175]',
	'新生銀行 [0397]',
	'新潟大栄信用組合 [2363]',
	'新潟市農業協同組合 [5864]',
	'新潟信用金庫 [1370]',
	'新潟県信用農業協同組合連合会 [3017]',
	'新潟県信用漁業協同組合連合会 [9466]',
	'新潟縣信用組合 [2351]',
	'新潟県労働金庫 [2965]',
	'新潟鉄道信用組合 [2354]',
	'新潟かがやき農業協同組合 [5600]',
	'新潟みらい農業協同組合 [5577]',
	'新篠津村農業協同組合 [3147]',
	'新岩手農業協同組合 [3517]',
	'新旭町農業協同組合 [6933]',
	'新庄市農業協同組合 [3971]',
	'新庄信用金庫 [1143]',
	'新庄もがみ農業協同組合 [3973]',
	'新田みどり農業協同組合 [4664]',
	'新井信用金庫 [1377]',
	'新湊信用金庫 [1404]',
	'信州諏訪農業協同組合 [5372]',
	'信州うえだ農業協同組合 [5348]',
	'新津さつき農業協同組合 [5585]',
	'新函館農業協同組合 [3068]',
	'神戸市職員信用組合 [2610]',
	'神戸信用金庫 [1680]',
	'新栄信用組合 [2357]',
	'新発田信用金庫 [1374]',
	'新あきた農業協同組合 [3810]',
	'新おたる農業協同組合 [3094]',
	'新ひたち野農業協同組合 [4394]',
	'新みやぎ農業協同組合 [3721]',
	'室蘭信用金庫 [1003]',
	'十六銀行 [0153]',
	'十勝高島農業協同組合 [3285]',
	'十勝信用組合 [2024]',
	'十勝池田町農業協同組合 [3283]',
	'十勝清水町農業協同組合 [3273]',
	'十日町農業協同組合 [5714]',
	'十八親和銀行 [0181]',
	'十和田おいらせ農業協同組合 [3455]',
	'阿南農業協同組合 [8268]',
	'阿南信用金庫 [1803]',
	'阿蘇農業協同組合 [8964]',
	'阿新農業協同組合 [7847]',
	'芽室町農業協同組合 [3271]',
	'阿波郡東部農業協同組合 [8301]',
	'阿波銀行 [0172]',
	'阿波町農業協同組合 [8296]',
	'阿波みよし農業協同組合 [8323]',
	'阿寒農業協同組合 [3338]',
	'安房農業協同組合 [4876]',
	'安芸農業協同組合 [7916]',
	'岩沼市農業協同組合 [3647]',
	'岩手江刺農業協同組合 [3579]',
	'岩手銀行 [0123]',
	'岩手中央農業協同組合 [3541]',
	'岩手県信用農業協同組合連合会 [3003]',
	'岩手県信用漁業協同組合連合会 [9452]',
	'岩手県医師信用組合 [2049]',
	'岩手ふるさと農業協同組合 [3572]',
	'岩井農業協同組合 [4425]',
	'愛媛信用金庫 [1860]',
	'愛媛銀行 [0576]',
	'愛媛県信用農業協同組合連合会 [3038]',
	'愛媛県信用漁業協同組合連合会 [9487]',
	'愛媛たいき農業協同組合 [8457]',
	'愛知東農業協同組合 [6591]',
	'愛知北農業協同組合 [6470]',
	'愛知商銀信用組合 [2442]',
	'愛知西農業協同組合 [6483]',
	'愛知信用金庫 [1550]',
	'愛知銀行 [0542]',
	'愛知県警察信用組合 [2443]',
	'愛知県信用農業協同組合連合会 [3022]',
	'愛知県信用漁業協同組合連合会 [9471]',
	'愛知県中央信用組合 [2451]',
	'愛知県医療信用組合 [2446]',
	'愛知県医師信用組合 [2447]',
	'愛知みなみ農業協同組合 [6615]',
	'野々市農業協同組合 [6010]',
	'野村信託銀行 [0304]',
	'若狭農業協同組合 [6863]',
	'魚沼みなみ農業協同組合 [5707]',
	'御殿場農業協同組合 [6351]',
	'魚津市農業協同組合 [5885]',
	'奄美大島信用金庫 [1993]',
	'奄美信用組合 [2895]',
	'余目町農業協同組合 [4022]',
	'余市町農業協同組合 [3095]',
	'延岡農業協同組合 [9208]',
	'延岡信用金庫 [1982]',
	'塩尻市農業協同組合 [5449]',
	'塩野谷農業協同組合 [4497]',
	'塩沢信用組合 [2365]',
	'永平寺町農業協同組合 [6805]',
	'永和信用金庫 [1643]',
	'烏山信用金庫 [1227]',
	'玉島信用金庫 [1738]',
	'玉名農業協同組合 [8926]',
	'玉名市大浜町農業協同組合 [8934]',
	'玉山 [0632]',
	'湧別町農業協同組合 [3305]',
	'宇都宮農業協同組合 [4445]',
	'羽茂農業協同組合 [5847]',
	'宇和島信用金庫 [1862]',
	'羽後信用金庫 [1123]',
	'旭川信用金庫 [1020]',
	'芸南農業協同組合 [8011]',
	'熊谷商工信用組合 [2165]',
	'熊本市農業協同組合 [8916]',
	'熊本信用金庫 [1951]',
	'熊本宇城農業協同組合 [9010]',
	'熊本銀行 [0587]',
	'熊本第一信用金庫 [1952]',
	'熊本中央信用金庫 [1954]',
	'熊本県信用組合 [2845]',
	'熊本県医師信用組合 [2842]',
	'遠州夢咲農業協同組合 [6387]',
	'遠州信用金庫 [1517]',
	'遠州中央農業協同組合 [6391]',
	'遠賀信用金庫 [1920]',
	'遠軽信用金庫 [1033]',
	'越谷市農業協同組合 [4847]',
	'越前丹生農業協同組合 [6841]',
	'越前信用金庫 [1475]',
	'越前たけふ農業協同組合 [6853]',
	'越智今治農業協同組合 [8400]',
	'月形町農業協同組合 [3168]',
	'越後おぢや農業協同組合 [5690]',
	'越後さんとう農業協同組合 [5685]',
	'越後ながおか農業協同組合 [5666]',
	'音更町農業協同組合 [3278]',
	'邑楽館林農業協同組合 [4677]',
	'鷹巣町農業協同組合 [3771]',
	'伊達市農業協同組合 [3107]',
	'伊達信用金庫 [1009]',
	'伊豆太陽農業協同組合 [6328]',
	'伊豆の国農業協同組合 [6338]',
	'伊万里市農業協同組合 [8771]',
	'伊万里信用金庫 [1932]',
	'二本松信用金庫 [1189]',
	'伊勢農業協同組合 [6731]',
	'伊勢原市農業協同組合 [5139]',
	'伊予銀行 [0174]',
	'伊賀北部農業協同組合 [6758]',
	'益田信用組合 [2481]',
	'日高信用金庫 [1011]',
	'日立市多賀農業協同組合 [4294]',
	'日本海信用金庫 [1711]',
	'日本トラスティ・サービス信託銀行 [0324]',
	'日生信用金庫 [1742]',
	'日新信用金庫 [1689]',
	'日田信用金庫 [1968]',
	'日証金信託銀行 [0321]',
	'一志東部農業協同組合 [6678]',
	'日向農業協同組合 [9213]',
	'一関信用金庫 [1153]',
	'茨木市農業協同組合 [7032]',
	'資産管理サービス信託銀行 [0325]',
	'茨城旭村農業協同組合 [4295]',
	'茨城県信用農業協同組合連合会 [3008]',
	'茨城県信用漁業協同組合連合会 [9457]',
	'茨城県信用組合 [2101]',
	'茨城かすみ農業協同組合 [4324]',
	'茨城みなみ農業協同組合 [4378]',
	'茨城むつみ農業協同組合 [4422]',
	'滋賀銀行 [0157]',
	'滋賀中央信用金庫 [1602]',
	'滋賀蒲生町農業協同組合 [6900]',
	'滋賀県民信用組合 [2504]',
	'滋賀県信用農業協同組合連合会 [3025]',
	'滋賀県信用組合 [2505]',
	'長岡信用金庫 [1371]',
	'長崎三菱信用組合 [2820]',
	'長崎西彼農業協同組合 [8794]',
	'長崎銀行 [0585]',
	'長崎県民信用組合 [2825]',
	'長崎県信用漁業協同組合連合会 [9491]',
	'長崎県央農業協同組合 [8813]',
	'長崎県医師信用組合 [2821]',
	'長浜信用金庫 [1603]',
	'長生農業協同組合 [4916]',
	'長野信用金庫 [1390]',
	'長野銀行 [0533]',
	'長野八ヶ岳農業協同組合 [5311]',
	'長野県信用農業協同組合連合会 [3016]',
	'長野県信用組合 [2390]',
	'長野県労働金庫 [2966]',
	'庄原農業協同組合 [8076]',
	'庄内たがわ農業協同組合 [4013]',
	'庄内みどり農業協同組合 [4027]',
	'赤城橘農業協同組合 [4540]',
	'笛吹農業協同組合 [5199]',
	'前橋市農業協同組合 [4544]',
	'全東栄信用組合 [2202]',
	'田川農業協同組合 [8715]',
	'田川信用金庫 [1913]',
	'全国信用協同組合連合会 [2010]',
	'苫小牧信用金庫 [1006]',
	'苫前町農業協同組合 [3201]',
	'整理回収機構 [2213]',
	'町野町農業協同組合 [6117]',
	'町田市農業協同組合 [5060]',
	'第四北越銀行 [0140]',
	'第四銀行 [0140]',
	'第一勧業信用組合 [2254]',
	'鳥羽志摩農業協同組合 [6741]',
	'朝銀西信用組合 [2672]',
	'朝日新聞信用組合 [2580]',
	'朝日信用金庫 [1303]',
	'銚子商工信用組合 [2184]',
	'銚子信用金庫 [1261]',
	'鳥取西部農業協同組合 [7641]',
	'鳥取信用金庫 [1701]',
	'鳥取銀行 [0166]',
	'鳥取中央農業協同組合 [7625]',
	'鳥取県信用農業協同組合連合会 [3031]',
	'鳥取県信用漁業協同組合連合会 [9480]',
	'鳥取いなば農業協同組合 [7601]',
	'足寄町農業協同組合 [3289]',
	'足利小山信用金庫 [1221]',
	'足利市農業協同組合 [4533]',
	'足利銀行 [0129]',
	'足立成和信用金庫 [1327]',
	'宗谷南農業協同組合 [3261]',
	'宗像農業協同組合 [8621]',
	'種子屋久農業協同組合 [9353]',
	'佐久浅間農業協同組合 [5335]',
	'佐渡農業協同組合 [5832]',
	'佐呂間町農業協同組合 [3303]',
	'佐伯中央農業協同組合 [7938]',
	'佐世保中央信用組合 [2826]',
	'佐野農業協同組合 [4523]',
	'佐野信用金庫 [1224]',
	'佐原農業協同組合 [5002]',
	'佐原信用金庫 [1267]',
	'佐波伊勢崎農業協同組合 [4652]',
	'佐賀共栄銀行 [0583]',
	'佐賀東信用組合 [2803]',
	'佐賀西信用組合 [2808]',
	'佐賀市中央農業協同組合 [8740]',
	'佐賀信用金庫 [1931]',
	'佐賀銀行 [0179]',
	'佐賀県農業協同組合 [8762]',
	'佐賀県信用農業協同組合連合会 [3041]',
	'佐賀県信用漁業協同組合連合会 [9490]',
	'佐賀県医師信用組合 [2802]',
	'周南農業協同組合 [8134]',
	'周桑農業協同組合 [8398]',
	'住信ＳＢＩネット銀行 [0038]',
	'酒田市袖浦農業協同組合 [4036]',
	'珠洲市農業協同組合 [6122]',
	'中巨摩東部農業協同組合 [5234]',
	'中京銀行 [0544]',
	'中南信用金庫 [1290]',
	'中頓別町農業協同組合 [3260]',
	'中兵庫信用金庫 [1695]',
	'中央信用組合 [2556]',
	'中央労働金庫 [2963]',
	'中野市農業協同組合 [5491]',
	'中日信用金庫 [1565]',
	'中札内村農業協同組合 [3266]',
	'中春別農業協同組合 [3358]',
	'中標津町農業協同組合 [3349]',
	'中国銀行 [0168]',
	'中国銀行 [0489]',
	'中国労働金庫 [2984]',
	'中栄信用金庫 [1289]',
	'中ノ郷信用組合 [2235]',
	'知多信用金庫 [1556]',
	'芝信用金庫 [1319]',
	'池田泉州銀行 [0161]',
	'志賀農業協同組合 [6084]',
	'直鞍農業協同組合 [8694]',
	'津久井郡農業協同組合 [5162]',
	'津南町農業協同組合 [5719]',
	'津別町農業協同組合 [3319]',
	'津山農業協同組合 [7868]',
	'津山信用金庫 [1735]',
	'津信用金庫 [1580]',
	'津安芸農業協同組合 [6673]',
	'秦野市農業協同組合 [5140]',
	'榛沢農業協同組合 [4821]',
	'津軽みらい農業協同組合 [3407]',
	'札幌市農業協同組合 [3133]',
	'札幌中央信用組合 [2013]',
	'札内農業協同組合 [3281]',
	'倉吉信用金庫 [1703]',
	'倉敷かさや農業協同組合 [7825]',
	'川口信用金庫 [1251]',
	'川崎信用金庫 [1283]',
	'天童市農業協同組合 [3938]',
	'釧路丹頂農業協同組合 [3339]',
	'釧路信用金庫 [1027]',
	'釧路信用組合 [2025]',
	'釧路太田農業協同組合 [3334]',
	'天白信用農業協同組合 [6436]',
	'川上物産農協 [5330]',
	'天塩町農業協同組合 [3206]',
	'千葉信用金庫 [1260]',
	'千葉銀行 [0134]',
	'千葉県信用漁業協同組合連合会 [9461]',
	'千葉興業銀行 [0135]',
	'千葉みらい農業協同組合 [4954]',
	'川之江信用金庫 [1866]',
	'天草信用金庫 [1955]',
	'晴れの国岡山農業協同組合 [7837]',
	'草津市農業協同組合 [6883]',
	'村上信用金庫 [1379]',
	'諏訪信用金庫 [1393]',
	'萩山口信用金庫 [1780]',
	'秋田信用金庫 [1120]',
	'秋田銀行 [0119]',
	'秋田県信用組合 [2075]',
	'秋田おばこ農業協同組合 [3855]',
	'秋田しんせい農業協同組合 [3825]',
	'秋田ふるさと農業協同組合 [3878]',
	'秋田みなみ農業協同組合 [3805]',
	'秋田やまもと農業協同組合 [3795]',
	'秋川農業協同組合 [5039]',
	'筑邦銀行 [0178]',
	'筑紫農業協同組合 [8636]',
	'筑前あさくら農業協同組合 [8645]',
	'筑波銀行 [0131]',
	'筑後信用金庫 [1909]',
	'春江農業協同組合 [6823]',
	'忠類農業協同組合 [3268]',
	'沖縄銀行 [0188]',
	'沖縄海邦銀行 [0596]',
	'沖縄県農業協同組合 [9375]',
	'沖縄県信用漁業協同組合連合会 [9496]',
	'沖縄県労働金庫 [2997]',
	'七島信用組合 [2243]',
	'七十七銀行 [0125]',
	'稚内農業協同組合 [3254]',
	'稚内信用金庫 [1021]',
	'太田市農業協同組合 [4665]',
	'胎内市農業協同組合 [5568]',
	'台湾中小企業 [0633]',
	'土佐信用組合 [2740]',
	'土佐くろしお農業協同組合 [8589]',
	'土浦農業協同組合 [4357]',
	'播州信用金庫 [1686]',
	'板野郡農業協同組合 [8252]',
	'八代地域農業協同組合 [9017]',
	'八幡信用金庫 [1538]',
	'八十二銀行 [0143]',
	'八王子市農業協同組合 [5050]',
	'八千代市農業協同組合 [4955]',
	'八戸農業協同組合 [3488]',
	'平塚信用金庫 [1286]',
	'蒲郡市農業協同組合 [6606]',
	'蒲郡信用金庫 [1562]',
	'浦幌町農業協同組合 [3287]',
	'標茶町農業協同組合 [3336]',
	'標津町農業協同組合 [3348]',
	'豊頃町農業協同組合 [3286]',
	'豊橋農業協同組合 [6618]',
	'豊橋商工信用組合 [2448]',
	'豊橋信用金庫 [1551]',
	'豊田信用金庫 [1559]',
	'豊川信用金庫 [1557]',
	'豊和銀行 [0590]',
	'下野農業協同組合 [4478]',
	'鶴岡市農業協同組合 [4000]',
	'鶴岡信用金庫 [1142]',
	'鶴来信用金庫 [1445]',
	'函館商工信用組合 [2017]',
	'函館市亀田農業協同組合 [3066]',
	'海部東農業協同組合 [6503]',
	'香川銀行 [0573]',
	'香川県農業協同組合 [8332]',
	'香川県信用農業協同組合連合会 [3037]',
	'香川県信用漁業協同組合連合会 [9486]',
	'香川県信用組合 [2721]',
	'香港上海銀行 [0411]',
	'県央愛川農業協同組合 [5153]',
	'協栄信用組合 [2360]',
	'湖東農業協同組合 [6911]',
	'湖東信用金庫 [1604]',
	'和歌山県信用農業協同組合連合会 [3030]',
	'和歌山県信用漁業協同組合連合会 [9479]',
	'和歌山県医師信用組合 [2634]',
	'花園農業協同組合 [4823]',
	'丸八信用組合 [2440]',
	'幌延町農業協同組合 [3208]',
	'花咲ふくい農業協同組合 [6810]',
	'花巻農業協同組合 [3553]',
	'花巻信用金庫 [1155]',
	'栃木信用金庫 [1222]',
	'栃木銀行 [0517]',
	'厚木市農業協同組合 [5152]',
	'興能信用金庫 [1448]',
	'興産信用金庫 [1305]',
	'興栄信用組合 [2356]',
	'両備信用組合 [2690]',
	'亀有信用金庫 [1323]',
	'会津商工信用組合 [2096]',
	'会津信用金庫 [1181]',
	'会津よつば農業協同組合 [4160]',
	'児湯農業協同組合 [9200]',
	'内浦町農業協同組合 [6121]',
	'労働金庫連合会 [2950]',
	'呉農業協同組合 [7913]',
	'呉市職員信組 [2686]',
	'呉信用金庫 [1752]',
	'壱岐市農業協同組合 [8905]',
	'姫路信用金庫 [1685]',
	'嬬恋村農業協同組合 [4628]',
	'対馬農業協同組合 [8906]',
	'巣鴨信用金庫 [1356]',
	'巻信用組合 [2362]',
	'帯広大正農業協同組合 [3265]',
	'帯広市川西農業協同組合 [3264]',
	'帯広信用金庫 [1026]',
	'広島北部農業協同組合 [7981]',
	'広島商銀信用組合 [2684]',
	'広島市農業協同組合 [7909]',
	'広島市信用組合 [2680]',
	'広島信用金庫 [1750]',
	'広島銀行 [0169]',
	'広島中央農業協同組合 [7994]',
	'広島県信用農業協同組合連合会 [3034]',
	'広島県信用漁業協同組合連合会 [9483]',
	'広島県信用組合 [2681]',
	'広島みどり信用金庫 [1758]',
	'広島ゆたか農業協同組合 [8019]',
	'広尾町農業協同組合 [3270]',
	'当麻農業協同組合 [3223]',
	'徳島北農業協同組合 [8257]',
	'徳島市農業協同組合 [8231]',
	'徳島信用金庫 [1801]',
	'徳島銀行 [0572]',
	'徳島県信用農業協同組合連合会 [3036]',
	'徳島県信用漁業協同組合連合会 [9485]',
	'楽天銀行 [0036]',
	'横浜農業協同組合 [5114]',
	'横浜信用金庫 [1280]',
	'横浜銀行 [0138]',
	'横浜中央信用組合 [2306]',
	'横浜華銀信用組合 [2307]',
	'毎日信用組合 [2581]',
	'気仙沼信用金庫 [1175]',
	'清里町農業協同組合 [3330]',
	'清水農業協同組合 [6363]',
	'清水銀行 [0151]',
	'瀬戸信用金庫 [1554]',
	'真岡信用組合 [2122]',
	'真室川町農業協同組合 [3986]',
	'真庭農業協同組合 [7859]',
	'砺波信用金庫 [1412]',
	'稲敷農業協同組合 [4322]',
	'竜ケ崎農業協同組合 [4344]',
	'緑信用農業協同組合 [6443]',
	'荘内銀行 [0121]',
	'観音寺信用金庫 [1833]',
	'関西みらい銀行 [0159]',
	'関信用金庫 [1534]',
	'青梅信用金庫 [1358]',
	'青木信用金庫 [1252]',
	'青森農業協同組合 [3373]',
	'青森銀行 [0117]',
	'青森県信用漁業協同組合連合会 [9451]',
	'青森県信用組合 [2030]',
	'青和信用組合 [2231]',
	'青い森信用金庫 [1105]',
	'静岡市農業協同組合 [6373]',
	'静岡信用金庫 [1501]',
	'静岡銀行 [0149]',
	'静岡中央銀行 [0538]',
	'静岡県信用農業協同組合連合会 [3021]',
	'静岡県信用漁業協同組合連合会 [9470]',
	'静岡県労働金庫 [2968]',
	'静岡県医師信用組合 [2332]',
	'静清信用金庫 [1502]',
	'黒部市農業協同組合 [5883]',
	'Abashiri Shinkin Bank [1031]',
	'Abukuma Shinkin Bank [1188]',
	'Adachi Seiwa Shinkin Bank [1327]',
	'Aeon Bank [0040]',
	'Aichi Bank [0542]',
	'Aichi Chuo Shinkumi Bank [2451]',
	'Aichi Iryo Shinkumi Bank [2446]',
	'Aichi Ishi Shinkumi Bank [2447]',
	'Aichi Keisatsu Shinkumi Bank [2443]',
	'Aichi Shinkin Bank [1550]',
	'Aichi Shogin Shinkumi Bank [2442]',
	'Aizu Shinkin Bank [1181]',
	'Aizu Shinkumi Bank [2096]',
	'Akagi Shinkumi Bank [2143]',
	'Akita Bank [0119]',
	'Akita Shinkin Bank [1120]',
	'Akita Shinkumi Bank [2075]',
	'Alupuschuo Shinkin Bank [1396]',
	'Amagasaki Shinkin Bank [1688]',
	'Amakusa Shinkin Bank [1955]',
	'Amami Oshima Shinkin Bank [1993]',
	'Amami Shinkumi Bank [2895]',
	'Anan Shinkin Bank [1803]',
	'Aoi Mori Shinkin Bank [1105]',
	'Aoki Shinkin Bank [1252]',
	'Aomori Bank [0117]',
	'Aomoriken Shinkumi Bank [2030]',
	'Aozora Bank [0398]',
	'Arai Shinkin Bank [1377]',
	'Asahi Shinbun Shinkumi Bank [2580]',
	'Asahi Shinkin Bank [1303]',
	'Asahikawa Shinkin Bank [1020]',
	'Ashikaga Bank [0129]',
	'Ashikaga Oyama Shinkin Bank [1221]',
	'Asuka Shinkumi Bank [2060]',
	'Awa Bank [0172]',
	'Awaginko [0172]',
	'Awaji Shinkin Bank [1691]',
	'Azuma Shinkumi Bank [2226]',
	'Bank of America NA [0403]',
	'Bank of Fukuoka [0177]',
	'Bank of Iwate [0123]',
	'Bank of Kochi [0578]',
	'Bank of Minami-Nippon [0594]',
	'Bank of Nagasaki [0585]',
	'Bank of Nagoya [0543]',
	'Bank of Nagoya, Ltd. [0543]',
	'Bank of Okinawa [0188]',
	'Bank of Saga [0179]',
	'Bank of The Ryukyus [0187]',
	'Bank of Tokyo-Mitsubishi UFJ Ltd. [0005]',
	'Bank of Yokohama [0138]',
	'Banshu Shinkin Bank [1686]',
	"Banshushin'yokinko [1686]",
	'Bihoku Agricultural Cooperatives [7837]',
	'Bihoku Shinkin Bank [1740]',
	'Bingo Shinkumi Bank [2696]',
	'Bisai Shinkin Bank [1563]',
	'Bizen Shinkin Bank [1743]',
	'Boso Shinkumi Bank [2180]',
	'Bunka Sangyo Shinkumi Bank [2211]',
	'Chiba Bank [0134]',
	'Chiba Kogyo Bank [0135]',
	'Chiba Shinkin Bank [1260]',
	'Chibahigashikuzu Agricultural Cooperatives [4975]',
	'Chikugo Shinkin Bank [1909]',
	'Chikuho Bank [0178]',
	'Chita Shinkin Bank [1556]',
	'Chogin Nishi Shinkumi Bank [2672]',
	'Choshi Shinkin Bank [1261]',
	'Choshi Shoko Shinkumi Bank [2184]',
	'Chuei Shinkin Bank [1289]',
	'Chugoku Bank [0168]',
	'Chugoku Bank [0489]',
	'Chugoku Labour Bank [2984]',
	'Chukyo Bank [0544]',
	'Chunan Shinkin Bank [1290]',
	'Chunichi Shinkin Bank [1565]',
	'Chuo Labour Bank [2963]',
	'Chuo Shinkumi Bank [2556]',
	'Citibank [0401]',
	'Cosmos Agricultural Cooperatives [8582]',
	'Credit Agricole Bank [0421]',
	'Daichimirai Shinkin Bank [1028]',
	'Daido Shinkumi Bank [2540]',
	'Daiichi Kangyo Shinkin Bank [2254]',
	'Daishi Bank, Ltd. [0140]',
	'Daishi Hokuetsu [0140]',
	'Daito Bank [0514]',
	'Daitokyo Shinkumi Bank [2248]',
	'Daiwa Next Bank [0041]',
	'Danyo Shinkumi Bank [2616]',
	'Date Shinkin [1009]',
	'Datong credit union [2540]',
	'Deutsche Bank [0430]',
	'Dounan Umimachi Shinkin Bank [1014]',
	'E.SUN Commercial Bank [0632]',
	'East Soya Agricultural Cooperatives [3259]',
	'Echizen Shinkin Bank [1475]',
	'Ehime Bank [0576]',
	'Ehime Shinkin Bank [1860]',
	'Eiwa Shinkin Bank [1643]',
	'Engaru Shinkin Bank [1033]',
	'Enshu Shinkin Bank [1517]',
	'First Bank of Toyama [0534]',
	'Fuji Shinkin Bank [1515]',
	'Fujinomiya Shinkin Bank [1507]',
	'Fukue Shinkumi Bank [2833]',
	'Fukuho Bank [0537]',
	'Fukui Bank [0147]',
	'Fukui Ishi Shinkumi Bank [2435]',
	'Fukui Shinkin Bank [1470]',
	'Fukuoka Chuo Bank [0582]',
	'Fukuoka Hibiki Shinkin Bank [1903]',
	'Fukuoka Kencho Shinkumi Bank [2751]',
	'Fukuoka Prefecture central credit union [2773]',
	'Fukuoka Prefecture credit Federation of Fisheries Cooperative Associations [9489]',
	'Fukuoka Shinkin Bank [1901]',
	'Fukuokaken Chuo Shinkumi Bank [2773]',
	'Fukuokaken Ishi Shinkumi Bank [2753]',
	'Fukuokaken Nanbu Shinkumi Bank [2763]',
	'Fukusen Shinkumi Bank [2430]',
	'Fukushima Shinkin Bank [1190]',
	'Fukushima Shoko Shinkumi Bank [2090]',
	'Fukusima Bank [0513]',
	'Furukawa Shinkumi Bank [2062]',
	'Gamagori Shinkin Bank [1562]',
	'Gifu credit union [1530]',
	'Gifu Ishi Shinkumi Bank [2473]',
	'Gifu Shinkin Bank [1530]',
	'Gifu Shoko Shinkumi Bank [2470]',
	'GMO Aozora Net Bank [0310]',
	'ＧＭＯあおぞらネット銀行 [0310]',
	'Gumma Ishi Shinkumi Bank [2151]',
	'Gumma Mirai Shinkumi Bank [2149]',
	'Gumma Shinkumi Bank [2146]',
	'Gunma Bank [0128]',
	'Hachijuni Bank [0143]',
	'Hachiman Shinkin Bank [1538]',
	'Hagi-Yamaguchi Shinkin Bank [1780]',
	'Hakodate Shinkumi Bank [2017]',
	'Hamamatsu Iwata Shinkin Bank [1503]',
	'Hana credit union [2277]',
	'Hana Shinkumi Bank [2277]',
	'Hanamaki Shinkin Bank [1155]',
	'Handa Shinkin Bank [1555]',
	"Handashin'yokinko [1555]",
	'Hanno Shinkin Bank [1253]',
	'Hata credit union [1880]',
	'Hata Shinkin Bank [1880]',
	'Hekikai Shinkin Bank [1560]',
	"Hekikaishin'yokinko [1560]",
	'Hida Shinkumi Bank [2476]',
	'Hidaka Shinkin Bank [1011]',
	'Higashi Yamaguchi Shinkin Bank [1789]',
	'Higashi-Nippon Bank [0525]',
	'Higo Bank [0182]',
	'Himawari Shinkin Bank [1186]',
	'Himeji Shinkin Bank [1685]',
	"Himejishin'yokinko [1685]",
	'Himi Fusiki Shinkin Bank [1406]',
	'Hinase Shinkin Bank [1742]',
	'Hirakata Shinkin Bank [1656]',
	'Hiratsuka Shinkin Bank [1286]',
	'Hiroshima Bank [0169]',
	'Hiroshima credit union [2681]',
	'Hiroshima Midori Shinkin Bank [1758]',
	'Hiroshima Shinkin Bank [1750]',
	'Hiroshima Shogin Shinkimi Bank [2684]',
	'Hiroshimaken Shinkumi Bank [2681]',
	'Hita Shinkin Bank [1968]',
	'Hokkaido Bank [0116]',
	'Hokkaido Labour Bank [2951]',
	'Hokkaido Shinkin Bank [1001]',
	'Hokkoku Bank [0146]',
	'Hokumon Shinkin [1008]',
	'Hokuoh Shinkumi Bank [2011]',
	'Hokuriku Bank [0144]',
	'Hokuriku Labour Bank [2970]',
	'Hokuriku Shinkin Bank [1444]',
	'Hokusei Shinkin Bank [1024]',
	'Hokuto Bank [0120]',
	'Hong Kong and Shanghai Banking Corporation [0411]',
	'Hongkong and Shanghai Banking Corporation Limited [0411]',
	'Horoshimashi Shinkumi Bank [2680]',
	'Howa Bank [0590]',
	'Hyakugo Bank [0155]',
	'Hyakugo Bank, Ltd. [0155]',
	'Hyakujushi Bank [0173]',
	'Hyogo Himawari Shinkumi Bank [2620]',
	'Hyogo Iryo Shinkumi Bank [2605]',
	'Hyogo Keisatsu Shinkumi Bank [2602]',
	'Hyogo Prefecture credit Federation of Fisheries Cooperative Associations [9477]',
	'Hyogo Shinkin Bank [1687]',
	'Hyogo Shinkumi Bank [2606]',
	'Ibarakiken Shinkumi Bank [2101]',
	'Ichii Shinkin Bank [1553]',
	'Ichinoseki Shinkin Bank [1153]',
	'Iida Shinkin Bank [1394]',
	'Iizuka Shinkin Bank [1910]',
	'Imari Shinkin Bank [1932]',
	'ING Groep N.V. [0482]',
	'IO Shinkin Bank [1206]',
	'Io Shinkumi Bank [2471]',
	'Ishikawa Ishi Shinkumi Bank [2417]',
	'Ishinomaki Shinkin Bank [1172]',
	'Ishinomaki Shoko Shinkumi Bank [2061]',
	'Isurugi Shinkin Bank [1413]',
	'Itoigawa Shinkumi Bank [2366]',
	'Iwaki Shinkumi Bank [2092]',
	'Iwate Ishi Shinkumi Bank [2049]',
	'Iyo Bank [0174]',
	"Izukashin'yokinko [1910]",
	'JA Bank Agatsuma [4626]',
	'JA Bank Aichi Ama [6514]',
	'JA Bank Aichi Bito [6466]',
	'JA Bank Aichi Chita [6531]',
	'JA Bank Aichi Chuo [6552]',
	'JA Bank Aichi Higashi [6591]',
	'JA Bank Aichi Kita [6470]',
	'JA Bank Aichi Minami [6615]',
	'JA Bank Aichi Nishi [6483]',
	'JA Bank Aichi Shinren [3022]',
	'JA Bank Aichi Toyota [6582]',
	'JA Bank Aichimikawa [6572]',
	'JA Bank Aioi [7316]',
	'JA Bank Aira [9319]',
	'JA Bank Airaizu [6342]',
	'JA Bank Aizuyotsuba [4160]',
	'JA Bank Aka [7239]',
	'JA Bank Akagitachibana [4540]',
	'JA Bank Akan [3338]',
	'JA Bank Aki [7916]',
	'JA Bank Akigawa [5039]',
	'JA Bank Akitafurusato [3878]',
	'JA Bank Akitahokuo [3773]',
	'JA Bank Akitakita [3764]',
	'JA Bank Akitakoto [3798]',
	'JA Bank Akitaminami [3805]',
	'JA Bank Akitaobako [3855]',
	'JA Bank Akitashinsei [3825]',
	'JA Bank Akitashirakami [3784]',
	'JA Bank Akitayamamoto [3795]',
	'JA Bank Ama Higashi [6503]',
	'JA Bank Amakusa [9070]',
	'JA Bank Amami [9363]',
	'JA Bank Amarumemachi [4022]',
	'JA Bank Anan [8268]',
	'JA Bank Aoba [5895]',
	'JA Bank Aomori [3373]',
	'JA Bank Aozora [9338]',
	'JA Bank Arida [7559]',
	'JA Bank Arupusu [5888]',
	'JA Bank Asahikawa [3210]',
	'JA Bank Asakano [4730]',
	'JA Bank Ashikaga [4533]',
	'JA Bank Ashikita [9043]',
	'JA Bank Ashin [7847]',
	'JA Bank Ashiyorocho [3289]',
	'JA Bank Aso [8964]',
	'JA Bank Atsugi [5152]',
	'JA Bank Awa [4876]',
	'JA Bank Awa Miyoshi [8323]',
	'JA Bank Awacho [8296]',
	'JA Bank Awaguntobu [8301]',
	'JA Bank Awaji Hinode [7363]',
	'JA Bank Awajishima [7373]',
	'JA Bank Ayacho [9177]',
	'JA Bank Azumi [5466]',
	'JA Bank Beppu Hiji [9103]',
	'JA Bank Bibai [3164]',
	'JA Bank Bieicho [3228]',
	'JA Bank Bihorocho [3320]',
	'JA Bank Biratori [3120]',
	'JA Bank Ceresa Kawasaki [5123]',
	'JA Bank Chiba Midori [5016]',
	'JA Bank Chiba Tokatsu [4975]',
	'JA Bank Chibamirai [4954]',
	'JA Bank Chichibu [4792]',
	'JA Bank Chiku [8636]',
	'JA Bank Chikuzen Asakura [8645]',
	'JA Bank Chiyosei [4916]',
	'JA Bank Chiyurui [3268]',
	'JA Bank Chokuan [8694]',
	'JA Bank Daihoku [5470]',
	'JA Bank Date [3107]',
	'JA Bank Dohokunayoro [3244]',
	'JA Bank Doo [3139]',
	'JA Bank Dotoasahi [3354]',
	'JA Bank Ebino [9197]',
	'JA Bank Echigo Jyoetsu [5768]',
	'JA Bank Echigojiya [5690]',
	'JA Bank Echigonagaoka [5666]',
	'JA Bank Echigosanto [5685]',
	'JA Bank Echizen Takefu [6853]',
	'JA Bank Echizenniyu [6841]',
	'JA Bank Ehime Shinren [3038]',
	'JA Bank Ehimechuo [8500]',
	'JA Bank Ehimeminami [8482]',
	'JA Bank Ehimetaiki [8457]',
	'JA Bank Eiheijicho [6805]',
	'JA Bank Enshu Chuo [6391]',
	'JA Bank Enshu Yumesaki [6387]',
	'JA Bank Enyu [3306]',
	'JA Bank Fruits Yamana [5169]',
	'JA Bank Fuefuki [5199]',
	'JA Bank Fuji [6355]',
	'JA Bank Fujikawa [5209]',
	'JA Bank Fujinomiya [6357]',
	'JA Bank Fukaya [4874]',
	'JA Bank Fukui [6785]',
	'JA Bank Fukui Shinren [3024]',
	'JA Bank Fukui Tannan [6836]',
	'JA Bank Fukuiikedacho [6838]',
	'JA Bank Fukuishinambu [6789]',
	'JA Bank Fukumitsu [5935]',
	'JA Bank Fukuoka [8633]',
	'JA Bank Fukuoka Keichiku [8730]',
	'JA Bank Fukuoka Shinren [3040]',
	'JA Bank Fukuokakaho [8701]',
	'JA Bank Fukuokaoki [8667]',
	'JA Bank Fukuokashi Tobu [8632]',
	'JA Bank Fukuokayame [8668]',
	'JA Bank Fukushima Mirai [4047]',
	'JA Bank Fukushima Sakura [4196]',
	'JA Bank Fukuyama [8047]',
	'JA Bank Furano [3231]',
	'JA Bank Furukawa [3704]',
	'JA Bank Gamagori [6606]',
	'JA Bank Geinan [8011]',
	'JA Bank Gifu [6129]',
	'JA Bank Gifu Shinren [3020]',
	'JA Bank Goshiyotsugaru [3442]',
	'JA Bank Gotemba [6351]',
	'JA Bank Goto [8893]',
	'JA Bank Green Kagoshima [9234]',
	'JA Bank Green Nagano [5477]',
	'JA Bank Green Omi [6897]',
	'JA Bank Green Osaka [7156]',
	'JA Bank Hachinohe [3488]',
	'JA Bank Hachioji [5050]',
	'JA Bank Hadano [5140]',
	'JA Bank Hagano [4463]',
	'JA Bank Hagukumi [4567]',
	'JA Bank Hainan [6382]',
	'JA Bank Hakodateshi Kameda [3066]',
	'JA Bank Hakui [6076]',
	'JA Bank Hakusan [6012]',
	'JA Bank Hamanakacho [3335]',
	'JA Bank Hamayu [9178]',
	'JA Bank Hamochi [5847]',
	'JA Bank Hanamaki [3553]',
	'JA Bank Hanasakifukui [6810]',
	'JA Bank Hanazono [4823]',
	'JA Bank Hanzawa [4821]',
	'JA Bank Harima [7326]',
	'JA Bank Harue [6823]',
	'JA Bank Hida [6313]',
	'JA Bank Hidakahiga [3129]',
	'JA Bank Higashi Biwako [6912]',
	'JA Bank Higashi Tokushima [8234]',
	'JA Bank Higashiasahikawa [3220]',
	'JA Bank Higashikagura [3219]',
	'JA Bank Higashikawacho [3227]',
	'JA Bank Higashimino [6287]',
	'JA Bank Higashine [3962]',
	'JA Bank Higashinotogawa [6909]',
	'JA Bank Higashisoya [3259]',
	'JA Bank Higashiuwa [8477]',
	'JA Bank Himawari [6612]',
	'JA Bank Himi [5920]',
	'JA Bank Hirocho [3270]',
	'JA Bank Hiroshima [7909]',
	'JA Bank Hiroshima Shinren [3034]',
	'JA Bank Hiroshimachuo [7994]',
	'JA Bank Hiroshimahokubu [7981]',
	'JA Bank Hiroshimayutaka [8019]',
	'JA Bank Hisui [5797]',
	'JA Bank Hitachi [4263]',
	'JA Bank Hitachishitaga [4294]',
	'JA Bank Hiyogomirai [7264]',
	'JA Bank Hiyuga [9213]',
	'JA Bank Hokkaido Shinren [3001]',
	'JA Bank Hokota [4296]',
	'JA Bank Hokusai [4828]',
	'JA Bank Hombetsucho [3288]',
	'JA Bank Hondoitsuwa [9069]',
	'JA Bank Horonobecho [3208]',
	'JA Bank Hyogo Minami [7240]',
	'JA Bank Hyogo Nishi [7288]',
	'JA Bank Hyogo Rokko [7213]',
	'JA Bank Hyogo Shinren [3028]',
	'JA Bank Ibaraki [7032]',
	'JA Bank Ibaraki Minami [4378]',
	'JA Bank Ibaraki Shinren [3008]',
	'JA Bank Ibarakiasahimura [4295]',
	'JA Bank Ibarakikasumi [4324]',
	'JA Bank Ibarakimutsumi [4422]',
	'JA Bank Ibigawa [6198]',
	'JA Bank Ibusuki [9251]',
	'JA Bank Ichibacho [8300]',
	'JA Bank Ichihara [4949]',
	'JA Bank Ichikawa [4959]',
	'JA Bank Ichishitobu [6678]',
	'JA Bank Igahokubu [6758]',
	'JA Bank Iki [8905]',
	'JA Bank Imabari Tachibana [8401]',
	'JA Bank Imaganecho [3058]',
	'JA Bank Imari [8771]',
	'JA Bank Imazucho [6932]',
	'JA Bank Imizuno [5911]',
	'JA Bank Inaba [5932]',
	'JA Bank Inashiki [4322]',
	'JA Bank Irumano [4735]',
	'JA Bank Ise [6731]',
	'JA Bank Isehara [5139]',
	'JA Bank Ishikari [3142]',
	'JA Bank Ishikawa Shinren [3019]',
	'JA Bank Ishikawakahoku [6062]',
	'JA Bank Ishinomaki [3731]',
	'JA Bank Isumi [4893]',
	'JA Bank Itanogun [8252]',
	'JA Bank Itoshima [8635]',
	'JA Bank Iwai [4425]',
	'JA Bank Iwamizawa [3156]',
	'JA Bank Iwanuma [3647]',
	'JA Bank Iwate Shinren [3003]',
	'JA Bank Iwatechuo [3541]',
	'JA Bank Iwatefurusato [3572]',
	'JA Bank Iwatehiraizumi [3590]',
	'JA Bank Iwatesa [3579]',
	'JA Bank Izumino [7092]',
	'JA Bank Izunokuni [6338]',
	'JA Bank Izutaiyo [6328]',
	'JA Bank Jiyosohikari [4413]',
	'JA Bank Kaga [5943]',
	'JA Bank Kagawa [8332]',
	'JA Bank Kagawa Shinren [3037]',
	'JA Bank Kagoshima Chuo [9229]',
	'JA Bank Kagoshima Kimotsuki [9341]',
	'JA Bank Kagoshima Shinren [3046]',
	'JA Bank Kagoshimaizumi [9302]',
	'JA Bank Kaifu [8288]',
	'JA Bank Kakegawa [6386]',
	'JA Bank Kakogawashi Minami [7274]',
	'JA Bank Kamihaya [5815]',
	'JA Bank Kamiina [5384]',
	'JA Bank Kamikawachuo [3225]',
	'JA Bank Kamimashiki [8982]',
	'JA Bank Kamishihorocho [3280]',
	'JA Bank Kamitsuga [4456]',
	'JA Bank Kamiyotsuba [3710]',
	'JA Bank Kamoto [8941]',
	'JA Bank Kanagawa Seisho [5147]',
	'JA Bank Kanagawa Shinren [3014]',
	'JA Bank Kanazawa [6025]',
	'JA Bank Kanazawachuo [6024]',
	'JA Bank Kaneyama [3987]',
	'JA Bank Kanratomioka [4608]',
	'JA Bank Karatsu [8766]',
	'JA Bank Kashiwazaki [5720]',
	'JA Bank Kasuya [8626]',
	'JA Bank Katori [5000]',
	'JA Bank Kazuno [3762]',
	'JA Bank Kenebetsu [3350]',
	'JA Bank Kenoaikawa [5153]',
	'JA Bank Kihoku Kawakami [7550]',
	'JA Bank Kikuchi Chiiki [8949]',
	'JA Bank Kimitsu [4909]',
	'JA Bank Kimotsuki Airacho [9347]',
	'JA Bank Kinan [7576]',
	'JA Bank Kino [3277]',
	'JA Bank Kinosato [7543]',
	'JA Bank Kisarazu [4902]',
	'JA Bank Kishu [7565]',
	'JA Bank Kiso [5441]',
	'JA Bank Kita Biwako [6924]',
	'JA Bank Kita Okhotsk [3297]',
	'JA Bank Kitaechigo [5554]',
	'JA Bank Kitafuji [5284]',
	'JA Bank Kitagun Shibukawa [4593]',
	'JA Bank Kitaharuka [3248]',
	'JA Bank Kitahibiki [3238]',
	'JA Bank Kitahiyamacho [3056]',
	'JA Bank Kitaibuki [3188]',
	'JA Bank Kitaishikari [3145]',
	'JA Bank Kitakamminami [5541]',
	'JA Bank Kitakawachi [7193]',
	'JA Bank Kitakyushu [8692]',
	'JA Bank Kitamirai [3317]',
	'JA Bank Kitaosaka [7025]',
	'JA Bank Kitasatsuma [9296]',
	'JA Bank Kitasorachi [3189]',
	'JA Bank Kitasoya [3257]',
	'JA Bank Kitatsukuba [4397]',
	'JA Bank Kitauonuma [5693]',
	'JA Bank Kiyosatocho [3330]',
	'JA Bank Kiyowa [3087]',
	'JA Bank Kobaya [9193]',
	'JA Bank Kochi [8551]',
	'JA Bank Kochi Shinren [3039]',
	'JA Bank Kofu [5222]',
	'JA Bank Koka [6889]',
	'JA Bank Kokonoemachihanda [9140]',
	'JA Bank Komachi [3913]',
	'JA Bank Komano [5243]',
	'JA Bank Komatsu [5962]',
	'JA Bank Koshigaya [4847]',
	'JA Bank Koshimizucho [3328]',
	'JA Bank Kosumosu [8582]',
	'JA Bank Koto [6911]',
	'JA Bank Koyu [9200]',
	'JA Bank Kukashiyo [7191]',
	'JA Bank Kumachiiki [9048]',
	'JA Bank Kumagaya [4808]',
	'JA Bank Kumamoto [8916]',
	'JA Bank Kumamotoki [9010]',
	'JA Bank Kurashiki Kasaya [7825]',
	'JA Bank Kure [7913]',
	'JA Bank Kurein [5272]',
	'JA Bank Kurobe [5883]',
	'JA Bank Kurume [8660]',
	'JA Bank Kusatsu [6883]',
	'JA Bank Kushimashiotsuka [9181]',
	'JA Bank Kushiroota [3334]',
	'JA Bank Kushirotancho [3339]',
	'JA Bank Kusukokonoe [9137]',
	'JA Bank Kyoto [6990]',
	'JA Bank Kyoto Chuo [6956]',
	'JA Bank Kyoto Ninokuni [6996]',
	'JA Bank Kyoto Shinren [3026]',
	'JA Bank Kyoto Yamashiro [6961]',
	'JA Bank Kyotoshi [6941]',
	'JA Bank Lake Ibuki [6919]',
	'JA Bank Lake Shiga [6874]',
	'JA Bank Machida [5060]',
	'JA Bank Machinomachi [6117]',
	'JA Bank Maebashi [4544]',
	'JA Bank Mainzu [5070]',
	'JA Bank Makinocho [6931]',
	'JA Bank Makubetsucho [3282]',
	'JA Bank Mamurogawamachi [3986]',
	'JA Bank Maniwa [7859]',
	'JA Bank Mashiyuko [3337]',
	'JA Bank Matsumoto [5447]',
	'JA Bank Matsumoto Highland [5448]',
	'JA Bank Matsuto [5997]',
	'JA Bank Matsuyama [8425]',
	'JA Bank Megumino [6242]',
	'JA Bank Memambetsucho [3321]',
	'JA Bank Memurocho [3271]',
	'JA Bank Michinokumurayama [3960]',
	'JA Bank Midori Shinyo [6443]',
	'JA Bank Mie Shinren [3023]',
	'JA Bank Miekita [6649]',
	'JA Bank Mienanki [6770]',
	'JA Bank Mihara [8027]',
	'JA Bank Mii [8656]',
	'JA Bank Mikatahara-kaitaku [6426]',
	'JA Bank Mikumano [7591]',
	'JA Bank Mima [8312]',
	'JA Bank Minaho [5877]',
	'JA Bank Minami Chikugo [8689]',
	'JA Bank Minami Shinshu [5405]',
	'JA Bank Minamirumoi [3200]',
	'JA Bank Minamisatsuma [9257]',
	'JA Bank Minenobu [3165]',
	'JA Bank Minori [7249]',
	'JA Bank Mishima Kannami [6333]',
	'JA Bank Mito [4238]',
	'JA Bank Mitsui [3126]',
	'JA Bank Mitsukabicho [6423]',
	'JA Bank Miura [5130]',
	'JA Bank Miyagisennan [3751]',
	'JA Bank Miyagitome [3665]',
	'JA Bank Miyagiwatari [3653]',
	'JA Bank Miyakonojo [9184]',
	'JA Bank Miyazaki Chuo [9169]',
	'JA Bank Miyazaki Shinren [3045]',
	'JA Bank Miyo [8069]',
	'JA Bank Mizumamachi [8664]',
	'JA Bank Mombetsucho [3122]',
	'JA Bank Mukawa [3114]',
	'JA Bank Munakata [8621]',
	'JA Bank Myozaigun [8242]',
	'JA Bank Nagamine [7541]',
	'JA Bank Nagano [5499]',
	'JA Bank Nagano Shinren [3016]',
	'JA Bank Nagano Yatsugatake [5311]',
	'JA Bank Naganuma [3170]',
	'JA Bank Nagasaki Keno [8813]',
	'JA Bank Nagasaki Saikai [8857]',
	'JA Bank Nagasaki Seihi [8794]',
	'JA Bank Nagoya [6430]',
	'JA Bank Nakakomatobu [5234]',
	'JA Bank Nakano [5491]',
	'JA Bank Nakasatsunaimura [3266]',
	'JA Bank Nakashibetsucho [3349]',
	'JA Bank Nakashiyumbetsu [3358]',
	'JA Bank Nakatombetsucho [3260]',
	'JA Bank Namegata [4310]',
	'JA Bank Namporocho [3161]',
	'JA Bank Nanohana [5898]',
	'JA Bank Nansai [4848]',
	'JA Bank Nansun [6345]',
	'JA Bank Nanto [5927]',
	'JA Bank Nara [7387]',
	'JA Bank Narita [4992]',
	'JA Bank Narusawamura [5287]',
	'JA Bank Nasuminami [4518]',
	'JA Bank Nasuno [4507]',
	'JA Bank Natoriiwanuma [3652]',
	'JA Bank Neagari [5980]',
	'JA Bank Niigata [5864]',
	'JA Bank Niigata Kagayaki [5600]',
	'JA Bank Niigata Mirai [5577]',
	'JA Bank Niigata Nankan [5631]',
	'JA Bank Niigata Shinren [3017]',
	'JA Bank Niigataiwafune [5823]',
	'JA Bank Niihama [8397]',
	'JA Bank Niikatsupucho [3124]',
	'JA Bank Niitsusatsuki [5585]',
	'JA Bank Niji [8653]',
	'JA Bank Nishi Biwako [6935]',
	'JA Bank Nishi Tokyo [5030]',
	'JA Bank Nishi-yatsushirogun [5207]',
	'JA Bank Nishiimba [4996]',
	'JA Bank Nishikasugai [6456]',
	'JA Bank Nishimikawa [6560]',
	'JA Bank Nishimino [6175]',
	'JA Bank Nishitama [5037]',
	'JA Bank Nishiuwa [8463]',
	'JA Bank Nitsutamidori [4664]',
	'JA Bank Nobeoka [9208]',
	'JA Bank Nomi [5982]',
	'JA Bank Nonoichi [6010]',
	'JA Bank Notowakaba [6094]',
	'JA Bank Obihiroshi Kawanishi [3264]',
	'JA Bank Obihirotaishiyo [3265]',
	'JA Bank Ochi Imabari [8400]',
	'JA Bank Oegun [8305]',
	'JA Bank Ofunato [3598]',
	'JA Bank Ogatamura [3929]',
	'JA Bank Oigawa [6377]',
	'JA Bank Oirase [3474]',
	'JA Bank Oita [9104]',
	'JA Bank Oita Shinren [3044]',
	'JA Bank Oitaoyamamachi [9145]',
	'JA Bank Okayama [7755]',
	'JA Bank Okayama Nishi [7794]',
	'JA Bank Okayamahiga [7768]',
	'JA Bank Okhotsk Abashiri [3326]',
	'JA Bank Okhotsk Hamanasu [3301]',
	'JA Bank Okinawa [9375]',
	'JA Bank Omifuji [6888]',
	'JA Bank Onomichi [8029]',
	'JA Bank Oosaka Shinren [3027]',
	'JA Bank Oratatebaya [4677]',
	'JA Bank Ororon [3202]',
	'JA Bank Osaka Hokubu [7041]',
	'JA Bank Osaka Minami [7139]',
	'JA Bank Osaka Nakakawachi [7164]',
	'JA Bank Osaka Senshu [7087]',
	'JA Bank Osakashi [7200]',
	'JA Bank Osakatobu [7184]',
	'JA Bank Osuzu [9203]',
	'JA Bank Ota [4665]',
	'JA Bank Otofukecho [3278]',
	'JA Bank Otsu Matsushige [8261]',
	'JA Bank Owari Chuo [6451]',
	'JA Bank Oyama [4490]',
	'JA Bank Ozora [6113]',
	'JA Bank Pinne [3181]',
	'JA Bank Pitsupucho [3224]',
	'JA Bank Reihokumachi [9072]',
	'JA Bank Rihoku [5260]',
	'JA Bank Rikubetsucho [3290]',
	'JA Bank Ritsuto [6885]',
	'JA Bank Sado [5832]',
	'JA Bank Saga [8762]',
	'JA Bank Saga Shinren [3041]',
	'JA Bank Sagae Nishi Murayama [3943]',
	'JA Bank Sagami [5131]',
	'JA Bank Sagamihara [5159]',
	'JA Bank Sagashichuo [8740]',
	'JA Bank Saijyo [8395]',
	'JA Bank Saikatsu [4864]',
	'JA Bank Saikichuo [7938]',
	'JA Bank Saitama [4682]',
	'JA Bank Saitama Chuo [4780]',
	'JA Bank Saitama Hibikino [4802]',
	'JA Bank Saitama Mizuho [4859]',
	'JA Bank Saitama Shinren [3011]',
	'JA Bank Saitamaokabe [4820]',
	'JA Bank Saito [9205]',
	'JA Bank Sakai [7111]',
	'JA Bank Sakatashisodeura [4036]',
	'JA Bank Sakuasama [5335]',
	'JA Bank Sambugun [4929]',
	'JA Bank Sano [4523]',
	'JA Bank Sapporo [3133]',
	'JA Bank Sarabetsumura [3267]',
	'JA Bank Saromacho [3303]',
	'JA Bank Sasakami [5542]',
	'JA Bank Satora [8263]',
	'JA Bank Satsumahioki [9270]',
	'JA Bank Satsunai [3281]',
	'JA Bank Satsuraku [3154]',
	'JA Bank Sawaisesaki [4652]',
	'JA Bank Sawara [5002]',
	'JA Bank Seba [5462]',
	'JA Bank Sendai [3636]',
	'JA Bank Setagaya Meguro [5095]',
	'JA Bank Shibechiyacho [3336]',
	'JA Bank Shibetsucho [3348]',
	'JA Bank Shiga Shinren [3025]',
	'JA Bank Shigagamocho [6900]',
	'JA Bank Shihorocho [3279]',
	'JA Bank Shika [6084]',
	'JA Bank Shikaoicho [3276]',
	'JA Bank Shimabara Unzen [8829]',
	'JA Bank Shimane [7708]',
	'JA Bank Shimizu [6363]',
	'JA Bank Shimotsuke [4478]',
	'JA Bank Shin Asahicho [6933]',
	'JA Bank Shinakita [3810]',
	'JA Bank Shinhakodate [3068]',
	'JA Bank Shinhitachino [4394]',
	'JA Bank Shiniwate [3517]',
	'JA Bank Shinjiyo [3971]',
	'JA Bank Shinjiyomogami [3973]',
	'JA Bank ShinMiyagi [3721]',
	'JA Bank Shinotaru [3094]',
	'JA Bank Shinshinotsumura [3147]',
	'JA Bank Shinshu Suwa [5372]',
	'JA Bank Shinshu Ueda [5348]',
	'JA Bank Shinsunagawa [3175]',
	'JA Bank Shintokucho [3275]',
	'JA Bank Shiojiri [5449]',
	'JA Bank Shionoya [4497]',
	'JA Bank Shiosai [4301]',
	'JA Bank Shiozawa [5706]',
	'JA Bank Shiyaricho [3329]',
	'JA Bank Shiyobara [8076]',
	'JA Bank Shiyoei [7889]',
	'JA Bank Shiyonaimidori [4027]',
	'JA Bank Shiyonaitagawa [4013]',
	'JA Bank Shiyunan [8134]',
	'JA Bank Shiyuso [8398]',
	'JA Bank Shizunai [3125]',
	'JA Bank Shizuoka [6373]',
	'JA Bank Shizuoka Shinren [3021]',
	'JA Bank Shonan [5137]',
	'JA Bank Sokagoshima [9332]',
	'JA Bank Somamura [3390]',
	'JA Bank Sorachiminami [3172]',
	'JA Bank Soyaminami [3261]',
	'JA Bank Suzu [6122]',
	'JA Bank Suzuka [6665]',
	'JA Bank Tagawa [8715]',
	'JA Bank Taikicho [3269]',
	'JA Bank Tainai [5568]',
	'JA Bank Taisetsu [3214]',
	'JA Bank Tajima [7338]',
	'JA Bank Takachihochiku [9221]',
	'JA Bank Takanosumachi [3771]',
	'JA Bank Takaoka [5916]',
	'JA Bank Takasaki [4563]',
	'JA Bank Takatsuki [7029]',
	'JA Bank Takigun [6697]',
	'JA Bank Takikawa [3177]',
	'JA Bank Takomachi [5011]',
	'JA Bank Tamana [8926]',
	'JA Bank Tamanashiohamamachi [8934]',
	'JA Bank Tanba Hikami [7353]',
	'JA Bank Tanba Sasayama [7362]',
	'JA Bank Taneyaku [9353]',
	'JA Bank Tanofujioka [4594]',
	'JA Bank Tendo [3938]',
	'JA Bank Tenpaku Shinyo [6436]',
	'JA Bank Terral Echizen [6832]',
	'JA Bank Teshiocho [3206]',
	'JA Bank Tobashima [6741]',
	'JA Bank Tobu [9240]',
	'JA Bank Tokachiikedacho [3283]',
	'JA Bank Tokachishimizucho [3273]',
	'JA Bank Tokachitakashima [3285]',
	'JA Bank Tokamachi [5714]',
	'JA Bank Tokatsuchuo [4965]',
	'JA Bank Tokatsufutaba [4962]',
	'JA Bank Tokorocho [3322]',
	'JA Bank Tokushima [8231]',
	'JA Bank Tokushima Shinren [3036]',
	'JA Bank Tokushimakita [8257]',
	'JA Bank Tokyo Aoba [5097]',
	'JA Bank Tokyo Chuo [5094]',
	'JA Bank Tokyo Midori [5072]',
	'JA Bank Tokyo Minami [5055]',
	'JA Bank Tokyo Mirai [5077]',
	'JA Bank Tokyo Musashi [5087]',
	'JA Bank Tokyo Shinren [3013]',
	'JA Bank Tokyo Smile [5100]',
	'JA Bank Toma [3223]',
	'JA Bank Tomakomai Koiki [3112]',
	'JA Bank Tomamaecho [3201]',
	'JA Bank Tomisato [4993]',
	'JA Bank Tonamino [5921]',
	'JA Bank Tonenumata [4632]',
	'JA Bank Topia Hamamatsu [6403]',
	'JA Bank Tosa Kuroshio [8589]',
	'JA Bank Toto Shinyo [6265]',
	'JA Bank Tottori Chuo [7625]',
	'JA Bank Tottori Inaba [7601]',
	'JA Bank Tottori Seibu [7641]',
	'JA Bank Tottori Shinren [3031]',
	'JA Bank Towadaoirase [3455]',
	'JA Bank Toyako [3103]',
	'JA Bank Toyama [5897]',
	'JA Bank Toyoha [6618]',
	'JA Bank Toyokorocho [3286]',
	'JA Bank Tozaishirakawa [4132]',
	'JA Bank Tsuage [6673]',
	'JA Bank Tsubetsucho [3319]',
	'JA Bank Tsuchiura [4357]',
	'JA Bank Tsugaruhirosaki [3387]',
	'JA Bank Tsugarumirai [3407]',
	'JA Bank Tsugarunishikita [3421]',
	'JA Bank Tsukigatacho [3168]',
	'JA Bank Tsukuba [4363]',
	'JA Bank Tsukubashiyatabe [4371]',
	'JA Bank Tsukuigun [5162]',
	'JA Bank Tsumagoimura [4628]',
	'JA Bank Tsunammachi [5719]',
	'JA Bank Tsuruga Mitaka [6860]',
	'JA Bank Tsuruoka [4000]',
	'JA Bank Tsushima [8906]',
	'JA Bank Tsuyama [7868]',
	'JA Bank Uchiuramachi [6121]',
	'JA Bank Ugo [3917]',
	'JA Bank Uma [8389]',
	'JA Bank Umajimura [8511]',
	'JA Bank Uonuma Minami [5707]',
	'JA Bank Uozu [5885]',
	'JA Bank Urahorocho [3287]',
	'JA Bank Usuiannaka [4613]',
	'JA Bank Utsunomiya [4445]',
	'JA Bank Wakasa [6863]',
	'JA Bank Wakayama [7532]',
	'JA Bank Wakayama Shinren [3030]',
	'JA Bank Watsukanai [3254]',
	'JA Bank Yacho [4955]',
	'JA Bank Yamadamura [5906]',
	'JA Bank Yamagata [3932]',
	'JA Bank Yamagatamogami [3980]',
	'JA Bank Yamagataokitama [3989]',
	'JA Bank Yamagatashi [3931]',
	'JA Bank Yamaguchi Shinren [3035]',
	'JA Bank Yamaguchichuo [8153]',
	'JA Bank Yamanashi Shinren [3015]',
	'JA Bank Yanagawa [8680]',
	'JA Bank Yasato [4387]',
	'JA Bank Yatsushiro Chiiki [9017]',
	'JA Bank Yoichicho [3095]',
	'JA Bank Yokohama [5114]',
	'JA Bank Yokosuka Hayama [5128]',
	'JA Bank Yotei [3086]',
	'JA Bank Yubari [3173]',
	'JA Bank Yubetsucho [3305]',
	'JA Bank Yukiaomori [3469]',
	'JA Bank Yumeminami [4091]',
	'JA Harenokuniokayama [7837]',
	'JA Kawakami bussan [5330]',
	'JA MIENAKA [6677]',
	'JA Suigou Tsukuba [4344]',
	'Japan Post Bank (Yucho) [9900]',
	'Japan Trustee Services Bank [0324]',
	'JAみえなか [6677]',
	'ＪＡみえなか [6677]',
	'JF Aichi Shingyoren [9471]',
	'JF Aomori Shingyoren [9451]',
	'JF Bank Miyagi [9453]',
	'JF Chiba Shingyoren [9461]',
	'JF Ehime Shingyoren [9487]',
	'JF Fukui Shingyoren [9473]',
	'JF Fukuoka Shingyoren [9489]',
	'JF Fukushima Shingyoren [9456]',
	'JF Hiroshima Shingyoren [9483]',
	'JF Hokkaido Shingyoren [9450]',
	'JF Hyogo Shingyoren [9477]',
	'JF Ibaraki Shingyoren [9457]',
	'JF Ishikawa Shingyoren [9468]',
	'JF Iwate Shingyoren [9452]',
	'JF Kagawa Shingyoren [9486]',
	'JF Kagoshima Shingyoren [9495]',
	'JF Kochi Shingyoren [9488]',
	'JF Kyoto Shingyoren [9475]',
	'JF Mie Shingyoren [9472]',
	'JF Miyazaki Shingyoren [9494]',
	'JF Nagasaki Shingyoren [9491]',
	'JF Niigata Shingyoren [9466]',
	'JF Oita Bank [9493]',
	'JF Okinawa Shingyoren [9496]',
	'JF Saga Shingyoren [9490]',
	'JF Shimane Bank [9481]',
	'JF Shizuoka Shingyoren [9470]',
	'JF Tokushima Shingyoren [9485]',
	'JF Tokyo Shingyoren [9462]',
	'JF Tottori Shingyoren [9480]',
	'JF Toyama Shingyoren [9467]',
	'JF Wakayama Shingyoren [9479]',
	'JF Yamaguchi Bank [9484]',
	'ＪＦしまね漁業協同組合 [9481]',
	'ＪＦシマネギヨキヨウ [9481]',
	'Jibun [0039]',
	'Jibun Bank Corporation [0039]',
	'Joetsu Shinkin Bank [1376]',
	'Johnan Shinkin Bank [1344]',
	'Johoku Shinkin Bank [1351]',
	'Joyo Bank [0130]',
	'JP Morgan Chase Bank [0402]',
	'JSF Trust And Banking [0321]',
	'JSF Trust and banking Co., Ltd. [0321]',
	'Juhachi-Shinwa Bank [0181]',
	'Juroku Bank [0153]',
	'Kagawa Bank [0573]',
	'Kagawa Prefecture credit Federation of Fisheries Cooperative Associations [9486]',
	'Kagawaken Shinkumi Bank [2721]',
	'Kagoshima Bank [0185]',
	'Kagoshima Ishi Shinkumi Bank [2891]',
	'Kagoshima Kogyo Shinkumi Bank [2890]',
	'Kagoshima Shinkin Bank [1990]',
	'Kagoshima Sougo Shinkin Bank [1991]',
	"Kagoshimashin'yokinko [1990]",
	'Kakegawa Shinkin Bank [1513]',
	'Kameari Shinkin Bank [1323]',
	'Kamo Shinkin Bank [1380]',
	'Kanagawa Bank [0530]',
	'Kanagawa Shinkin Bank [1281]',
	'Kanagawaken Ishi Shinkumi Bank [2304]',
	'Kanagawaken Shika Ishi Shinkumi Bank [2305]',
	'Kanazawa Chuo Shinkumi Bank [2411]',
	'Kanazawa Shinkin Bank [1440]',
	'Kanonji Shinkin Bank [1833]',
	'Kansai Mirai Bank [0159]',
	'Kanumasogo Shinkin Bank [1223]',
	'Karasuyama Shinkin Bank [1227]',
	'Karatsu Shinkin Bank [1930]',
	'Kasaoka Shinkumi Bank [2674]',
	'Kashiwazaki Shinkin Bank [1375]',
	'Kawaguchi Shinkin Bank [1251]',
	'Kawanoe Shinkin Bank [1866]',
	'Kawasaki Shinkin Bank [1283]',
	'Keiji Shinkumi Bank [2526]',
	'Keishicho Shokuin Shinkumi Bank [2271]',
	'Keiyo Bank [0522]',
	'Kesennuma Shinkin Bank [1175]',
	'Kibi Shinkin Bank [1741]',
	'Kihoku Shinkin Bank [1585]',
	'Kimitsu Shinkumi Bank [2190]',
	'Kinki Labour Bank [2978]',
	'Kinki Sangyo Shinkumi Bank [2567]',
	'Kinokuni Shinkin Bank [1674]',
	'Kiraboshi Bank [0137]',
	'Kirayaka Bank [0508]',
	'Kiryu Shinkin Bank [1204]',
	'Kita Osaka Shinkin Bank [1645]',
	'Kita-nippon Bank [0509]',
	'Kitagun Shinkumi Bank [2083]',
	'Kitagunma Shinkin Bank [1210]',
	'Kitaiseueno Shinkin Bank [1581]',
	'Kitakami Shinkin Bank [1154]',
	'Kitakyushu Bank [0191]',
	'Kitami Shinkin Bank [1030]',
	'Kitasorachi Shinkin Bank [1010]',
	"Kitasorachishin'yokinko [1010]",
	'Kiyo Bank [0163]',
	'Kobe Shinkin Bank [1680]',
	'Kobe Shokuin Shinkumi Bank [2610]',
	'Kochi Prefecture credit Federation of Agricultural Cooperatives [3039]',
	'Kochi Shinkin Bank [1881]',
	'Kofu Shinkin Bank [1385]',
	"Kofushin'yokinko [1385]",
	'Komatsugawa Shinkin Bank [1326]',
	'Kono Shinkin Bank [1448]',
	'Koriyama Shinkin Bank [1182]',
	'Kosan Shinkin Bank [1305]',
	'Koshi Shinkumi Bank [2272]',
	'Koto Shinkin Bank [1604]',
	'Koto Shinkumi Bank [2229]',
	'Kouei Shinkumi Bank [2356]',
	'Koza Shinkin Bank [1996]',
	'Kumagaya Shoko Shinkumi Bank [2165]',
	'Kumamoto Bank [0587]',
	'Kumamoto Chuo Shinkin Bank [1954]',
	'Kumamoto Dai-ichi Shinkin Bank [1952]',
	'Kumamoto Ishi Shinkumi Bank [2842]',
	'Kumamoto Prefecture credit union [2845]',
	'Kumamoto Shinkin Bank [1951]',
	'Kumamoto Shinkumi Bank [2845]',
	'Kurayoshi Shinkin Bank [1703]',
	'Kure City Credit Union [2686]',
	'Kure Shinkin Bank [1752]',
	'Kushiro Shinkin Bank [1027]',
	'Kushiro Shinkumi Bank [2025]',
	'Kuwana Mie Shinkin Bank [1583]',
	'Kyoei Shinkumi Bank [2360]',
	'Kyoritsu Shinkumi Bank [2241]',
	'Kyoto Bank [0158]',
	'Kyoto Chuo Shinkin Bank [1611]',
	'Kyoto Hokuto credit union [1620]',
	'Kyoto Hokuto Shinkin Bank [1620]',
	'Kyoto Shinkin Bank [1610]',
	'Kyushu Kougin Shinkumi Bank [2840]',
	'Kyushu Labour Bank [2990]',
	'Kyusyu Hizen Shinkin Bank [1933]',
	'Lawson Bank [0042]',
	'Mainichi Shinkumi Bank [2581]',
	'Maki Shinkumi Bank [2362]',
	'Maruhachi Shinkumi Bank [2440]',
	'Mashita Shinkumi Bank [2481]',
	'Matsumoto Shinkin Bank [1391]',
	'Meguro Shinkin Bank [1346]',
	'Michinoku Bank [0118]',
	'Michinoku Bank, Ltd. [0118]',
	'Mie Bank [-]',
	'Minato Bank [0562]',
	'Minna no Ginko [0043]',
	'Mire Shinkumi Bank [2582]',
	'Mishima Shinkin Bank [1506]',
	'Mito Shinkin Bank [1240]',
	'Mitsubishi UFJ Trust and Banking Corporation [0288]',
	'Miyagi Daiichi Shinkin Bank [1171]',
	'Miyako Shinkin Bank [1152]',
	'Miyakonojo Shinkin Bank [1981]',
	'Miyazaki Bank [0184]',
	'Miyazaki Nanbu Shinkumi Bank [2884]',
	'Miyazaki Shinkin Bank [1980]',
	'Miyazaki Taiyo Bank [0591]',
	"Miyazakishin'yokinko [1980]",
	'Miyazakitaiyoginko [0591]',
	'Mizuho Bank [0001]',
	'Mizuho Trust And Banking [0289]',
	'Mizusawa Shinkin Bank [1156]',
	'Mizushima Shinkin Bank [1734]',
	'Moka Shinkumi Bank [2122]',
	'Momiji Bank [0569]',
	'Mori No Miyako Shinkin Bank [1170]',
	'Morioka Shinkin Bank [1150]',
	'MUFG Bank, Ltd. (Formerly Tokyo-Mitsubishi UFJ Bank, Ltd.) [0005]',
	'Murakami Shinkin Bank [1379]',
	'Muroran Shinkin [1003]',
	'Musashino Bank [0133]',
	'Nagahama Shinkin Bank [1603]',
	'Nagano Labour Bank [2966]',
	'Nagano Shinkin Bank [1390]',
	'Naganoginko [0533]',
	'Naganoken Shinkumi Bank [2390]',
	'Nagaoka Shinkin Bank [1371]',
	'Nagasaki Ishi Shinkumi Bank [2821]',
	'Nagasaki Kenmin Shinkumi Bank [2825]',
	'Nagasaki Mitsubishi Shinkumi Bank [2820]',
	'Nagasaki prefecture credit union [2825]',
	'Nagoya Seikabutsu Shinkumi Bank [2444]',
	'Nakahyogo Shinkin Bank [1695]',
	'Nakanogo Shinkumi Bank [2235]',
	'Nango Shinkin Bank [1986]',
	'Nanto Bank [0162]',
	'Nara Chuo Shinkin Bank [1668]',
	'Nara Shinkin Bank [1666]',
	'Nasu Shinkumi Bank [2125]',
	'Nihonkai Shinkin Bank [1711]',
	'Nihonmatsu Shinkin Bank [1189]',
	'Niigata Daiei Shinkumi Bank [2363]',
	'Niigata Labour Bank [2965]',
	'Niigata Shinkin Bank [1370]',
	'Niigata Tetsudo Shinkin Bank [2354]',
	'Niigataken Shinkumi Bank [2351]',
	'Niihama Agricultural Cooperatives [8397]',
	'Niikawa Shinkin Bank [1405]',
	'Nishi-Chugoku Shinkin Bank [1781]',
	'Nishi-Nippon City Bank [0190]',
	'Nishihyogo Shinkin Bank [1694]',
	'Nishio Shinkin Bank [1561]',
	"Nishioshin'yokinko [1561]",
	'Nisshin Shinkin Bank [1689]',
	'Nobeoka Shinkin Bank [1982]',
	'Nomura Trust and Banking [0304]',
	'Norin Chukin Bank [3000]',
	'North Pacific Bank [0501]',
	'North Soya Agricultural Cooperatives [3257]',
	'Noto Kyoei Shinyo Kinko [1442]',
	'Nozomi Shinkumi Bank [2549]',
	'Numazu Shinkin Bank [1505]',
	'Obama Shinkin Bank [1473]',
	'Obihiro Shinkin Bank [1026]',
	'Odawara Daiichi Shinkumi Bank [2315]',
	'Ogaki Kyoritsu Bank [0152]',
	'Ogaki Seino Shinkin Bank [1531]',
	'Ohtawara Shinkin Bank [1225]',
	'Oita Bank [0183]',
	'Oita Mirai Shinkin Bank [1962]',
	'Oita Prefecture Agricultural Cooperatives [9104]',
	'Oita Shinkin Bank [1960]',
	'Oita Shinkumi Bank [2870]',
	'Okawa credit union [1917]',
	'Okawa Shinkin Bank [1917]',
	'Okayama Shinkin Bank [1732]',
	'Okayama Shogin Shinkumi Bank [2673]',
	'Okazaki Shinkin Bank [1552]',
	'Okinawa Kaiho Bank [0596]',
	'Okinawa Labour Bank [2997]',
	'Okinawa Prefectural Agricultural Cooperatives [9375]',
	'Oloron Agricultural Cooperatives [3202]',
	'Ome Shinkin Bank [1358]',
	"Omeshin'yokinko [1358]",
	'Omuta Yanagawa Shinkin Bank [1908]',
	'Onga Shinkin Bank [1920]',
	'ORIX Bank [0307]',
	'Osaka Chochiku Shinkumi Bank [2548]',
	'Osaka City Shinkin Bank [1635]',
	'Osaka Ishi Shinkumi Bank [2560]',
	'Osaka Keisatsu Shinkumi Bank [2566]',
	'Osaka Kosei Shinkin Bank [1633]',
	'Osaka Kyoei Shinkumi Bank [2543]',
	"Osaka Kyoeishin'yokumiai [2543]",
	'Osaka Shinkin Bank [1630]',
	'Osaka Shoko Shinkin Bank [1636]',
	'Osaka Welfare credit union [1633]',
	'Oshima Shinkin Bank [1013]',
	'Otaru Shinkin Bank [1016]',
	'PayPay Bank [0033]',
	'Philippine National Bank [0617]',
	'Rakuten [0036]',
	'Rakuten Bank [0036]',
	'Resona Bank [0010]',
	'Roudou Kinko Rengokai [2950]',
	'Rumoi Shinkin Bank [1022]',
	'Ryobi Shinkumi Bank [2690]',
	'Ryugasaki Agricultural Cooperatives [4344]',
	'Saga Higashi Shinkumi Bank [2803]',
	'Saga Kyoei Bank [0583]',
	'Saga Nishi Shinkumi Bank [2808]',
	'Saga Prefecture Agricultural Cooperatives [8762]',
	'Saga Shinkin Bank [1931]',
	'Sagaken Ishi Shinkumi Bank [2802]',
	'Sagami Shinkin Bank [1288]',
	'Saikyo Bank [0570]',
	'Saikyo Shinkin Bank [1336]',
	'Saitama Ishi Shinkumi Bank [2162]',
	'Saitama Resona Bank [0017]',
	'Saitama Shinkumi Bank [2167]',
	'Saitamaken Shinkin Bank [1250]',
	'Sakuranomachi Shinkumi Bank [2358]',
	'Saningodo Bank [0167]',
	'Sanjo Shinkin Bank [1373]',
	'Sanjuusan [0154]',
	'Sanjyo Shinkumi Bank [2361]',
	'Sano Shinkin Bank [1224]',
	'Sapporo Chuo Shinkumi Bank [2013]',
	'Sasebo Chuo Shinkumi Bank [2826]',
	'Sawara Shinkin Bank [1267]',
	'Sawayaka Shinkin Bank [1310]',
	'SBI Shinsei Bank [0397]',
	'SBI Sumishin Net Bank [0038]',
	'SBI新生銀行 [0397]',
	'ＳＢＪ銀行 [0472]',
	'Seibu Shinkin Bank [1341]',
	'Seikyo Shinkumi Bank [2541]',
	'Seiri Kaishu Kiko [2213]',
	'Seishin Shinkin Bank [1502]',
	'Seiwa Shinkumi Bank [2231]',
	'Seki Shinkin Bank [1534]',
	'Sendai Bank [0512]',
	'Sennan Shinkin Bank [1174]',
	'Senpoku Shinkumi Bank [2063]',
	'Senshu Ikeda Bank [0161]',
	'Setagaya Shinkin Bank [1348]',
	'Seto Shinkin Bank [1554]',
	'Seven Bank [0034]',
	'Shiba Shinkin Bank [1319]',
	'Shibata Shinkin Bank [1374]',
	'Shichitou Shinkumi Bank [2243]',
	'Shiga Bank, Ltd. [0157]',
	'Shiga Shinkumi Bank [2505]',
	'Shigachuo Shinkin Bank [1602]',
	'Shigakenmin Shinkumi Bank [2504]',
	'Shikoku Bank [0175]',
	'Shikoku Labour Bank [2987]',
	'Shimada Shinkin Bank [1509]',
	'Shimanami Shinkin Bank [1756]',
	'Shimane Bank [0565]',
	'Shimane Chuo Shinkin Bank [1712]',
	'Shimane Masuda Shinkumi Bank [2661]',
	'Shimane Shinkin Bank [1710]',
	'Shimaneginko [0565]',
	'Shimizu Bank [0151]',
	'Shinei Shinkumi Bank [2357]',
	'Shingu Shinkin Bank [1671]',
	'Shinhan Bank Japan [0472]',
	'Shinjo Shinkin Bank [1143]',
	'Shinkin Central Bank [1000]',
	'Shinkin Trust Bank [0309]',
	'Shinkumi Federation Bank [2010]',
	'Shinminato Shinkin Bank [1404]',
	'Shinonome Shinkin Bank [1211]',
	'Shinsei Bank [0397]',
	'Shinwa Bank, Ltd. [0181]',
	'Shiozawa Shinkumi Bank [2365]',
	'Shirakawa Shinkin Bank [1184]',
	'Shizuoka Bank [0149]',
	'Shizuoka Bank Ltd. [0149]',
	'Shizuoka Chuo Bank [0538]',
	'Shizuoka Labour Bank [2968]',
	'Shizuoka Shinkin Bank [1501]',
	'Shizuokaken Ishi Shinkumi Bank [2332]',
	'Shoko Chukin Bank [2004]',
	'Shonai Bank [0121]',
	'Shonan Shinkin Bank [1282]',
	'Showa Shinkin Bank [1345]',
	'SMBC Trust Bank (PRESTIA) [0300]',
	'ＳＭＢＣ信託銀行 (プレスティア) [0300]',
	'Sony Bank [0035]',
	'Sorachi Shinkin [1004]',
	'Sorachi Shinkumi Bank [2019]',
	'Soso Gojou Shinkumi Bank [2095]',
	'Souai Shinkumi Bank [2318]',
	'Sugamo Shinkin Bank [1356]',
	"Sugamoshin'yokinko [1356]",
	'Suikotsukubanokiyou [4344]',
	'Sukagawa credit union [1185]',
	'Sukagawa Shinkin Bank [1185]',
	'Sukumo Shogin Shinkumi Bank [2741]',
	'Sumitomo Mitsui Banking Corporation (SMBC) [0009]',
	'Sumitomo Mitsui Trust Bank [0294]',
	'Sumitomo Trust SBI net bank [0038]',
	'Suruga Bank [0150]',
	'Suwa Shinkin Bank [1393]',
	'Tachibana Shinkin Bank [1942]',
	'Tagawa Shinkin Bank [1913]',
	'Taiko Bank [0532]',
	'Taisho Bank [0555]',
	'Taiwan Business Bank [0633]',
	'Tajima Bank [0164]',
	'Tajima Shinkin Bank [1692]',
	'Takamatsu Shinkin Bank [1830]',
	'Takanabe Shinkin Bank [1985]',
	'Takaoka Shinkin Bank [1402]',
	'Takasaki Shinkin Bank [1203]',
	'Takayama Shinkin Bank [1532]',
	'Takinogawa Shinkin Bank [1352]',
	'Tama Shinkin Bank [1360]',
	'Tamashima Shinkin Bank [1738]',
	'Tanyo Shinkin Bank [1696]',
	'Tatebayashi Shinkin Bank [1209]',
	'Tateyama credit union [1264]',
	'Tateyama Shinkin Bank [1264]',
	'The Nomura Trust and Banking [0304]',
	'Tobiume Shinkin Bank [2778]',
	'Tochigi Bank [0517]',
	'Tochigi Shinkin Bank [1222]',
	'Toei Shinkin Bank [1321]',
	'Toho Bank [0126]',
	'Tohoku Bank [0124]',
	'Tohoku Labour Bank [2954]',
	'Tokachi Ikeda Agricultural Cooperatives [3283]',
	'Tokachi Shinkumi Bank [2024]',
	'Tokai Labour Bank [2972]',
	'Tokushima Bank [0572]',
	'Tokushima Shinkin Bank [1801]',
	'Tokyo Bay Shinkin Bank [1262]',
	'Tokyo City Shinkin Bank [1311]',
	'Tokyo Higashi Shinkin Bank [1320]',
	'Tokyo Kosei Shinkumi Bank [2224]',
	'Tokyo Sankyo Shinkin Bank [1333]',
	'Tokyo Shinkin Bank [1349]',
	'Tokyo Shobo Shinkumi Bank [2274]',
	'Tokyo Shoken Shinkumi Bank [2215]',
	'Tokyo Star Bank [0526]',
	'Tokyoto Shokuin Shinkumi Bank [2276]',
	'Tomakomai Shinkin [1006]',
	'Tomato Bank [0566]',
	'Tonami Shinkin Bank [1412]',
	'Tonegun Shinkin Bank [1208]',
	'Tono Shinkin Bank [1533]',
	'Too Shinkin Bank [1104]',
	'Toryo Shinkumi Bank [2045]',
	'Tosa Shinkumi Bank [2740]',
	'Toshun Shinkin Bank [1566]',
	'Tottori Bank [0166]',
	'Tottori Shinkin Bank [1701]',
	'Towa Bank [0516]',
	'Toyama Bank [0145]',
	'Toyama Ishi Shinkumi Bank [2402]',
	'Toyama Shinkin Bank [1401]',
	'Toyama Shinkumi Bank [2404]',
	'Toyamadaiichiginko [0534]',
	'Toyo Shinkin Bank [1864]',
	'Toyohashi Shinkin Bank [1551]',
	'Toyohashi Shoko Shinkumi Bank [2448]',
	'Toyokawa Shinkin Bank [1557]',
	'Toyoku Shinkumi Bank [2210]',
	'Toyota Shinkin Bank [1559]',
	"Toyotashin'yokinko [1559]",
	'Trust And Custody Services Bank [0325]',
	'Tsu Shinkin Bank [1580]',
	'Tsukuba Bank [0131]',
	'Tsuru Shinkumi Bank [2378]',
	'Tsuruga Shinkin Bank [1471]',
	'Tsurugi Shinkin Bank [1445]',
	'Tsuruoka Shinkin Bank [1142]',
	'Tsuyama Shinkin Bank [1735]',
	'Ueda Shinkin Bank [1392]',
	'Ugo Shinkin Bank [1123]',
	'UI 銀行 [0044]',
	'UI Bank [0044]',
	'Uri Shinkumi Bank [2014]',
	'Uwajima Shinkin Bank [1862]',
	'Wakayama Ishi Shinkumi Bank [2634]',
	'Wakkanai Shinkin Bank [1021]',
	'Wooribank [0477]',
	'Xin Rong credit union [2357]',
	'Yamagata Bank [0122]',
	'Yamagata Chuo Shinkumi Bank [2084]',
	'Yamagata Daiichi Shinkumi Bank [2085]',
	'Yamagata Shinkin Bank [1140]',
	'Yamaguchi Bank [0170]',
	'Yamaguchiken Shinkumi Bank [2703]',
	'Yamanashi Chuo Bank [0142]',
	'Yamanashi Kenmin Shinkumi Bank [2377]',
	'Yamanashi Shinkin Bank [1386]',
	'Yamato Shinkin Bank [1667]',
	'Yokohama Chuo Shinkumi Bank [2306]',
	'Yokohama Kagin Shinkumi Bank [2307]',
	'Yokohama Shinkin Bank [1280]',
	'Yonago Shinkin Bank [1702]',
	'Yonezawa Shinkin Bank [1141]',
	'Yuki Shinkin Bank [1242]',
	'Zen Toei Shinkumi Bank [2202]',
	'アイエヌジ－　バンク　エヌ・ヴイ [0482]',
	'アイエヌジー　バンク　エヌ・ヴイ [0482]',
	'アイオ－シンキン [1206]',
	'アイオー信用金庫 [1206]',
	'アイオイシノウキヨウ [7316]',
	'アイチ [0542]',
	'あいち尾東農業協同組合 [6466]',
	'あいち三河農業協同組合 [6572]',
	'あいち中央農業協同組合 [6552]',
	'あいち知多農業協同組合 [6531]',
	'あいち豊田農業協同組合 [6582]',
	'あいち海部農業協同組合 [6514]',
	'アイチアマノウキヨウ [6514]',
	'アイチキタノウキヨウ [6470]',
	'アイチケンイシシンクミ [2447]',
	'アイチケンイリヨウシンクミ [2446]',
	'アイチケンケイサツシンクミ [2443]',
	'アイチケンシンギヨレン [9471]',
	'アイチケンシンレン [3022]',
	'アイチケンチユウオウシンクミ [2451]',
	'アイチシヨウギンシンクミ [2442]',
	'アイチシンキン [1550]',
	'アイチチタノウキヨウ [6531]',
	'アイチチユウオウノウキヨウ [6552]',
	'アイチトヨタノウキヨウ [6582]',
	'アイチニシノウキヨウ [6483]',
	'アイチヒガシノウキヨウ [6591]',
	'アイチビトウノウキヨウ [6466]',
	'アイチミカワノウキヨウ [6572]',
	'アイチミナミノウキヨウ [6615]',
	'アイヅイイデノウキヨウ [4160]',
	'アイヅシヨウコウシンクミ [2096]',
	'アイヅシンキン [1181]',
	'あいら農業協同組合 [9319]',
	'あいら伊豆農業協同組合 [6342]',
	'アイライズノウキヨウ [6342]',
	'アイラノウキヨウ [9319]',
	'アオイモリシンキン [1105]',
	'アオキシンキン [1252]',
	'アオゾラ [0398]',
	'あおぞら農業協同組合 [9338]',
	'あおぞら銀行 [0398]',
	'アオゾラノウキヨウ [9338]',
	'あおば農業協同組合 [5895]',
	'アオバノウキヨウ [5895]',
	'アオモリ [0117]',
	'アオモリケンシンギヨレン [9451]',
	'アオモリケンシンクミ [2030]',
	'アオモリノウキヨウ [3373]',
	'あかぎ信用組合 [2143]',
	'アカギシンクミ [2143]',
	'アカギタチバナノウキヨウ [4540]',
	'あかし農業協同組合 [7239]',
	'アカシノウキヨウ [7239]',
	'あがつま農業協同組合 [4626]',
	'アガツマノウキヨウ [4626]',
	'アカンノウキヨウ [3338]',
	'アキガワノウキヨウ [5039]',
	'アキタ [0119]',
	'あきた白神農業協同組合 [3784]',
	'あきた北農業協同組合 [3764]',
	'あきた北央農業協同組合 [3773]',
	'あきた湖東農業協同組合 [3798]',
	'アキタオバコノウキヨウ [3855]',
	'アキタキタノウキヨウ [3764]',
	'アキタケンシンクミ [2075]',
	'アキタコトウノウキヨウ [3798]',
	'アキタシラカミノウキヨウ [3784]',
	'アキタシンキン [1120]',
	'アキタシンセイノウキヨウ [3825]',
	'アキタフルサトノウキヨウ [3878]',
	'アキタホクオウノウキヨウ [3773]',
	'アキタミナミノウキヨウ [3805]',
	'アキタヤマモトノウキヨウ [3795]',
	'アキノウキヨウ [7916]',
	'あさか野農業協同組合 [4730]',
	'アサカノノウキヨウ [4730]',
	'あさひかわ農業協同組合 [3210]',
	'アサヒカワシンキン [1020]',
	'アサヒカワノウキヨウ [3210]',
	'アサヒシンキン [1303]',
	'アサヒシンブンシンクミ [2580]',
	'アシカガ [0129]',
	'アシカガオヤマシンキン [1221]',
	'アシカガシノウキヨウ [4533]',
	'あしきた農業協同組合 [9043]',
	'アシキタノウキヨウ [9043]',
	'アシヨロチヨウノウキヨウ [3289]',
	'アシンノウキヨウ [7847]',
	'あすか信用組合 [2060]',
	'アスカシンクミ [2060]',
	'アソノウキヨウ [8964]',
	'アダチセイワシンキン [1327]',
	'アツギシノウキヨウ [5152]',
	'アヅマシンクミ [2226]',
	'あづみ農業協同組合 [5466]',
	'アヅミノウキヨウ [5466]',
	'アナンシンキン [1803]',
	'アナンノウキヨウ [8268]',
	'アバシリシンキン [1031]',
	'あぶくま信用金庫 [1188]',
	'アブクマシンキン [1188]',
	'アマガサキシンキン [1688]',
	'あまくさ農業協同組合 [9070]',
	'アマクサシンキン [1955]',
	'アマクサノウキヨウ [9070]',
	'アマヒガシノウキヨウ [6503]',
	'あまみ農業協同組合 [9363]',
	'アマミオオシマシンキン [1993]',
	'アマミシンクミ [2895]',
	'アマミノウキヨウ [9363]',
	'アマルメマチノウキヨウ [4022]',
	'アメリカ [0403]',
	'アヤチヨウノウキヨウ [9177]',
	'アライシンキン [1377]',
	'ありだ農業協同組合 [7559]',
	'アリダノウキヨウ [7559]',
	'アルプス農業協同組合 [5888]',
	'アルプス中央信用金庫 [1396]',
	'アルプスチユウオウシンキン [1396]',
	'アルプスノウキヨウ [5888]',
	'アワ [0172]',
	'アワグントウブノウキヨウ [8301]',
	'あわじ島農業協同組合 [7373]',
	'アワジシマノウキヨウ [7373]',
	'アワジシンキン [1691]',
	'アワジヒノデノウキヨウ [7363]',
	'アワチヨウノウキヨウ [8296]',
	'アワノウキヨウ [4876]',
	'アワミヨシノウキヨウ [8323]',
	'イイサン [0632]',
	'イイダシンキン [1394]',
	'イイヅカシンキン [1910]',
	'イオ信用組合 [2471]',
	'イオシンクミ [2471]',
	'イオン [0040]',
	'イオン銀行 [0040]',
	'イガフルサトノウキヨウ [6758]',
	'イキシノウキヨウ [8905]',
	'イケダセンシユウ [0161]',
	'イシカリシノウキヨウ [3142]',
	'イシカワカホクノウキヨウ [6062]',
	'イシカワケンイシシンクミ [2417]',
	'イシカワケンシンギヨレン [9468]',
	'イシカワケンシンレン [3019]',
	'いしのまき農業協同組合 [3731]',
	'イシノマキシヨウコウシンクミ [2061]',
	'イシノマキシンキン [1172]',
	'イシノマキノウキヨウ [3731]',
	'イズタイヨウノウキヨウ [6328]',
	'イズノクニノウキヨウ [6338]',
	'いすみ農業協同組合 [4893]',
	'いずみの農業協同組合 [7092]',
	'イスミノウキヨウ [4893]',
	'イズミノノウキヨウ [7092]',
	'イスルギシンキン [1413]',
	'イセノウキヨウ [6731]',
	'イセハラシノウキヨウ [5139]',
	'イタノグンノウキヨウ [8252]',
	'いちい信用金庫 [1553]',
	'イチイシンキン [1553]',
	'イチカワシノウキヨウ [4959]',
	'イチシトウブノウキヨウ [6678]',
	'イチノセキシンキン [1153]',
	'イチバチヨウノウキヨウ [8300]',
	'イチハラシノウキヨウ [4949]',
	'イトイガワシンクミ [2366]',
	'イトシマノウキヨウ [8635]',
	'イナシキノウキヨウ [4322]',
	'いなば農業協同組合 [5932]',
	'イナバノウキヨウ [5932]',
	'イバラキアサヒムラノウキヨウ [4295]',
	'イバラキカスミノウキヨウ [4324]',
	'イバラキケンシンギヨレン [9457]',
	'イバラキケンシンクミ [2101]',
	'イバラキケンシンレン [3008]',
	'イバラキシノウキヨウ [7032]',
	'イバラキミナミノウキヨウ [4378]',
	'イバラキムツミノウキヨウ [4422]',
	'いび川農業協同組合 [6198]',
	'イビガワノウキヨウ [6198]',
	'いぶすき農業協同組合 [9251]',
	'イブスキノウキヨウ [9251]',
	'イマガネチヨウノウキヨウ [3058]',
	'イマヅチヨウノウキヨウ [6932]',
	'イマバリタチバナノウキヨウ [8401]',
	'イマリシノウキヨウ [8771]',
	'イマリシンキン [1932]',
	'いみず野農業協同組合 [5911]',
	'イミズノノウキヨウ [5911]',
	'イヨ [0174]',
	'いるま野農業協同組合 [4735]',
	'イルマノノウキヨウ [4735]',
	'イワイノウキヨウ [4425]',
	'いわき信用組合 [2092]',
	'イワキシノウキヨウ [4196]',
	'イワキシンクミ [2092]',
	'イワテ [0123]',
	'いわて平泉農業協同組合 [3590]',
	'イワテエサシノウキヨウ [3579]',
	'イワテケンイシシンクミ [2049]',
	'イワテケンシンギヨレン [9452]',
	'イワテケンシンレン [3003]',
	'イワテチユウオウノウキヨウ [3541]',
	'イワテヒライズミノウキヨウ [3590]',
	'イワテフルサトノウキヨウ [3572]',
	'イワヌマシノウキヨウ [3647]',
	'いわみざわ農業協同組合 [3156]',
	'イワミザワノウキヨウ [3156]',
	'ウエダシンキン [1392]',
	'ウオヅシノウキヨウ [5885]',
	'ウオヌマミナミノウキヨウ [5707]',
	'うご農業協同組合 [3917]',
	'ウゴシンキン [1123]',
	'ウゴノウキヨウ [3917]',
	'ウスイアンナカノウキヨウ [4613]',
	'ウチウラマチノウキヨウ [6121]',
	'ウツノミヤノウキヨウ [4445]',
	'うま農業協同組合 [8389]',
	'ウマジムラノウキヨウ [8511]',
	'ウマノウキヨウ [8389]',
	'ウラホロチヨウノウキヨウ [3287]',
	'ウリ信用組合 [2014]',
	'ウリイ [0477]',
	'ウリィ銀行 [0477]',
	'ウリシンクミ [2014]',
	'ウワジマシンキン [1862]',
	'エーユージブン [0039]',
	'エイワシンキン [1643]',
	'エサシシンキン [1014]',
	'エスエムビ－シ－シンタク [0300]',
	'エスビ－ジエ－ [0472]',
	'エスビーアイシンセイ [0397]',
	'えちご上越農業協同組合 [5768]',
	'エチゴオヂヤノウキヨウ [5690]',
	'エチゴサントウノウキヨウ [5685]',
	'エチゴジヨウエツノウキヨウ [5768]',
	'エチゴナガオカノウキヨウ [5666]',
	'エチゼンシンキン [1475]',
	'エチゼンタケフノウキヨウ [6853]',
	'エチゼンニユウノウキヨウ [6841]',
	'えびの市農業協同組合 [9197]',
	'エビノシノウキヨウ [9197]',
	'エヒメ [0576]',
	'えひめ南農業協同組合 [8482]',
	'えひめ中央農業協同組合 [8500]',
	'エヒメケンシンギヨレン [9487]',
	'エヒメケンシンレン [3038]',
	'エヒメシンキン [1860]',
	'エヒメタイキノウキヨウ [8457]',
	'エヒメチユウオウノウキヨウ [8500]',
	'エヒメミナミノウキヨウ [8482]',
	'エンガルシンキン [1033]',
	'エンシユウシンキン [1517]',
	'エンシユウチユウオウノウキヨウ [6391]',
	'エンシユウユメサキノウキヨウ [6387]',
	'えんゆう農業協同組合 [3306]',
	'エンユウノウキヨウ [3306]',
	'おいらせ農業協同組合 [3474]',
	'オイラセノウキヨウ [3474]',
	'おうみ冨士農業協同組合 [6888]',
	'オウミフジノウキヨウ [6888]',
	'オウメシンキン [1358]',
	'オウラタテバヤシノウキヨウ [4677]',
	'オエグンノウキヨウ [8305]',
	'オオイガワノウキヨウ [6377]',
	'オオイタ [0183]',
	'オオイタオオヤママチノウキヨウ [9145]',
	'オオイタケンギヨキヨウ [9493]',
	'オオイタケンシンクミ [2870]',
	'オオイタケンシンレン [3044]',
	'オオイタケンノウキヨウ [9104]',
	'オオイタシンキン [1960]',
	'オオイタミライシンキン [1962]',
	'オオガキキヨウリツ [0152]',
	'オオガキシンキン [1531]',
	'オオガタムラノウキヨウ [3929]',
	'オオカワシンキン [1917]',
	'オオサカキヨウエイシンクミ [2543]',
	'オオサカコウセイシンキン [1633]',
	'オオサカシテイシンキン [1635]',
	'オオサカシノウキヨウ [7200]',
	'オオサカシヨウコウシンキン [1636]',
	'オオサカシンキン [1630]',
	'オオサカセンシユウノウキヨウ [7087]',
	'オオサカチヨチクシンクミ [2548]',
	'オオサカトウブノウキヨウ [7184]',
	'オオサカナカカワチノウキヨウ [7164]',
	'オオサカフイシシンクミ [2560]',
	'オオサカフケイサツシンクミ [2566]',
	'オオサカフシンレン [3027]',
	'オオサカホクブノウキヨウ [7041]',
	'オオサカミナミノウキヨウ [7139]',
	'おおぞら農業協同組合 [6113]',
	'オオゾラノウキヨウ [6113]',
	'オオタシノウキヨウ [4665]',
	'オオタワラシンキン [1225]',
	'オオツマツシゲノウキヨウ [8261]',
	'オオフナトシノウキヨウ [3598]',
	'オオムタヤナガワシンキン [1908]',
	'オカザキシンキン [1552]',
	'おかやま信用金庫 [1732]',
	'オカヤマシノウキヨウ [7755]',
	'オカヤマシヨウギンシンクミ [2673]',
	'オカヤマシンキン [1732]',
	'オカヤマニシノウキヨウ [7794]',
	'オカヤマヒガシノウキヨウ [7768]',
	'オキナワ [0188]',
	'オキナワカイホウ [0596]',
	'オキナワケンシンギヨレン [9496]',
	'オキナワケンノウキヨウ [9375]',
	'オキナワケンロウキン [2997]',
	'オシマシンキン [1013]',
	'オスズノウキヨウ [9203]',
	'オタルシンキン [1016]',
	'オダワラダイイチシンクミ [2315]',
	'オチイマバリノウキヨウ [8400]',
	'オトフケチヨウノウキヨウ [3278]',
	'オノミチシノウキヨウ [8029]',
	'オバマシンキン [1473]',
	'オビヒロシカワニシノウキヨウ [3264]',
	'オビヒロシンキン [1026]',
	'オビヒロタイシヨウノウキヨウ [3265]',
	'オホ－ツクアバシリノウキヨウ [3326]',
	'オホ－ツクハマナスノウキヨウ [3301]',
	'オホーツク網走農業協同組合 [3326]',
	'オホーツクはまなす農業協同組合 [3301]',
	'オヤマノウキヨウ [4490]',
	'オリツクス [0307]',
	'オリックス銀行 [0307]',
	'オロロン農業協同組合 [3202]',
	'オロロンノウキヨウ [3202]',
	'オワリチユウオウノウキヨウ [6451]',
	'オンガシンキン [1920]',
	'かいふ農業協同組合 [8288]',
	'カイフノウキヨウ [8288]',
	'カガノウキヨウ [5943]',
	'カガワ [0573]',
	'カガワケンシンギヨレン [9486]',
	'カガワケンシンクミ [2721]',
	'カガワケンシンレン [3037]',
	'カガワケンノウキヨウ [8332]',
	'カケガワシノウキヨウ [6386]',
	'カコガワシミナミノウキヨウ [7274]',
	'カゴシマ [0185]',
	'かごしま中央農業協同組合 [9229]',
	'カゴシマイズミノウキヨウ [9302]',
	'カゴシマキモツキノウキヨウ [9341]',
	'カゴシマケンイシシンクミ [2891]',
	'カゴシマケンシンギヨレン [9495]',
	'カゴシマケンシンレン [3046]',
	'カゴシマコウギヨウシンクミ [2890]',
	'カゴシマシンキン [1990]',
	'カゴシマソウゴシンキン [1991]',
	'カゴシマチユウオウノウキヨウ [9229]',
	'カサオカシンクミ [2674]',
	'カシワザキシンキン [1375]',
	'カシワザキノウキヨウ [5720]',
	'カスヤノウキヨウ [8626]',
	'かづの農業協同組合 [3762]',
	'カヅノノウキヨウ [3762]',
	'かとり農業協同組合 [5000]',
	'カトリノウキヨウ [5000]',
	'カナガワ [0530]',
	'かながわ西湘農業協同組合 [5147]',
	'かながわ信用金庫 [1281]',
	'カナガワケンイシシンクミ [2304]',
	'カナガワケンシカイシシンクミ [2305]',
	'カナガワケンシンレン [3014]',
	'カナガワシンキン [1281]',
	'カナガワセイシヨウノウキヨウ [5147]',
	'カナザワシノウキヨウ [6025]',
	'カナザワシンキン [1440]',
	'カナザワチユウオウシンクミ [2411]',
	'カナザワチユウオウノウキヨウ [6024]',
	'カヌマソウゴシンキン [1223]',
	'カネヤマノウキヨウ [3987]',
	'ガマゴオリシノウキヨウ [6606]',
	'ガマゴオリシンキン [1562]',
	'カミイナノウキヨウ [5384]',
	'カミカワチユウオウノウキヨウ [3225]',
	'カミシホロチヨウノウキヨウ [3280]',
	'カミツガノウキヨウ [4456]',
	'かみはやし農業協同組合 [5815]',
	'カミハヤシノウキヨウ [5815]',
	'カミマシキノウキヨウ [8982]',
	'カミヨツバノウキヨウ [3710]',
	'カメアリシンキン [1323]',
	'カモシンキン [1380]',
	'カモトノウキヨウ [8941]',
	'カラスヤマシンキン [1227]',
	'カラツシンキン [1930]',
	'カラツノウキヨウ [8766]',
	'カワカミブツサンノウキヨウ　 [5330]',
	'カワグチシンキン [1251]',
	'カワサキシンキン [1283]',
	'カワノエシンキン [1866]',
	'カンオンジシンキン [1833]',
	'カンサイミライギンコウ [0159]',
	'カンラトミオカノウキヨウ [4608]',
	'キクチチイキノウキヨウ [8949]',
	'キサラヅシノウキヨウ [4902]',
	'キシユウノウキヨウ [7565]',
	'キソノウキヨウ [5441]',
	'キタイシカリノウキヨウ [3145]',
	'キタイセウエノシンキン [1581]',
	'キタイブキノウキヨウ [3188]',
	'キタウオヌマノウキヨウ [5693]',
	'キタエチゴノウキヨウ [5554]',
	'キタオオサカシンキン [1645]',
	'キタオオサカノウキヨウ [7025]',
	'キタオホ－ツクノウキヨウ [3297]',
	'キタカミシンキン [1154]',
	'キタカワチノウキヨウ [7193]',
	'キタカンミナミノウキヨウ [5541]',
	'キタキユウシユウ [0191]',
	'キタキユウシユウノウキヨウ [8692]',
	'キタグンシブカワノウキヨウ [4593]',
	'キタグンシンクミ [2083]',
	'キタグンマシンキン [1210]',
	'キタサツマノウキヨウ [9296]',
	'キタソウヤノウキヨウ [3257]',
	'きたそらち農業協同組合 [3189]',
	'キタソラチシンキン [1010]',
	'キタソラチノウキヨウ [3189]',
	'キタツクバノウキヨウ [4397]',
	'キタニツポン [0509]',
	'キタハルカノウキヨウ [3248]',
	'キタヒビキノウキヨウ [3238]',
	'キタヒヤマチヨウノウキヨウ [3056]',
	'キタビワコノウキヨウ [6924]',
	'キタフジノウキヨウ [5284]',
	'キタミシンキン [1030]',
	'きたみらい農業協同組合 [3317]',
	'キタミライノウキヨウ [3317]',
	'キナンノウキヨウ [7576]',
	'きのくに信用金庫 [1674]',
	'キノクニシンキン [1674]',
	'キノサトノウキヨウ [7543]',
	'キノノウキヨウ [3277]',
	'キビシンキン [1741]',
	'ぎふ農業協同組合 [6129]',
	'ギフケンイシシンクミ [2473]',
	'ギフケンシンレン [3020]',
	'ギフシヨウコウシンクミ [2470]',
	'ギフシンキン [1530]',
	'ギフノウキヨウ [6129]',
	'キホクカワカミノウキヨウ [7550]',
	'キホクシンキン [1585]',
	'キミツシノウキヨウ [4909]',
	'キミツシンクミ [2190]',
	'キモツキアイラチヨウノウキヨウ [9347]',
	'キユウシユウコウギンシンクミ [2840]',
	'キユウシユウヒゼンシンキン [1933]',
	'キユウシユウロウキン [2990]',
	'キヨウ [0163]',
	'キヨウエイシンクミ [2360]',
	'キヨウト [0158]',
	'キヨウトシノウキヨウ [6941]',
	'キヨウトシンキン [1610]',
	'キヨウトチユウオウシンキン [1611]',
	'キヨウトチユウオウノウキヨウ [6956]',
	'キヨウトニノクニノウキヨウ [6996]',
	'キヨウトノウキヨウ [6990]',
	'キヨウトフシンギヨレン [9475]',
	'キヨウトフシンレン [3026]',
	'キヨウトホクトシンキン [1620]',
	'キヨウトヤマシロノウキヨウ [6961]',
	'キヨウリツシンクミ [2241]',
	'きょうわ農業協同組合 [3087]',
	'キヨウワノウキヨウ [3087]',
	'キヨサトチヨウノウキヨウ [3330]',
	'キラボシ [0137]',
	'きらぼし銀行 [0137]',
	'キラヤカ [0508]',
	'きらやか銀行 [0508]',
	'キリユウシンキン [1204]',
	'キンキサンギヨウシンクミ [2567]',
	'キンキロウキン [2978]',
	'クカシヨウノウキヨウ [7191]',
	'クサツシノウキヨウ [6883]',
	'クシマシオオツカノウキヨウ [9181]',
	'クシロオオタノウキヨウ [3334]',
	'クシロシンキン [1027]',
	'クシロシンクミ [2025]',
	'クシロタンチヨウノウキヨウ [3339]',
	'クスココノエノウキヨウ [9137]',
	'くまがや農業協同組合 [4808]',
	'クマガヤシヨウコウシンクミ [2165]',
	'クマガヤノウキヨウ [4808]',
	'クマチイキノウキヨウ [9048]',
	'クマモト [0587]',
	'クマモトウキノウキヨウ [9010]',
	'クマモトケンイシシンクミ [2842]',
	'クマモトケンシンクミ [2845]',
	'クマモトシノウキヨウ [8916]',
	'クマモトシンキン [1951]',
	'クマモトダイイチシンキン [1952]',
	'クマモトチユウオウシンキン [1954]',
	'クラシキカサヤノウキヨウ [7825]',
	'クラヨシシンキン [1703]',
	'グリ－ンオウミノウキヨウ [6897]',
	'グリ－ンオオサカノウキヨウ [7156]',
	'グリ－ンカゴシマノウキヨウ [9234]',
	'グリ－ンナガノノウキヨウ [5477]',
	'グリーン近江農業協同組合 [6897]',
	'グリーン大阪農業協同組合 [7156]',
	'グリーン鹿児島農業協同組合 [9234]',
	'グリーン長野農業協同組合 [5477]',
	'クルメシノウキヨウ [8660]',
	'クレイン農業協同組合 [5272]',
	'クレインノウキヨウ [5272]',
	'クレシシヨクインシンクミ [2686]',
	'クレシンキン [1752]',
	'クレデイ・アグリコル [0421]',
	'クレディ・アグリコル銀行 [0421]',
	'クレノウキヨウ [7913]',
	'クロベノウキヨウ [5883]',
	'クワナミエシンキン [1583]',
	'グンマ [0128]',
	'グンマケンイシシンクミ [2151]',
	'グンマケンシンクミ [2146]',
	'ぐんまみらい信用組合 [2149]',
	'グンマミライシンクミ [2149]',
	'ケイジシンクミ [2526]',
	'ケイシチヨウシヨクインシンクミ [2271]',
	'ゲイナンノウキヨウ [8011]',
	'ケイヨウ [0522]',
	'ケセンヌマシンキン [1175]',
	'ケネベツノウキヨウ [3350]',
	'ケンオウアイカワノウキヨウ [5153]',
	'コウエイシンクミ [2356]',
	'コウカノウキヨウ [6889]',
	'コウサンシンキン [1305]',
	'コウシシンクミ [2272]',
	'コウチ [0578]',
	'コウチケンシンギヨレン [9488]',
	'コウチケンシンレン [3039]',
	'コウチケンノウキヨウ [8582]',
	'コウチシノウキヨウ [8551]',
	'コウチシンキン [1881]',
	'コウトウシンクミ [2229]',
	'コウノウシンキン [1448]',
	'コウフシノウキヨウ [5222]',
	'コウフシンキン [1385]',
	'コウベシシヨクインシンクミ [2610]',
	'コウベシンキン [1680]',
	'コオリヤマシンキン [1182]',
	'ココノエマチハンダノウキヨウ [9140]',
	'コザ信用金庫 [1996]',
	'コザシンキン [1996]',
	'コシガヤシノウキヨウ [4847]',
	'コシミズチヨウノウキヨウ [3328]',
	'ごしょつがる農業協同組合 [3442]',
	'ゴシヨツガルノウキヨウ [3442]',
	'コスモス農業協同組合 [8582]',
	'コスモスノウキヨウ [8582]',
	'ゴテンバノウキヨウ [6351]',
	'ごとう農業協同組合 [8893]',
	'コトウシンキン [1604]',
	'コトウノウキヨウ [6911]',
	'ゴトウノウキヨウ [8893]',
	'こばやし農業協同組合 [9193]',
	'コバヤシノウキヨウ [9193]',
	'こまち農業協同組合 [3913]',
	'コマチノウキヨウ [3913]',
	'コマツガワシンキン [1326]',
	'コマツシノウキヨウ [5962]',
	'コマノノウキヨウ [5243]',
	'コユノウキヨウ [9200]',
	'さいかつ農業協同組合 [4864]',
	'サイカツノウキヨウ [4864]',
	'サイキチユウオウノウキヨウ [7938]',
	'サイキヨウ [0570]',
	'サイキヨウシンキン [1336]',
	'サイジヨウシノウキヨウ [8395]',
	'さいたま農業協同組合 [4682]',
	'サイタマオカベノウキヨウ [4820]',
	'サイタマケンイシシンクミ [2162]',
	'サイタマケンシンキン [1250]',
	'サイタマケンシンレン [3011]',
	'サイタマシンクミ [2167]',
	'サイタマチユウオウノウキヨウ [4780]',
	'サイタマノウキヨウ [4682]',
	'サイタマヒビキノノウキヨウ [4802]',
	'サイタマミズホノウキヨウ [4859]',
	'サイタマリソナ [0017]',
	'サイトノウキヨウ [9205]',
	'サガ [0179]',
	'サカイシノウキヨウ [7111]',
	'さがえ西村山農業協同組合 [3943]',
	'サガエニシムラヤマノウキヨウ [3943]',
	'サガキヨウエイ [0583]',
	'サガケンイシシンクミ [2802]',
	'サガケンシンギヨレン [9490]',
	'サガケンシンレン [3041]',
	'サガケンノウキヨウ [8762]',
	'サガシチユウオウノウキヨウ [8740]',
	'サガシンキン [1931]',
	'サカタシソデウラノウキヨウ [4036]',
	'サガニシシンクミ [2808]',
	'サガヒガシシンクミ [2803]',
	'さがみ農業協同組合 [5131]',
	'さがみ信用金庫 [1288]',
	'サガミシンキン [1288]',
	'サガミノウキヨウ [5131]',
	'サガミハラシノウキヨウ [5159]',
	'サクアサマノウキヨウ [5335]',
	'さくらの街信用組合 [2358]',
	'サクラノマチシンクミ [2358]',
	'ささかみ農業協同組合 [5542]',
	'ササカミノウキヨウ [5542]',
	'サセボチユウオウシンクミ [2826]',
	'サツナイノウキヨウ [3281]',
	'サツポロシノウキヨウ [3133]',
	'サツポロチユウオウシンクミ [2013]',
	'さつま日置農業協同組合 [9270]',
	'サツマヒオキノウキヨウ [9270]',
	'サツラク農業協同組合 [3154]',
	'サツラクノウキヨウ [3154]',
	'サトウラノウキヨウ [8263]',
	'サドノウキヨウ [5832]',
	'サノシンキン [1224]',
	'サノノウキヨウ [4523]',
	'サラベツムラノウキヨウ [3267]',
	'サロマチヨウノウキヨウ [3303]',
	'サワイセサキノウキヨウ [4652]',
	'さわやか信用金庫 [1310]',
	'サワヤカシンキン [1310]',
	'サワラシンキン [1267]',
	'サワラノウキヨウ [5002]',
	'サンインゴウドウ [0167]',
	'サンジユウサン [0154]',
	'サンジヨウシンキン [1373]',
	'サンジヨウシンクミ [2361]',
	'サンブグンシノウキヨウ [4929]',
	'ジ－エムオ－アオゾラネツト [0310]',
	'ジエ－ピ－モルガン [0402]',
	'ジェー・ピー・モルガン・チェース・バンク・ナショナル・アソシエーション [0402]',
	'しおさい農業協同組合 [4301]',
	'シオサイノウキヨウ [4301]',
	'しおざわ農業協同組合 [5706]',
	'シオザワシンクミ [2365]',
	'シオザワノウキヨウ [5706]',
	'シオジリシノウキヨウ [5449]',
	'シオノヤノウキヨウ [4497]',
	'シガ [0157]',
	'シカオイチヨウノウキヨウ [3276]',
	'シガガモウチヨウノウキヨウ [6900]',
	'シガケンシンクミ [2505]',
	'シガケンシンレン [3025]',
	'シガケンミンシンクミ [2504]',
	'シガチユウオウシンキン [1602]',
	'シカノウキヨウ [6084]',
	'シコク [0175]',
	'シコクロウキン [2987]',
	'シサンカンリサ－ビスシンタク [0325]',
	'シズオカ [0149]',
	'シズオカケンイシシンクミ [2332]',
	'シズオカケンシンギヨレン [9470]',
	'シズオカケンシンレン [3021]',
	'シズオカケンロウキン [2968]',
	'シズオカシノウキヨウ [6373]',
	'シズオカチユウオウ [0538]',
	'シズオカヤイヅシンキン [1501]',
	'しずない農業協同組合 [3125]',
	'シズナイノウキヨウ [3125]',
	'シチジユウシチ [0125]',
	'シチトウシンクミ [2243]',
	'シテイバンク [0401]',
	'シティバンク銀行 [0401]',
	'しののめ信用金庫 [1211]',
	'シノノメシンキン [1211]',
	'シバシンキン [1319]',
	'シバタシンキン [1374]',
	'ジブン [0039]',
	'じぶん銀行 [0039]',
	'シベチヤチヨウノウキヨウ [3336]',
	'シベツチヨウノウキヨウ [3348]',
	'シホロチヨウノウキヨウ [3279]',
	'シマダカケガワシンキン [1513]',
	'シマダシンキン [1509]',
	'しまなみ信用金庫 [1756]',
	'シマナミシンキン [1756]',
	'シマネ [0565]',
	'しまね信用金庫 [1710]',
	'シマネケンノウキヨウ [7708]',
	'シマネシンキン [1710]',
	'シマネチユウオウシンキン [1712]',
	'シマネマスダシンクミ [2661]',
	'シマバラウンゼンノウキヨウ [8829]',
	'シミズ [0151]',
	'シミズノウキヨウ [6363]',
	'シモツケノウキヨウ [4478]',
	'シヤリチヨウノウキヨウ [3329]',
	'シユウソウノウキヨウ [8398]',
	'ジュウハチシンワ [0181]',
	'ジユウロク [0153]',
	'シヨウエイノウキヨウ [7889]',
	'ジヨウエツシンキン [1376]',
	'シヨウコウチユウキン [2004]',
	'ジヨウソウヒカリノウキヨウ [4413]',
	'シヨウナイ [0121]',
	'シヨウナイタガワノウキヨウ [4013]',
	'シヨウナイミドリノウキヨウ [4027]',
	'シヨウナンシンキン [1282]',
	'ジヨウナンシンキン [1344]',
	'シヨウナンノウキヨウ [5137]',
	'シヨウバラノウキヨウ [8076]',
	'ジヨウホクシンキン [1351]',
	'ジヨウヨウ [0130]',
	'シヨウワシンキン [1345]',
	'シラカワシンキン [1184]',
	'シンアキタノウキヨウ [3810]',
	'シンアサヒチヨウノウキヨウ [6933]',
	'シンイワテノウキヨウ [3517]',
	'シンエイシンクミ [2357]',
	'シンオタルノウキヨウ [3094]',
	'しんきん信託銀行 [0309]',
	'シンキンシンタク [0309]',
	'シンキンチユウオウキンコ [1000]',
	'シングウシンキン [1671]',
	'シンシノツムラノウキヨウ [3147]',
	'シンシユウウエダノウキヨウ [5348]',
	'シンシユウスワノウキヨウ [5372]',
	'シンジヨウシノウキヨウ [3971]',
	'シンジヨウシンキン [1143]',
	'シンジヨウモガミノウキヨウ [3973]',
	'シンスナガワノウキヨウ [3175]',
	'シンセイ [0397]',
	'シントクチヨウノウキヨウ [3275]',
	'シンハコダテノウキヨウ [3068]',
	'シンヒタチノノウキヨウ [4394]',
	'シンフクシマノウキヨウ [4047]',
	'シンミナトシンキン [1404]',
	'シンミヤギノウキョウ [3721]',
	'シンワ [0181]',
	'スイゴウツクバノウキヨウ [4344]',
	'スカガワイワセノウキヨウ [4091]',
	'スカガワシンキン [1185]',
	'スガモシンキン [1356]',
	'スクモシヨウギンシンクミ [2741]',
	'スズカノウキヨウ [6665]',
	'スズシノウキヨウ [6122]',
	'スミシンエスビ－アイネツト [0038]',
	'スルガ [0150]',
	'スルガ銀行 [0150]',
	'スワシンキン [1393]',
	'セイキヨウシンクミ [2541]',
	'セイシンシンキン [1502]',
	'セイブシンキン [1341]',
	'セイリカイシユウキコウ [2213]',
	'セイワシンクミ [2231]',
	'セキシンキン [1534]',
	'セタガヤシンキン [1348]',
	'セタガヤメグロノウキヨウ [5095]',
	'セトシンキン [1554]',
	'セバノウキヨウ [5462]',
	'セブン [0034]',
	'セブン銀行 [0034]',
	'セレサ川崎農業協同組合 [5123]',
	'セレサカワサキノウキヨウ [5123]',
	'ゼンシンクミレン [2010]',
	'センダイ [0512]',
	'センダイノウキヨウ [3636]',
	'ゼントウエイシンクミ [2202]',
	'センナンシンキン [1174]',
	'センポクシンクミ [2063]',
	'ソウアイシンクミ [2318]',
	'ソウソウゴジヨウシンクミ [2095]',
	'ソウマムラノウキヨウ [3390]',
	'ソウヤミナミノウキヨウ [3261]',
	'そお鹿児島農業協同組合 [9332]',
	'ソオカゴシマノウキヨウ [9332]',
	'ソニ－ [0035]',
	'ソニー銀行 [0035]',
	'そらち南農業協同組合 [3172]',
	'ソラチシヨウコウシンクミ [2019]',
	'ソラチシンキン [1004]',
	'ソラチミナミノウキヨウ [3172]',
	'ダイイチカンギヨウシンクミ [2254]',
	'タイキチヨウノウキヨウ [3269]',
	'タイコウ [0532]',
	'ダイシ [0140]',
	'ダイシホクエツ [0140]',
	'タイシヨウ [0555]',
	'たいせつ農業協同組合 [3214]',
	'タイセツノウキヨウ [3214]',
	'ダイチミライシンキン [1028]',
	'ダイトウ [0514]',
	'ダイトウキヨウシンクミ [2248]',
	'ダイドウシンクミ [2540]',
	'タイナイシノウキヨウ [5568]',
	'ダイホクノウキヨウ [5470]',
	'ダイワネクスト [0041]',
	'タイワンチュウショウキギョウ [0633]',
	'タカオカシノウキヨウ [5916]',
	'タカオカシンキン [1402]',
	'タカサキシノウキヨウ [4563]',
	'タカサキシンキン [1203]',
	'タカチホチクノウキヨウ [9221]',
	'タカツキシノウキヨウ [7029]',
	'タカナベシンキン [1985]',
	'タカノスマチノウキヨウ [3771]',
	'タカマツシンキン [1830]',
	'タカヤマシンキン [1532]',
	'タガワシンキン [1913]',
	'タガワノウキヨウ [8715]',
	'たきかわ農業協同組合 [3177]',
	'タキカワノウキヨウ [3177]',
	'タキグンノウキヨウ [6697]',
	'タキノガワシンキン [1352]',
	'タコマチノウキヨウ [5011]',
	'タジマ [0164]',
	'たじま農業協同組合 [7338]',
	'タジマシンキン [1692]',
	'タジマノウキヨウ [7338]',
	'たちばな信用金庫 [1942]',
	'タチバナシンキン [1942]',
	'ダテシノウキヨウ [3107]',
	'ダテシンキン [1009]',
	'タテバヤシシンキン [1209]',
	'タテヤマシンキン [1264]',
	'タネヤクノウキヨウ [9353]',
	'タノフジオカノウキヨウ [4594]',
	'タマシマシンキン [1738]',
	'タマシンキン [1360]',
	'タマナシオオハママチノウキヨウ [8934]',
	'タマナノウキヨウ [8926]',
	'タンバササヤマノウキヨウ [7362]',
	'タンバヒカミノウキヨウ [7353]',
	'タンヨウシンキン [1696]',
	'ダンヨウシンクミ [2616]',
	'チクゴシンキン [1909]',
	'チクシノウキヨウ [8636]',
	'チクゼンアサクラノウキヨウ [8645]',
	'チクホウ [0178]',
	'チタシンキン [1556]',
	'ちちぶ農業協同組合 [4792]',
	'チチブノウキヨウ [4792]',
	'チバ [0134]',
	'ちば東葛農業協同組合 [4975]',
	'チバケンシンギヨレン [9461]',
	'チバコウギヨウ [0135]',
	'チバシンキン [1260]',
	'チバトウカツノウキヨウ [4975]',
	'ちばみどり農業協同組合 [5016]',
	'チバミドリノウキヨウ [5016]',
	'チバミライノウキヨウ [4954]',
	'チユウエイシンキン [1289]',
	'チユウオウシンクミ [2556]',
	'チユウオウロウキン [2963]',
	'チユウキヨウ [0544]',
	'チユウゴク [0168]',
	'チユウゴク [0489]',
	'チユウゴクロウキン [2984]',
	'チユウナンシンキン [1290]',
	'チユウニチシンキン [1565]',
	'チユウルイノウキヨウ [3268]',
	'チヨウギンニシシンクミ [2672]',
	'チヨウシシヨウコウシンクミ [2184]',
	'チヨウシシンキン [1261]',
	'チヨウセイノウキヨウ [4916]',
	'チヨクアンノウキヨウ [8694]',
	'ツアゲノウキヨウ [6673]',
	'つがる弘前農業協同組合 [3387]',
	'つがるにしきた農業協同組合 [3421]',
	'ツガルニシキタノウキヨウ [3421]',
	'ツガルヒロサキノウキヨウ [3387]',
	'ツガルミライノウキヨウ [3407]',
	'ツキガタチヨウノウキヨウ [3168]',
	'ツクイグンノウキヨウ [5162]',
	'ツクバ [0131]',
	'つくば市谷田部農業協同組合 [4371]',
	'つくば市農業協同組合 [4363]',
	'ツクバシノウキヨウ [4363]',
	'ツクバシヤタベノウキヨウ [4371]',
	'ツシマノウキヨウ [8906]',
	'ツシンキン [1580]',
	'ツチウラノウキヨウ [4357]',
	'ツナンマチノウキヨウ [5719]',
	'ツベツチヨウノウキヨウ [3319]',
	'ツマゴイムラノウキヨウ [4628]',
	'ツヤマシンキン [1735]',
	'ツヤマノウキヨウ [7868]',
	'ツルオカシノウキヨウ [4000]',
	'ツルオカシンキン [1142]',
	'ツルガシンキン [1471]',
	'ツルガミカタノウキヨウ [6860]',
	'ツルギシンキン [1445]',
	'ツルシンクミ [2378]',
	'テシオチヨウノウキヨウ [3206]',
	'テラル越前農業協同組合 [6832]',
	'テラルエチゼンノウキヨウ [6832]',
	'テンドウシノウキヨウ [3938]',
	'テンパクシンヨウノウキヨウ [6436]',
	'ドイツ [0430]',
	'ドイツ銀行 [0430]',
	'トウエイシンキン [1321]',
	'トウオウシンキン [1104]',
	'ドウオウノウキヨウ [3139]',
	'トウカイロウキン [2972]',
	'とうかつ中央農業協同組合 [4965]',
	'トウカツチユウオウノウキヨウ [4965]',
	'トウカツフタバノウキヨウ [4962]',
	'トウキヨウアオバノウキヨウ [5097]',
	'トウキヨウコウセイシンクミ [2224]',
	'トウキヨウサンキヨウシンキン [1333]',
	'トウキヨウシテイシンキン [1311]',
	'トウキヨウシヨウケンシンクミ [2215]',
	'トウキヨウシヨウボウシンクミ [2274]',
	'トウキヨウシンキン [1349]',
	'トウキヨウスタ－ [0526]',
	'トウキヨウスマイルノウキヨウ [5100]',
	'トウキヨウチユウオウノウキヨウ [5094]',
	'トウキヨウトシヨクインシンクミ [2276]',
	'トウキヨウトシンギヨレン [9462]',
	'トウキヨウトシンレン [3013]',
	'トウキヨウヒガシシンキン [1320]',
	'トウキヨウベイシンキン [1262]',
	'トウキヨウミドリノウキヨウ [5072]',
	'トウキヨウミナミノウキヨウ [5055]',
	'トウキヨウミライノウキヨウ [5077]',
	'トウキヨウムサシノウキヨウ [5087]',
	'トウザイシラカワノウキヨウ [4132]',
	'トウシユンシンキン [1566]',
	'ドウトウアサヒノウキヨウ [3354]',
	'トウトシンヨウノウキヨウ [6265]',
	'トウノウシンキン [1533]',
	'トウブノウキヨウ [9240]',
	'トウホウ [0126]',
	'トウホク [0124]',
	'ドウホクナヨロノウキヨウ [3244]',
	'トウホクロウキン [2954]',
	'トウマノウキヨウ [3223]',
	'とうや湖農業協同組合 [3103]',
	'トウヤコノウキヨウ [3103]',
	'トウヨクシンクミ [2210]',
	'トウヨシンキン [1864]',
	'トウワ [0516]',
	'トオカマチノウキヨウ [5714]',
	'トカチイケダチヨウノウキヨウ [3283]',
	'トカチシミズチヨウノウキヨウ [3273]',
	'トカチシンクミ [2024]',
	'トカチタカシマノウキヨウ [3285]',
	'トクシマ [0572]',
	'トクシマキタノウキヨウ [8257]',
	'トクシマケンシンギヨレン [9485]',
	'トクシマケンシンレン [3036]',
	'トクシマシノウキヨウ [8231]',
	'トクシマシンキン [1801]',
	'トコロチヨウノウキヨウ [3322]',
	'トサクロシオノウキヨウ [8589]',
	'トサシンクミ [2740]',
	'トチギ [0517]',
	'トチギシンキン [1222]',
	'トツトリ [0166]',
	'トツトリイナバノウキヨウ [7601]',
	'トツトリケンシンギヨレン [9480]',
	'トツトリケンシンレン [3031]',
	'トツトリシンキン [1701]',
	'トツトリセイブノウキヨウ [7641]',
	'トツトリチユウオウノウキヨウ [7625]',
	'となみ野農業協同組合 [5921]',
	'トナミシンキン [1412]',
	'トナミノノウキヨウ [5921]',
	'トネグンシンキン [1208]',
	'トネヌマタノウキヨウ [4632]',
	'トバシマノウキヨウ [6741]',
	'とぴあ浜松農業協同組合 [6403]',
	'トピアハママツノウキヨウ [6403]',
	'とびうめ信用組合 [2778]',
	'トビウメシンクミ [2778]',
	'とまこまい広域農業協同組合 [3112]',
	'トマコマイコウイキノウキヨウ [3112]',
	'トマコマイシンキン [1006]',
	'トマト [0566]',
	'トマト銀行 [0566]',
	'トママエチヨウノウキヨウ [3201]',
	'トミサトシノウキヨウ [4993]',
	'トヤマ [0145]',
	'トヤマケンイシシンクミ [2402]',
	'トヤマケンシンギヨレン [9467]',
	'トヤマケンシンクミ [2404]',
	'トヤマシノウキヨウ [5897]',
	'トヤマシンキン [1401]',
	'トヤマダイイチ [0534]',
	'トヨカワシンキン [1557]',
	'トヨコロチヨウノウキヨウ [3286]',
	'トヨタシンキン [1559]',
	'トヨハシシヨウコウシンクミ [2448]',
	'トヨハシシンキン [1551]',
	'トヨハシノウキヨウ [6618]',
	'トリヨウシンクミ [2045]',
	'トワダオイラセノウキヨウ [3455]',
	'ナガオカシンキン [1371]',
	'ナカコマトウブノウキヨウ [5234]',
	'ナガサキ [0585]',
	'ながさき西海農業協同組合 [8857]',
	'ナガサキケンイシシンクミ [2821]',
	'ナガサキケンオウノウキヨウ [8813]',
	'ナガサキケンシンギヨレン [9491]',
	'ナガサキケンミンシンクミ [2825]',
	'ナガサキサイカイノウキヨウ [8857]',
	'ナガサキセイヒノウキヨウ [8794]',
	'ナガサキミツビシシンクミ [2820]',
	'ナカサツナイムラノウキヨウ [3266]',
	'ナカシベツチヨウノウキヨウ [3349]',
	'ナカシユンベツノウキヨウ [3358]',
	'ナカトンベツチヨウノウキヨウ [3260]',
	'ながぬま農業協同組合 [3170]',
	'ナガヌマノウキヨウ [3170]',
	'ナガノ [0533]',
	'ながの農業協同組合 [5499]',
	'ナガノケンシンクミ [2390]',
	'ナガノケンシンレン [3016]',
	'ナガノケンロウキン [2966]',
	'ナカノゴウシンクミ [2235]',
	'ナカノシノウキヨウ [5491]',
	'ナガノシンキン [1390]',
	'ナガノノウキヨウ [5499]',
	'ナガノヤツガタケノウキヨウ [5311]',
	'ナガハマシンキン [1603]',
	'ナカヒヨウゴシンキン [1695]',
	'ながみね農業協同組合 [7541]',
	'ナガミネノウキヨウ [7541]',
	'ナゴヤ [0543]',
	'なごや農業協同組合 [6430]',
	'ナゴヤセイカブツシンクミ [2444]',
	'ナゴヤノウキヨウ [6430]',
	'ナスシンクミ [2125]',
	'ナスノノウキヨウ [4507]',
	'ナスミナミノウキヨウ [4518]',
	'ナトリイワヌマノウキヨウ [3652]',
	'なのはな農業協同組合 [5898]',
	'ナノハナノウキヨウ [5898]',
	'なめがた農業協同組合 [4310]',
	'ナメガタノウキヨウ [4310]',
	'ナラケンノウキヨウ [7387]',
	'ナラシンキン [1666]',
	'ナラチユウオウシンキン [1668]',
	'ナリタシノウキヨウ [4992]',
	'ナルサワムラノウキヨウ [5287]',
	'ナンゴウシンキン [1986]',
	'ナンサイノウキヨウ [4848]',
	'ナント [0162]',
	'なんと農業協同組合 [5927]',
	'ナントノウキヨウ [5927]',
	'ナンポロチヨウノウキヨウ [3161]',
	'にいがた南蒲農業協同組合 [5631]',
	'にいがた岩船農業協同組合 [5823]',
	'ニイガタイワフネノウキヨウ [5823]',
	'ニイガタカガヤキノウキヨウ [5600]',
	'ニイガタケンシンギヨレン [9466]',
	'ニイガタケンシンクミ [2351]',
	'ニイガタケンシンレン [3017]',
	'ニイガタケンロウキン [2965]',
	'ニイガタシノウキヨウ [5864]',
	'ニイガタシンキン [1370]',
	'ニイガタダイエイシンクミ [2363]',
	'ニイガタテツドウシンクミ [2354]',
	'ニイガタナンカンノウキヨウ [5631]',
	'ニイガタミライノウキヨウ [5577]',
	'ニイカツプチヨウノウキヨウ [3124]',
	'にいかわ信用金庫 [1405]',
	'ニイカワシンキン [1405]',
	'ニイツサツキノウキヨウ [5585]',
	'ニイハマシノウキヨウ [8397]',
	'にじ農業協同組合 [8653]',
	'ニシインバノウキヨウ [4996]',
	'ニシウワノウキヨウ [8463]',
	'ニシオシンキン [1561]',
	'ニシカスガイノウキヨウ [6456]',
	'ニシタマノウキヨウ [5037]',
	'ニシチユウゴクシンキン [1781]',
	'ニシトウキヨウノウキヨウ [5030]',
	'ニシニツポンシテイ [0190]',
	'ニジノウキヨウ [8653]',
	'ニシヒヨウゴシンキン [1694]',
	'ニシビワコノウキヨウ [6935]',
	'ニシミカワノウキヨウ [6560]',
	'ニシミノノウキヨウ [6175]',
	'ニシヤツシログンノウキヨウ [5207]',
	'ニツシンシンキン [1689]',
	'ニツタミドリノウキヨウ [4664]',
	'ニホンカイシンキン [1711]',
	'ニホントラステイサ－ビスシン [0324]',
	'ニホンマツシンキン [1189]',
	'ヌマヅシンキン [1505]',
	'ネアガリノウキヨウ [5980]',
	'ノウリンチユウキン [3000]',
	'のぞみ信用組合 [2549]',
	'ノゾミシンクミ [2549]',
	'のと共栄信用金庫 [1442]',
	'ノトキヨウエイシンキン [1442]',
	'ノトワカバノウキヨウ [6094]',
	'ノノイチノウキヨウ [6010]',
	'ノベオカシンキン [1982]',
	'ノベオカノウキヨウ [9208]',
	'ノミノウキヨウ [5982]',
	'ノムラシンタク [0304]',
	'ハイナン農業協同組合 [6382]',
	'ハイナンノウキヨウ [6382]',
	'はが野農業協同組合 [4463]',
	'ハガノノウキヨウ [4463]',
	'ハギヤマグチシンキン [1780]',
	'はくい農業協同組合 [6076]',
	'ハクイノウキヨウ [6076]',
	'はぐくみ農業協同組合 [4567]',
	'ハグクミノウキヨウ [4567]',
	'ハクサンノウキヨウ [6012]',
	'ハコダテシカメダノウキヨウ [3066]',
	'ハコダテシヨウコウシンクミ [2017]',
	'ハタシンキン [1880]',
	'ハダノシノウキヨウ [5140]',
	'ハチオウジシノウキヨウ [5050]',
	'ハチジユウニ [0143]',
	'ハチノヘノウキヨウ [3488]',
	'ハチマンシンキン [1538]',
	'ハナ信用組合 [2277]',
	'ハナサキフクイノウキヨウ [6810]',
	'ハナシンクミ [2277]',
	'ハナゾノノウキヨウ [4823]',
	'ハナマキシンキン [1155]',
	'ハナマキノウキヨウ [3553]',
	'ハマナカチヨウノウキヨウ [3335]',
	'ハママツイワタシンキン [1503]',
	'はまゆう農業協同組合 [9178]',
	'ハマユウノウキヨウ [9178]',
	'ハモチノウキヨウ [5847]',
	'ハリマ農業協同組合 [7326]',
	'ハリマノウキヨウ [7326]',
	'ハルエノウキヨウ [6823]',
	'ハレノクニオカヤマノウキヨウ [7837]',
	'バンク・オブ・アメリカ・エヌ・エイ [0403]',
	'ハンザワノウキヨウ [4821]',
	'バンシユウシンキン [1686]',
	'ハンダシンキン [1555]',
	'ハンノウシンキン [1253]',
	'ビエイチヨウノウキヨウ [3228]',
	'ヒガシアサヒカワノウキヨウ [3220]',
	'ヒガシウワノウキヨウ [8477]',
	'ヒガシカグラノウキヨウ [3219]',
	'ヒガシカワチヨウノウキヨウ [3227]',
	'ヒガシソウヤノウキヨウ [3259]',
	'ヒガシトクシマノウキヨウ [8234]',
	'ヒガシニツポン [0525]',
	'ヒガシネシノウキヨウ [3962]',
	'ヒガシノトガワノウキヨウ [6909]',
	'ヒガシビワコノウキヨウ [6912]',
	'ヒガシミノノウキヨウ [6287]',
	'ヒガシヤマグチシンキン [1789]',
	'ヒゴ [0182]',
	'ビサイシンキン [1563]',
	'ひすい農業協同組合 [5797]',
	'ヒスイノウキヨウ [5797]',
	'ビゼンシンキン [1743]',
	'ひだか東農業協同組合 [3129]',
	'ヒダカシンキン [1011]',
	'ヒダカヒガシノウキヨウ [3129]',
	'ヒタシンキン [1968]',
	'ヒダシンクミ [2476]',
	'ヒタチシタガノウキヨウ [4294]',
	'ヒタチノウキヨウ [4263]',
	'ヒダノウキヨウ [6313]',
	'ピツプチヨウノウキヨウ [3224]',
	'ヒナセシンキン [1742]',
	'ビバイシノウキヨウ [3164]',
	'びほく農業協同組合 [7837]',
	'ビホクシンキン [1740]',
	'ビホクノウキヨウ [7837]',
	'ビホロチヨウノウキヨウ [3320]',
	'ひまわり農業協同組合 [6612]',
	'ひまわり信用金庫 [1186]',
	'ヒマワリシンキン [1186]',
	'ヒマワリノウキヨウ [6612]',
	'ヒミシノウキヨウ [5920]',
	'ヒミフシキシンキン [1406]',
	'ヒメジシンキン [1685]',
	'ヒヤクゴ [0155]',
	'ヒヤクジユウシ [0173]',
	'ヒユウガノウキヨウ [9213]',
	'ヒヨウゴケンイリヨウシンクミ [2605]',
	'ヒヨウゴケンケイサツシンクミ [2602]',
	'ヒヨウゴケンシンギヨレン [9477]',
	'ヒヨウゴケンシンクミ [2606]',
	'ヒヨウゴケンシンレン [3028]',
	'ヒヨウゴシンキン [1687]',
	'ヒヨウゴニシノウキヨウ [7288]',
	'ヒヨウゴヒマワリシンクミ [2620]',
	'ヒヨウゴミナミノウキヨウ [7240]',
	'ヒヨウゴミライノウキヨウ [7264]',
	'ヒヨウゴロツコウノウキヨウ [7213]',
	'ヒラカタシンキン [1656]',
	'ヒラツカシンキン [1286]',
	'びらとり農業協同組合 [3120]',
	'ビラトリノウキヨウ [3120]',
	'ヒロオチヨウノウキヨウ [3270]',
	'ヒロシマ [0169]',
	'ヒロシマケンシンギヨレン [9483]',
	'ヒロシマケンシンクミ [2681]',
	'ヒロシマケンシンレン [3034]',
	'ヒロシマシシンクミ [2680]',
	'ヒロシマシノウキヨウ [7909]',
	'ヒロシマシヨウギンシンクミ [2684]',
	'ヒロシマシンキン [1750]',
	'ヒロシマチユウオウノウキヨウ [7994]',
	'ヒロシマホクブノウキヨウ [7981]',
	'ヒロシマミドリシンキン [1758]',
	'ヒロシマユタカノウキヨウ [8019]',
	'ビンゴシンクミ [2696]',
	'ピンネ農業協同組合 [3181]',
	'ピンネノウキヨウ [3181]',
	'フイリピン・ナシヨナル・バンク [0617]',
	'フエフキノウキヨウ [5199]',
	'ふかや農業協同組合 [4874]',
	'フカヤノウキヨウ [4874]',
	'フクイ [0147]',
	'フクイイケダチヨウノウキヨウ [6838]',
	'フクイケンイシシンクミ [2435]',
	'フクイケンシンギヨレン [9473]',
	'フクイケンシンレン [3024]',
	'フクイシナンブノウキヨウ [6789]',
	'フクイシノウキヨウ [6785]',
	'フクイシンキン [1470]',
	'フクイタンナンノウキヨウ [6836]',
	'フクエシンクミ [2833]',
	'フクオカ [0177]',
	'フクオカオオキノウキヨウ [8667]',
	'フクオカカホノウキヨウ [8701]',
	'フクオカケイチクノウキヨウ [8730]',
	'フクオカケンイシシンクミ [2753]',
	'フクオカケンシンギヨレン [9489]',
	'フクオカケンシンレン [3040]',
	'フクオカケンチユウオウシンクミ [2773]',
	'フクオカケンチヨウシンクミ [2751]',
	'フクオカケンナンブシンクミ [2763]',
	'フクオカシトウブノウキヨウ [8632]',
	'フクオカシノウキヨウ [8633]',
	'フクオカシンキン [1901]',
	'フクオカチユウオウ [0582]',
	'フクオカヒビキシンキン [1903]',
	'フクオカヤメノウキヨウ [8668]',
	'フクシマ [0513]',
	'ふくしま未来農業協同組合 [4047]',
	'フクシマケンシヨウコウシンクミ [2090]',
	'フクシマケンシンギヨレン [9456]',
	'フクシマシンキン [1190]',
	'フクセンシンクミ [2430]',
	'フクホウ [0537]',
	'フクミツノウキヨウ [5935]',
	'フクヤマシノウキヨウ [8047]',
	'フジイズノウキョウ [6345]',
	'ふじかわ農業協同組合 [5209]',
	'フジカワノウキヨウ [5209]',
	'フジシノウキヨウ [6355]',
	'フジシンキン [1515]',
	'フジノミヤシンキン [1507]',
	'フジノミヤノウキヨウ [6357]',
	'ふらの農業協同組合 [3231]',
	'フラノノウキヨウ [3231]',
	'フル－ツヤマナシノウキヨウ [5169]',
	'フルーツ山梨農業協同組合 [5169]',
	'フルカワシンクミ [2062]',
	'フルカワノウキヨウ [3704]',
	'ブンカサンギヨウシンクミ [2211]',
	'ペイペイ銀行 [0033]',
	'ペイペイギンコウ [0033]',
	'ヘキカイシンキン [1560]',
	'べっぷ日出農業協同組合 [9103]',
	'ベツプヒジノウキヨウ [9103]',
	'ボウソウシンクミ [2180]',
	'ホウワ [0590]',
	'ホクオウシンクミ [2011]',
	'ほくさい農業協同組合 [4828]',
	'ホクサイノウキヨウ [4828]',
	'ホクセイシンキン [1024]',
	'ホクト [0120]',
	'ホクモンシンキン [1008]',
	'ホクヨウ [0501]',
	'ホクリク [0144]',
	'ホクリクシンキン [1444]',
	'ホクリクロウキン [2970]',
	'ほこた農業協同組合 [4296]',
	'ホコタノウキヨウ [4296]',
	'ホツカイドウ [0116]',
	'ホツカイドウシンギヨレン [9450]',
	'ホツカイドウシンレン [3001]',
	'ホツカイドウロウキン [2951]',
	'ホッカイドシンキン [1001]',
	'ホツコク [0146]',
	'ホロノベチヨウノウキヨウ [3208]',
	'ホンコンシヤンハイ [0411]',
	'ホンドイツワノウキヨウ [9069]',
	'ホンベツチヨウノウキヨウ [3288]',
	'マイニチシンクミ [2581]',
	'マインズ農業協同組合 [5070]',
	'マインズノウキヨウ [5070]',
	'マエバシシノウキヨウ [4544]',
	'マキシンクミ [2362]',
	'マキノ町農業協同組合 [6931]',
	'マキノチヨウノウキヨウ [6931]',
	'マクベツチヨウノウキヨウ [3282]',
	'マシタシンクミ [2481]',
	'マシユウコノウキヨウ [3337]',
	'マチダシノウキヨウ [5060]',
	'マチノマチノウキヨウ [6117]',
	'マツトウシノウキヨウ [5997]',
	'マツモトシノウキヨウ [5447]',
	'マツモトシンキン [1391]',
	'マツモトハイランドノウキヨウ [5448]',
	'マツヤマシノウキヨウ [8425]',
	'マニワノウキヨウ [7859]',
	'マムロガワマチノウキヨウ [3986]',
	'マルハチシンクミ [2440]',
	'みい農業協同組合 [8656]',
	'ミイノウキヨウ [8656]',
	'ミウラシノウキヨウ [5130]',
	'ミエ [-]',
	'ミエキタノウキヨウ [6649]',
	'ミエケンシンギヨレン [9472]',
	'ミエケンシンレン [3023]',
	'ミエナンキノウキヨウ [6770]',
	'ミカタハラカイタクノウキヨウ [6426]',
	'みくまの農業協同組合 [7591]',
	'ミクマノノウキヨウ [7591]',
	'ミシマカンナミノウキヨウ [6333]',
	'ミシマシンキン [1506]',
	'ミズサワシンキン [1156]',
	'ミズシマシンキン [1734]',
	'ミズホ [0001]',
	'みずほ信託銀行 [0289]',
	'みずほ銀行 [0001]',
	'ミズホシンタク [0289]',
	'ミズママチノウキヨウ [8664]',
	'ミチノク [0118]',
	'みちのく銀行 [0118]',
	'みちのく村山農業協同組合 [3960]',
	'ミチノクムラヤマノウキヨウ [3960]',
	'みついし農業協同組合 [3126]',
	'ミツイシノウキヨウ [3126]',
	'ミツイスミトモ [0009]',
	'ミツイスミトモシンタク [0294]',
	'ミツカビチヨウノウキヨウ [6423]',
	'ミツビシＵＦＪシンタク [0288]',
	'ミツビシトウキヨウＵＦＪ [0005]',
	'ミツビシユーエフジエイ [0005]',
	'ミトシンキン [1240]',
	'ミトノウキヨウ [4238]',
	'ミドリシンヨウノウキヨウ [6443]',
	'みな穂農業協同組合 [5877]',
	'ミナト [0562]',
	'みなと銀行 [0562]',
	'ミナホノウキヨウ [5877]',
	'みなみ信州農業協同組合 [5405]',
	'ミナミサツマノウキヨウ [9257]',
	'ミナミシンシユウノウキヨウ [5405]',
	'ミナミチクゴノウキヨウ [8689]',
	'ミナミニツポン [0594]',
	'ミナミルモイノウキヨウ [3200]',
	'ミネノブノウキヨウ [3165]',
	'みのり農業協同組合 [7249]',
	'ミノリノウキヨウ [7249]',
	'ミハラノウキヨウ [8027]',
	'ミマノウキヨウ [8312]',
	'みやぎ亘理農業協同組合 [3653]',
	'みやぎ登米農業協同組合 [3665]',
	'みやぎ仙南農業協同組合 [3751]',
	'ミヤギケンギヨキヨウ [9453]',
	'ミヤギセンナンノウキヨウ [3751]',
	'ミヤギダイイチシンキン [1171]',
	'ミヤギトメノウキヨウ [3665]',
	'ミヤギワタリノウキヨウ [3653]',
	'ミヤコシンキン [1152]',
	'ミヤコノジヨウシンキン [1981]',
	'ミヤコノジヨウノウキヨウ [9184]',
	'ミヤザキ [0184]',
	'ミヤザキケンシンギヨレン [9494]',
	'ミヤザキケンシンレン [3045]',
	'ミヤザキケンナンブシンクミ [2884]',
	'ミヤザキシンキン [1980]',
	'ミヤザキタイヨウ [0591]',
	'ミヤザキチユウオウノウキヨウ [9169]',
	'ミヨウザイグンノウキヨウ [8242]',
	'ミヨシノウキヨウ [8069]',
	'ミレ信用組合 [2582]',
	'ミレシンクミ [2582]',
	'ﾐﾝﾅﾉ [0043]',
	'ミンナノ [0043]',
	'みんなの銀行 [0043]',
	'ムカワノウキヨウ [3114]',
	'ムサシノ [0133]',
	'ムナカタノウキヨウ [8621]',
	'ムラカミシンキン [1379]',
	'ムロランシンキン [1003]',
	'めぐみの農業協同組合 [6242]',
	'メグミノノウキヨウ [6242]',
	'メグロシンキン [1346]',
	'メマンベツチヨウノウキヨウ [3321]',
	'メムロチヨウノウキヨウ [3271]',
	'モオカシンクミ [2122]',
	'モミジ [0569]',
	'もみじ銀行 [0569]',
	'モリオカシンキン [1150]',
	'モリノミヤコシンキン [1170]',
	'モンベツチヨウノウキヨウ [3122]',
	'やさと農業協同組合 [4387]',
	'ヤサトノウキヨウ [4387]',
	'ヤチヨシノウキヨウ [4955]',
	'ヤツシロチイキノウキヨウ [9017]',
	'ヤナガワノウキヨウ [8680]',
	'ヤマガタ [0122]',
	'ヤマガタオキタマノウキヨウ [3989]',
	'ヤマガタシノウキヨウ [3931]',
	'ヤマガタシンキン [1140]',
	'ヤマガタダイイチシンクミ [2085]',
	'ヤマガタチユウオウシンクミ [2084]',
	'ヤマガタノウキヨウ [3932]',
	'ヤマガタモガミノウキヨウ [3980]',
	'ヤマグチ [0170]',
	'ヤマグチケンギヨキヨウ [9484]',
	'ヤマグチケンシンクミ [2703]',
	'ヤマグチケンシンレン [3035]',
	'ヤマグチケンノウキヨウ [8134]',
	'ヤマグチチユウオウノウキヨウ [8153]',
	'ヤマダムラノウキヨウ [5906]',
	'ヤマトシンキン [1667]',
	'ヤマナシケンシンレン [3015]',
	'ヤマナシケンミンシンクミ [2377]',
	'ヤマナシシンキン [1386]',
	'ヤマナシチユウオウ [0142]',
	'ユーアイギンコウ [0044]',
	'ゆうき青森農業協同組合 [3469]',
	'ユウキアオモリノウキヨウ [3469]',
	'ユウキシンキン [1242]',
	'ユウチョ [9900]',
	'ゆうちょ銀行 [9900]',
	'ユウバリシノウキヨウ [3173]',
	'ユウベツチヨウノウキヨウ [3305]',
	'ヨイチチヨウノウキヨウ [3095]',
	'ようてい農業協同組合 [3086]',
	'ヨウテイノウキヨウ [3086]',
	'よこすか葉山農業協同組合 [5128]',
	'ヨコスカハヤマノウキヨウ [5128]',
	'ヨコハマ [0138]',
	'ヨコハマカギンシンクミ [2307]',
	'ヨコハマシンキン [1280]',
	'ヨコハマチユウオウシンクミ [2306]',
	'ヨコハマノウキヨウ [5114]',
	'ヨシダグンノウキヨウ [6805]',
	'ヨナゴシンキン [1702]',
	'ヨネザワシンキン [1141]',
	'ラクテン [0036]',
	'リクベツチヨウノウキヨウ [3290]',
	'リソナ [0010]',
	'りそな銀行 [0010]',
	'リツトウシノウキヨウ [6885]',
	'リホクノウキヨウ [5260]',
	'リユウキユウ [0187]',
	'リヨウビシンクミ [2690]',
	'ルモイシンキン [1022]',
	'レ－クイブキノウキヨウ [6919]',
	'レ－クシガノウキヨウ [6874]',
	'レーク伊吹農業協同組合 [6919]',
	'レーク滋賀農業協同組合 [6874]',
	'レイホクマチノウキヨウ [9072]',
	'ローソン [0042]',
	'ロウキンレン [2950]',
	'ワカサノウキヨウ [6863]',
	'わかやま農業協同組合 [7532]',
	'ワカヤマケンイシシンクミ [2634]',
	'ワカヤマケンシンギヨレン [9479]',
	'ワカヤマケンシンレン [3030]',
	'ワカヤマノウキヨウ [7532]',
	'ワツカナイシンキン [1021]',
	'ワツカナイノウキヨウ [3254]',
];

export const thaBankList = [
	'SIAM COMMERCIAL BANK',
	'BANGKOK BANK',
	'KASIKORN BANK',
	'THE ROYAL BANK OF SCOTLAND N.V.,',
	'KRUNG THAI BANK',
	'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
	'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.',
	'TMBThanachart Bank',
	'Bank of Thailand',
	'Mega International Commercial Bank PCL',
	'THE SIAM CITY BANK',
	'CITIBANK',
	'SUMITOMO MITSUI BANKING CORPORATION',
	'STANDARD CHARTERED BANK (THAI)',
	'CIMB THAI BANK',
	'UNITED OVERSEAS BANK (THAI)',
	'BANK OF AYUDHYA PUBLIC COMPANY LTD.',
	'BANK OF AMERICA, NATIONAL ASSOCIATION',
	'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK',
	'THE GOVERNMENT SAVINGS BANK',
	'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.',
	'DEUTSCHE BANK AG.',
	'THE GOVERNMENT HOUSING BANK',
	'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES',
	'MIZUHO CORPORATE BANK, LTD.',
	'BNP PARIBAS',
	'BANK OF CHINA LIMITED',
	'ISLAMIC BANK OF THAILAND',
	'TISCO BANK',
	'KIATNAKIN BANK',
	'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI)',
	'THE THAI CREDIT RETAIL BANK',
	'LAND AND HOUSES PUBLIC COMPANY LIMITED',
	'ANZ BANK (THAI) PUBLIC COMPANY LIMITED',
	'INDIAN OVERSEA BANK',
	'RHB BANK BERHAD',
	'EXPORT-IMPORT BANK OF THAILAND',
	'OVER SEA-CHINESE BANKING CORPORATION LIMITED',
	'SMALL AND MEDIUM ENTERPRISE DEVELOPMENT BANK OF THAILAND',
];

export const canBankList = [
	'ADS Canadian Bank',
	'B2B Bank',
	'Bank of Montreal',
	'The Bank of Nova Scotia',
	'Bridgewater Bank',
	'Caisse populaire acadienne ltee',
	'Canadian Imperial Bank of Commerce',
	'Canadian Tire Bank',
	'Canadian Western Bank',
	'Concentra Bank',
	'Continental Bank of Canada',
	'CS Alterna Bank',
	'DirectCash Bank',
	'Equitable Bank',
	'Exchange Bank of Canada',
	'First Nations Bank of Canada',
	'Home Bank',
	'General Bank of Canada',
	'HomEquity Bank',
	'Citco Bank Canada',
	'Laurentian Bank of Canada',
	'Manulife Bank of Canada',
	'National Bank of Canada',
	'VersaBank',
	'Presidents Choice Bank',
	'M & T Bank',
	'Rogers Bank',
	'Royal Bank of Canada',
	'Street Capital Bank of Canada',
	'Tangerine Bank',
	'The Toronto-Dominion Bank',
	'Vancity Community Investment Bank',
	'Wealth One Bank of Canada',
	'Zag Bank',
	'Amex Bank of Canada',
	'Bank of China (Canada)',
	'BofA Canada Bank',
	'Cidel Bank Canada',
	'Citibank Canada',
	'CTBC Bank Corp. (Canada)',
	'Habib Canadian Bank',
	'HSBC Bank Canada',
	'ICICI Bank Canada',
	'Industrial and Commercial Bank of China (Canada)',
	'J.P. Morgan Bank Canada',
	'KEB Hana Bank Canada',
	'Mega International Commercial Bank (Canada)',
	'SBI Canada Bank',
	'Shinhan Bank Canada',
	'Societe Generale (Canada Branch)',
	'UBS Bank (Canada)',
	'Walmart Canada Bank',
	'Bank of Canada',
	'Atlantic Central',
	'Caisse Populaire Group Financier Lt\u00E9e',
	'Central 1 Credit Union Vancouver',
	'Central 1 Credit Union Burlington',
	'Credit Union Central of Manitoba Limited',
	'Credit Union Central Alberta Limited',
	'Credit Union Central of Saskatchewan',
	'F\u00E9d\u00E9ration des caisses Desjardins du Quebec',
	'La F\u00E9d\u00E9ration des caisses populaires de lOntario Inc.',
	'L Alliance des caisses populaires de l Ontario, Lt\u00E9e.',
	'Golden Horseshoe Credit Union Limited',
	'Latvian Credit Union Limited',
	'ATB Financial',
	'Edward Jones',
	'The Canada Trust Company',
	'CIBC Trust Corporation',
	'Effort Trust Company',
	'Home Trust Company',
	'HSBC Mortgage Corporation (Canada)',
	'Investors Group Trust Co. Ltd.',
	'Trust La Laurentienne du Canada Inc.',
	'Manulife Trust Company',
	'Montreal Trust Company of Canada',
	'National Trust Company',
	'Peace Hills Trust Company',
	'Peoples Trust Company',
	'The Royal Trust Company',
	'Royal Trust Corporation of Canada',
	'Scotia Mortgage Corporation',
	'Sun Life Financial Trust Inc.',
	'TD Mortgage Corporation',
	'TD Pacific Mortgage Corporation',
	'Community Trust Company',
];

export const idnBankList = [
	'BANK MANDIRI, TBK.',
	'BANK RAKYAT INDONESIA, TBK.',
	'BANK NEGARA INDONESIA, TBK.',
	'BANK CENTRAL ASIA, TBK.',
	'BANK CHINA CONSTRUCTION BANK INDONESIA, TBK.',
	'BANK CHINATRUST INDONESIA',
	'BANK CIMB NIAGA, TBK',
	'BANK COMMONWEALTH',
	'BANK DANAMON INDONESIA, TBK.',
	'BANK DBS INDONESIA',
	'BANK DINAR INDONESIA',
	'BANK DKI',
	'BANK EKSPOR INDONESIA',
	'BANK FAMA INTERNATIONAL',
	'BANK GANESHA',
	'BANK HANA',
	'BANK HARDA INTERNASIONAL',
	'BANK ICBC INDONESIA',
	'BANK INA PERDANA',
	'BANK INDEX SELINDO',
	'BANK INDIA',
	'BANK J TRUST INDONESIA, TBK.',
	'BANK JASA JAKARTA',
	'BANK KESEJAHTERAAN EKONOMI',
	'BANK MANDIRI TASPEN POS',
	'BANK NUSANTARA PARAHYANGAN',
	'BANK OCBC NISP, TBK.',
	'BANK OF AMERICA MERRILL-LYNCH',
	'BANK OF CHINA LIMITED',
	'BANK OF TOKYO MITSUBISHI UFJ, LTD.',
	'BANK OKE INDONESIA',
	'BANK PANIN',
	'BANK PANIN DUBAI SYARIAH',
	'BANK PEMBANGUNAN DAERAH BANTEN',
	'BANK PERMATA, TBK.',
	'BANK QNB INDONESIA',
	'BANK RABOBANK INTERNATIONAL INDONESIA',
	'BANK MASPION INDONESIA',
	'BANK MAYAPADA INTERNATIONAL, TBK.',
	'BANK MAYBANK INDONESIA SYRIAH TBK.',
	'BANK MAYBANK INDONESIA TBK.',
	'BANK MAYORA',
	'BANK MEGA, TBK.',
	'BANK MESTIKA DHARMA',
	'BANK MITRA NIAGA',
	'BANK MIZUHO INDONESIA',
	'BANK MNC INTERNASIONAL, TBK.',
	'BANK MUAMALAT INDONESIA TBK',
	'BANK MULTI ARTA SENTOSA',
	'BANK NATIONALNOBU',
	'BANK RBS',
	'BANK RESONA PERDANIA',
	'BANK ROYAL INDONESIA',
	'BANK SAHABAT SAMPOERNA',
	'BANK SBI INDONESIA',
	'BANK SHINHAN INDONESIA',
	'BANK SINARMAS',
	'BANK SUMITOMO MITSUI INDONESIA',
	'BANK SYARIAH BUKOPIN',
	'BANK SYARIAH MEGA INDONESIA',
	'BANK TABUNGAN NEGARA, TBK.',
	'BANK TABUNGAN PENSIUNAN NASIONAL',
	'BANK UOB INDONESIA',
	'BANK VICTORIA INTERNASIONAL',
	'BANK VICTORIA SYARIAH',
	'BANK WOORI SAUDARA ,TBK.',
	'BANK YUDHA BHAKTI',
	'BPD ACEH',
	'BPD BALI',
	'BPD BENGKULU',
	'BPD DAERAH ISTIMEWA YOGYAKARTA',
	'BPD JAMBI',
	'BPD JAWA TENGAH',
	'BPD JAWA TIMUR',
	'BPD KALIMANTAN BARAT',
	'BPD KALIMANTAN SELATAN',
	'BPD KALIMANTAN TENGAH',
	'BPD KALIMANTAN TIMUR',
	'BPD LAMPUNG',
	'BPD MALUKU',
	'BPD NUSA TENGGARA BARAT',
	'BPD NUSA TENGGARA TIMUR',
	'BPD PAPUA',
	'BPD RIAU DAN KEPRI',
	'BPD SULAWESI TENGAH',
	'BPD SULAWESI TENGGARA',
	'BPD SULSELBAR',
	'BPD SULUT',
	'BPD SUMATERA BARAT',
	'BPD SUMSEL DAN BABEL',
	'BPD SUMUT',
	'CENTRATAMA NASIONAL BANK',
	'CITIBANK N.A.',
	'DEUTSCHE BANK AG',
	'HONGKONG AND SHANGHAI BANK CORPORATION',
	'JP MORGAN CHASE BANK, N.A',
	'MANDIRI E-CASH',
	'PRIMA MASTER BANK',
	'STANDARD CHARTERED BANK',
	'Bank Artha Graha',
	'Bank HSBC',
	'MUFG Bank',
	'DBS',
	'Standard Chartered Bank (SCB)',
	'Bank UOB',
	'Bank Rabobank',
	'Bank Woori Indonesia',
	'Bank Ekonomi Raharja Tbk',
	'Bank Antardaerah',
	'J Trust Bank',
	'Bank Mayapada',
	'Bank Jabar',
	'Bank BPD DIY',
	'BPD Jateng',
	'Bank Jatim',
	'Bank Aceh',
	'Bank Sumut',
	'Bank Nagari',
	'Bank BPD Riau',
	'Bank Lampung',
	'BPD Kalsel',
	'BPD Kalbar',
	'BPD Kaltim',
	'BPD Kalteng',
	'BPD Sulsel',
	'Bank Sulut',
	'Bank NTB',
	'Bank NTT',
	'Bank Bengkulu',
	'BPD Sulteng',
	'Bank Sultra',
	'Bank Swadesi',
	'Bank Muamalat',
	'Bank Mestika',
	'Bank Maspion',
	'Bank Windu Kentjana',
	'Bank QNB Indonesia(Kesawan)',
	'BTN (Bank Tabungan Negara)',
	'BANK SYARIAH INDONESIA',
	'KEB Hana Indonesia',
	'Bank Agro',
	'BPD Banten',
	'CNB',
	'Bank Harda Internasional Tbk',
	'BPR LSB',
	'BPR KS',
	'BPR EKA',
	'CTBC Indonesia(China Trust)',
	'BANGKOK BANK PUBLIC CO. LTD.',
	'BANK AGRIS',
	'BANK AMAR INDONESIA',
	'BANK ANZ INDONESIA',
	'BANK ARTA NIAGA KENCANA',
	'BANK ARTHA GRAHA INTERNATIONAL, TBK.',
	'BANK ARTOS INDONESIA',
	'BANK BCA SYARIAH',
	'BANK BISNIS INTERNASIONAL',
	'BANK BJB',
	'BANK BJB SYARIAH',
	'BANK BNI SYARIAH',
	'BANK BNP PARIBAS INDONESIA',
	'BANK BRI AGRONIAGA TBK.',
	'BANK BTPN SYARIAH, TBK.',
	'BANK BUKOPIN',
	'BANK BUMI ARTA',
	'BANK CAPITAL INDONESIA',
];

export const sgpSwiftCodeList = [
	'Deutsche Bank',
	'ANZ Bank',
	'BANGKOK BANK PUBLIC COMPANY LIMITED',
	'BANK OF AMERICA, NATIONAL ASSOCIATION',
	'Bank of China',
	'BANK OF EAST ASIA LTD',
	'BANK OF INDIA',
	'Bank of Singapore',
	'BNP Paribas',
	'CHINATRUST COMMERCIAL BANK CO LTD',
	'CIMB Bank',
	'Citibank',
	'COMMERZBANK AKTIENGESELLSCHAFT',
	'CreDIT?AGRICOLE CORPORATE AND INVESTMENT BANK',
	'DBS Bank',
	'DnB?BANK ASA',
	'Far Eastern Bank',
	'FIRST COMMERCIAL BANK',
	'HL Bank',
	'HSBC Bank',
	'INDIAN BANK',
	'INDIAN OVERSEAS BANK',
	'INTESA SANPAOLO S.p.A.',
	'KOREA EXCHANGE BANK',
	'Malayan Banking (Maybank)',
	'Mizuho Bank',
	'NATIONAL AUSTRALIA BANK LTD',
	'NORDEA BANK FINLAND PLC',
	'OCBC Bank',
	'POSB Bank',
	'PT BANK NEGARA INDONESIA (PERSERO) TBK',
	'Qatar National Bank SAQ',
	'RHB Bank',
	'SKANDINAVISKA ENSKILDA BANKEN AB',
	'SOCIETE GENERALE',
	'Standard Chartered Bank',
	'State Bank of India',
	'Sumitomo Mitsui Banking Corporation',
	'SVENSKA HANDELSBANKEN AB',
	'The Bank of Tokyo-Mitsubishi UFJ',
	'UBS AG',
	'UCO BANK',
	'UOB BANK',
];

export const mngBankList = [
	'Khan bank (Хаан банк)',
	'Golomt Bank (Голомт банк)',
	'The State bank (Төрийн банк)',
	'XacBank (Хас банк)',
	'Chinggis Khaan Bank (Чингис хаан банк)',
	'Capital Bank of Mongolia (Капитал банк)',
	'National Investment Bank (Үндэсний хөрөнгө оруулалтын банк)',
	'Arig Bank (Ариг банк)',
	'Bogd bank (Богд банк)',
	'TransBank of Mongolia (Тээвэр хөгжлийн банк)',
	'Development Bank of Mongolia (Хөгжлийн банк)',
	'MobiFinance NBFI',
	'Ard Credit NBFI',
	'Hi payment solution LLC',
	'Trade and Development Bank of Mongolia (Худалдаа хөгжлийн банк)',
];

export const phlBankList = [
	'Metrobank',
	'Al Amanah Islamic Investment Bank',
	'ANZ Bank',
	'Asia United Bank',
	'Bank Of America',
	'Bank of China',
	'BDO - BANCO DE ORO',
	'BOC - BANK OF COMMERCE',
	'BPI - BANK OF THE PHILIPPINE ISLANDS',
	'BPI FAMILY SAVINGS BANK',
	'China Bank',
	'China Bank Savings',
	'Chinatrust Bank',
	'CITIBANK',
	'CITY SAVINGS BANK',
	'DBP - DEVELOPMENT BANK OF THE PHILIPPINES',
	'Duetsche Bank',
	'East West Bank',
	'Equicom Savings Bank',
	'First Consolidate Bank',
	'HSBC',
	'HSBC Savings Bank',
	'IBK-INDUSTRIAL BANK OF KOREA',
	'ISLA Bank',
	'JP Morgan Chase Bank',
	'KEB HANA BANK',
	'Malayan Bank',
	'Maybank Philippines',
	'Mega INTL COMML Bank Co LTD (ICBC)',
	'Mizuho Bank Ltd (Fuji Bank)',
	'One Network Bank',
	'Overseas Filipino Bank',
	'PBCOM - PHILIPPINE BANK OF COMMUNICATIONS',
	'Phil Business Bank',
	'Philippine Veterans Bank',
	'Philtrust Bank',
	'Security Bank',
	'Standard Chartered Bank',
	'Sterling Bank of Asia',
	'Shinhan Bank',
	'SUN SAVINGS BANK, INC.',
	'Yuanta Saving Bank Phils Inc (FKA TongYang)',
	'Union Bank of the Philippines',
	'UCPB - UNITED COCONUT PLANTERS BANK',
	'United Overseas Bank',
	'Wealth Development Bank',
	'ABN AMRO BANK',
	'ALLBANK',
	'ALLIED BANK',
	'ALLIED SAVINGS BANK',
	'AMA BANK',
	'AMANAH ISLAMIC BANK',
	'AUSTRALIA AND NEW ZEALAND BANK',
	'BANGKO MABUHAY',
	'BANK OF TOKYO',
	'BANK ONE SAVINGS',
	'Cebuana Lhuillier Rural Bank, Inc',
	'CTBC Bank (Philippines) Corporation',
	'CIMB BANK PHILS, INC',
	'CITIBANK',
	'CITY SAVINGS BANK',
	'DUNGGANON BANK, INC',
	'FAR EASTERN BANK',
	'FILIPINO SAVERS BANK',
	'FIRST ALLIED BANK',
	'FIRST MACRO BANK',
	'GUAGUA SAVERS BANK',
	'ING BANK N.V.',
	'LAND BANK OF THE PHILIPPINES',
	'LUZON DEVELOPMENT BANK',
	'MUFG BANK LTD (Bank Of Tokyo)',
	'PEN BANK',
	'PHILIPPINE BUSINESS BANK',
	'PSBANK - PHILIPPINE SAVINGS BANK',
	'PNB - PHILIPPINE NATIONAL BANK',
	'POSTAL BANK',
	'PREMIER DEVELOPMENT BANK',
	'PRODUCERS SAVINGS BANK CORP',
	'QUEZON CAPITAL RURAL BANK',
	'RCBC - RIZAL COMMERCIAL BANKING CORP',
	'RCBC Savings Bank',
	'ROBINSON SAVINGS BANK',
	'SECURITY SAVINGS BANK',
	'STERLING BANK',
	'SUMITOMO MITSUI BANKING CORP',
	'SUMMIT BANK',
	'TONG YANG SAVINGS BANK INC',
	'UCPB - UNITED COCONUT PLANTERS BANK',
	'UCPB SAVINGS',
	'UNION BANK OF THE PHILIPPINES',
	'UNIVERSITY SAVINGS BANK',
	'VETERANS BANK',
];

export const rusCashPickupList = ['CONTACT', 'ZOLOTAYA KORONA', 'NONE'];

export const chlBakList = [
	'BANCO INTERNACIONAL [BICHCLRM]',
	'Banco Bice [028]',
	'Banco Consorcio [055]',
	'Banco Credito e Inversiones (BCI) [016]',
	'Banco Falabella [051]',
	'Banco HSBC [031]',
	'Banco Internacional [009]',
	'Banco Itau Chile [039]',
	'Banco Ripley [053]',
	'Banco Santander [037]',
	'Banco Security [049]',
	'Banco de Chile [001]',
	'Banco del Estado de Chile [012]',
	'HSBC BANK (CHILE) [BLICCLRM]',
	'SCOTIABANK CHILE [BKSACLRMXXX]',
	'Scotiabank Chile [014]',
];
