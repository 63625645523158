import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import ScrollToTop from 'components/_common/ScrollToTop';
import LoginAuto from 'components/_common/LoginAuto';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import NotFound from 'pages/NotFound';

import MedicalPassportValidate from 'pages/MedicalPassportValidate';
import MedicalRecipt from 'pages/MedicalReceipt';
import MedicalHome from 'pages/MedicalHome';
import MedicalRefundInfo from 'pages/MedicalRefundInfo';
import MedicalDeparture from 'pages/MedicalDeparture';
import MedicalCustomerRefund from 'pages/MedicalCustomerRefund';
import MedicalRefundResult from 'pages/MedicalRefundResult';
import MedicalVerifiactionRedirect from 'pages/MedicalVerificationRedirect';
import { IGlobalState } from 'store';
import { Iso639_1Code } from 'utils/nations';
import Redirect from 'components/_common/Redirect';
import MedicalLeaflet from 'pages/MedicalLeaflet';
import CustomModal from 'components/_common/CustomModal';
import MedicalFaq from 'pages/MedicalFaq';
import CalendarModal from 'components/MedicalHome/CalendarModal';
import PassportRedirect from 'pages/MedicalRedirect';
import CashPickupGuideModal from 'components/MedicalCustomerRefund/CashPickupGuide.modal';
import BankInquiryGuideModal from 'components/MedicalRefundResult/BankInquiryGuide.modal';

function RouterContainer() {
	const { nationality, homeModalInfo } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const selectElement = document.querySelector('.goog-te-combo');
	const dispatch = useAppDispatch();

	function changeGoogleTranslateLanguage(nationality: string | null) {
		const nation = Iso639_1Code(nationality);
		if (!nation) return;
		//@ts-ignore
		selectElement.value = nation;
		//@ts-ignore
		selectElement.dispatchEvent(new Event('change'));
	}

	useEffect(() => {
		selectElement && changeGoogleTranslateLanguage(nationality);
	}, [selectElement, nationality]);

	const onCloseModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};

	return (
		<Container>
			<Translation id="google_translate_element"></Translation>
			<Router>
				<Redirect />
				<LoginAuto />
				<ScrollToTop />
				<Switch>
					<Route
						path="/email-verification/:id/:verifiedEmail"
						component={MedicalVerifiactionRedirect}
					/>
					<Route path="/" component={MedicalPassportValidate} exact />
					<Route path="/home" component={MedicalHome} />
					<Route path="/receipt" component={MedicalRecipt} />
					<Route path="/departure" component={MedicalDeparture} />
					<Route path="/refund-info" component={MedicalRefundInfo} />
					<Route path="/refund" component={MedicalCustomerRefund} />
					<Route path="/faq" component={MedicalFaq} />
					<Route path="/refund-result" component={MedicalRefundResult} />
					<Route path="/refund-info-leaflet" component={MedicalLeaflet} />
					<Route
						path="/redirect/:passportNumber"
						component={PassportRedirect}
					/>
					<Route>
						<NotFound />
					</Route>
				</Switch>
				{homeModalInfo?.type === 'CUSTOM' && homeModalInfo?.isActive && (
					<CustomModal onClose={onCloseModal} {...homeModalInfo?.data} />
				)}
				{homeModalInfo?.type === 'CALENDAR' && homeModalInfo?.isActive && (
					<CalendarModal onClose={onCloseModal} />
				)}
				{nationality === 'TWN' &&
					homeModalInfo?.type === 'CASH_PICKUP_GUIDE' && (
						<CashPickupGuideModal onClose={onCloseModal} />
					)}
				{nationality === 'CHN' &&
					homeModalInfo?.type === 'BANK_INQUIRY_GUIDE' && (
						<BankInquiryGuideModal
							title={
								<>
									<InfoTitle>因为海外汇款，银行可能会来咨询。</InfoTitle>
									<InfoTitle>那种时候请这样回答。</InfoTitle>
								</>
							}
							questionTitle="银行"
							questionText="与Successmode是什么关系?"
							answerTitle="您"
							answerText='Successmode作为退税窗口事业者代理Tax Refund。(代表人名 "Ju Byeong-Cheon" 可汇款。)'
							btnTitle="确认"
						/>
					)}
				{nationality === 'JPN' &&
					homeModalInfo?.type === 'BANK_INQUIRY_GUIDE' && (
						<BankInquiryGuideModal
							title={
								<>
									<InfoTitle>
										海外送金のために銀行から問い合わせが来ることもあります。
									</InfoTitle>
									<InfoTitle>そういう時はこう答えてください。</InfoTitle>
								</>
							}
							questionTitle="ぎんこう"
							questionText="Successmodeとはどのような関係ですか?"
							answerTitle="あなた"
							answerText="Successmodeは還付窓口運営事業者として 「TAX REFUND」を代行します。 (代表者 'Ju Byeong-Cheon' に入金されることがあります。)"
							btnTitle="確認"
						/>
					)}
			</Router>
		</Container>
	);
}

export default RouterContainer;

const Container = styled.div`
	position: static;
`;

const Translation = styled.div`
	position: fixed;
	z-index: 200;
	@media ${(props) => props.theme.desktop} {
		right: 42%;
		top: 2px;
	}
	@media ${(props) => props.theme.mobile} {
		top: 0px;
		right: 20px;
	}
`;

const InfoTitle = styled.span`
	display: block;
	font-weight: 600;
	line-height: 150%;
`;
