import { checkKorDeparture, medicalPassportValidate } from 'api';
import LoadingView from 'components/_common/LoadingView';
import { useAppDispatch } from 'hooks/useReduxHooks';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

type ParamProps = {
	passportNumber: string;
};

function PassportRedirect() {
	const { passportNumber } = useParams() as ParamProps;
	const dispatch = useAppDispatch();
	const history = useHistory();
	const location = useLocation();
	const queryString = new URLSearchParams(location.search);
	const targetPath = queryString.get('path');

	const { mutate: getKorDeparture } = useMutation(
		() => checkKorDeparture(passportNumber),
		{
			onSuccess: ({ isDepartureCheckRequired }) => {
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						isDepartureCheckRequired,
					},
				});
			},
		}
	);

	const { mutate: varifiedPassport } = useMutation(
		(payload: string) => medicalPassportValidate(payload),
		{
			retry: false,
			onSuccess: ({ departureDate, customerNation, ...rest }) => {
				if (customerNation === 'KOR') getKorDeparture();
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						passportNumber,
						nationality: customerNation,
						...rest,
					},
				});
				departureDate &&
					localStorage.setItem('medicalDepartureDate', departureDate);
				customerNation &&
					localStorage.setItem('medicalCustomerNation', customerNation);
				localStorage.setItem('login', passportNumber!);
				history.push(`/${targetPath}`);
			},
		}
	);

	useEffect(() => {
		varifiedPassport(passportNumber!);
	}, [passportNumber]);

	return (
		<LoadingWrapper>
			<LoadingView />
		</LoadingWrapper>
	);
}

export default PassportRedirect;

const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
