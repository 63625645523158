import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Refund from 'assets/home/refund.png';
import FlexWrap from 'components/_common/FlexWrap';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { comma } from 'utils/format';

function RefundAmount() {
  const { refundAmount, nationality } = useAppSelector(
    (state) => state
  ) as IGlobalState;
  const history = useHistory();

  const onClick = () => {
    history.push(routes.medicalReceipt);
  };

  return (
    <Container onClick={onClick}>
      <RefundIcon src={Refund} />
      <FlexWrap dir='column' alignItems='flex-start'>
        <RefundLabel>
          {nationality === 'KOR' ? '내가 환급 받을 금액' : 'My refunds'}
        </RefundLabel>
        <FlexWrap alignItems='center' gap='8px'>
          <Currency>KRW</Currency>
          <Amount className='notranslate'>{comma(refundAmount)}</Amount>
        </FlexWrap>
      </FlexWrap>
    </Container>
  );
}

const Container = styled.button`
  display: flex;
  align-items: center;
  background-color: #5089f8;
  padding: 16px;
  border-radius: 10px;
  gap: 20px;
  color: #fff;
`;
const RefundIcon = styled.img`
  width: 68px;
  height: 68px;
`;
const CheckedIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const WhiteText = styled.span`
  color: #fff;
  line-height: 150%;
`;
const RefundLabel = styled(WhiteText)`
  font-size: 14px;
`;
const BoldText = styled(WhiteText)`
  font-weight: 700;
`;
const Currency = styled(BoldText)`
  font-size: 18px;
`;
const Amount = styled(BoldText)`
  font-size: 24px;
`;
export default RefundAmount;
