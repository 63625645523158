import React from 'react';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled, { keyframes } from 'styled-components';
import FlexWrap from 'components/_common/FlexWrap';
import { getMedicalStatusData } from 'constant';
import { useAppSelector } from 'hooks/useReduxHooks';
import ProgressBar from './ProgressBar';
import { IGlobalState } from 'store';
import CashPickupGuideButton from 'components/MedicalCustomerRefund/CashPickupGuideButton';
import BankInquiryGuideButton from 'components/MedicalRefundResult/BankInquiryGuideButton';

function CurrentStatus() {
	const { isRefundInfoRegistered, paymentStatus, nationality } = useAppSelector(
		(state) => state
	) as IGlobalState;

	const { title, description, image } = getMedicalStatusData({
		paymentStatus,
		isRefundInfoRegistered,
		nationality,
	});
	const history = useHistory();

	const onClickActionBtn = (e: any) => {
		if (
			// @ts-ignore
			e.target.id !== 'guideButton'
		) {
			isRefundInfoRegistered
				? history.push(routes.medicalRefundInfo)
				: history.push(routes.medicalCustomerRefund);
		} else {
			e.preventDefault();
		}
	};

	return (
		<Container
			onClick={onClickActionBtn}
			isRefundInfoRegistered={isRefundInfoRegistered}
		>
			<ProgressBar />
			<FlexWrap
				gap="9px"
				justifyContent="space-between"
				style={{ width: '100%', marginTop: 24 }}
			>
				<FlexWrap dir="column">
					<Title>{title}</Title>
					<Description>{description}</Description>
					{nationality === 'TWN' && <CashPickupGuideButton />}
					{(nationality === 'JPN' || nationality === 'CHN') && (
						<BankInquiryGuideButton />
					)}
				</FlexWrap>
				<StatusImg src={image} />
			</FlexWrap>
		</Container>
	);
}
const blinkAnimation = keyframes`
0% {opacity:1;}
50% {opacity:0.6;}
100% {opacity:1;}
`;
const Container = styled.div<{ isRefundInfoRegistered: boolean }>`
	margin: 24px 0;
	padding: 20px;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0px 0px 20px 0px rgba(174, 191, 204, 0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	animation: ${blinkAnimation} 1500ms infinite;
	animation-play-state: ${(props) =>
		props.isRefundInfoRegistered ? 'paused' : 'running'};
`;

const Title = styled.h3`
	font-size: 18px;
	line-height: 150%;
	font-weight: 700;
	color: #1856cd;
	white-space: pre-wrap;
`;
const Description = styled.p`
	white-space: pre-wrap;
	font-size: 13px;
	line-height: 150%;
	color: #3a3b3e;
	margin: 8px 0 28px;
`;
const StatusImg = styled.img`
	width: 50px;
	height: 50px;
`;
export default CurrentStatus;
