import React, { useEffect, useState } from 'react';
import ScrollImg from 'assets/common/scroll_up.png';
import styled, { css } from 'styled-components';

function ScrollToTopBtn() {
  const [isShowBtn, setIsShowBtn] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const showScrollBtn = () => {
      if (window.scrollY > 200) {
        setIsShowBtn(true);
      } else {
        setIsShowBtn(false);
      }
    };
    window.addEventListener('scroll', showScrollBtn);
    return () => {
      window.removeEventListener('scroll', showScrollBtn);
    };
  });

  return (
    <>
      <ScrollBtn isActive={isShowBtn} onClick={scrollToTop}>
        <ScrollUpIcon src={ScrollImg} onClick={scrollToTop} />
      </ScrollBtn>
    </>
  );
}

const ScrollBtn = styled.button<{ isActive: boolean }>`
  transition: all 300ms ease-in;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #5f6165;
  position: fixed;
  bottom: 40px;
  right: 20px;
  ${(props) =>
    !props.isActive &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;
const ScrollUpIcon = styled.img`
  width: 16px;
  height: 16px;
`;
export default ScrollToTopBtn;
