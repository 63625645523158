import ModalFrame from 'components/_common/ModalFrame';
import React from 'react';
import FaqButton from './FaqButton';
import { IGlobalState } from 'store';
import { useAppSelector } from 'hooks/useReduxHooks';
import ICON_MAIL from 'assets/common/icon_mail.png';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useMutation } from 'react-query';
import { updateNewAnswer } from 'api';

function FaqAnswerModal() {
	const { nationality, passportNumber } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const history = useHistory();

	const { mutate: readNewAnswer } = useMutation(
		(payload: string) => updateNewAnswer(payload),
		{
			retry: false,
		}
	);

	const goToFaq = () => {
		readNewAnswer(passportNumber!);
		history.push(routes.medicalFaq);
	};

	return (
		<ModalFrame
			title={
				<>
					<IconMail src={ICON_MAIL} alt="Alert" />
					<strong>
						{nationality === 'KOR'
							? '문의에 대한 답변이 도착했습니다.'
							: 'Your Inquiry has been answered'}
					</strong>
				</>
			}
			content={
				<Text>
					{nationality === 'KOR'
						? '나의 문의로 이동하여 답변을 확인해주세요.'
						: 'Please check the answer by pressing the button below.'}
				</Text>
			}
			buttons={
				<>
					<FaqButton
						status="active"
						padding="10px 0"
						width="100%"
						fontSize={16}
						onClick={goToFaq}
					>
						{nationality === 'KOR' ? '나의 문의로 이동' : 'Open My Inquiry'}
					</FaqButton>
				</>
			}
		/>
	);
}

export default FaqAnswerModal;

const IconMail = styled.img`
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 auto 8px;
`;

const Text = styled.p`
	font-size: 14px;
	text-align: center;
	color: #5f6165;
`;
