import React from 'react';
import styled, { css } from 'styled-components';

import CheckBox from 'assets/receipt/checkbox.png';
import ActiveCheckBox from 'assets/receipt/checkbox_active.png';
import DisabledCheckBox from 'assets/receipt/checkbox_disabled.png';
import FlexWrap from 'components/_common/FlexWrap';

interface IProps {
  label: string;
  highlightLabel: string;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTermsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isTopLine?: boolean;
  disabled?: boolean;
  paddingTop?: string;
  paddinBottom?: string;
  underLine?: boolean;
}
function CheckboxWithLabel({
  label,
  highlightLabel,
  isActive,
  setIsActive,
  isTopLine = false,
  disabled = false,
  paddingTop,
  paddinBottom,
  underLine,
  setIsTermsModal = () => {
    return;
  },
}: IProps) {
  const onClick = () => {
    setIsActive(!isActive);
  };
  return (
    <CheckboxWrap
      alignItems='flex-start'
      isTopLine={isTopLine}
      paddingTop={paddingTop}
      paddinBottom={paddinBottom}
      underLine={underLine}
    >
      <CheckBtn onClick={onClick}>
        <CheckIcon
          src={
            disabled ? DisabledCheckBox : isActive ? ActiveCheckBox : CheckBox
          }
        />
      </CheckBtn>
      <GuideText underLine={underLine} onClick={() => setIsTermsModal(true)}>
        {label}
        <Highlight>{highlightLabel}</Highlight>
      </GuideText>
    </CheckboxWrap>
  );
}

const CheckboxWrap = styled(FlexWrap)<{
  isTopLine: boolean;
  paddingTop?: string;
  paddinBottom?: string;
  underLine?: boolean;
}>`
  padding-top: ${(props) => props.paddingTop || 0};
  ${(props) =>
    props.isTopLine &&
    css`
      margin-top: 40px;
      border-top: 1px solid #cbccce;
      padding-top: 30px;
    `}
  margin-top: ${(props) => props.underLine && 0};
  @media ${(props) => props.theme.mobile} {
    padding-bottom: ${(props) => props.paddinBottom || 0};
  }
`;
const CheckBtn = styled.button`
  padding: 4px;
  margin-right: 12px;
`;
const CheckIcon = styled.img`
  height: 24px;
  width: 24px;
`;
const GuideText = styled.small<{ underLine?: boolean }>`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  text-align: start;
  margin-top: 4px;
  color: #3a3b3e;
  ${(props) =>
    props.underLine &&
    css`
      text-decoration: underline;
      text-decoration-color: #246cf6;
      cursor: pointer;
    `}
`;
const Highlight = styled.small`
  color: #246cf6;
  text-decoration: solid;
`;
export default CheckboxWithLabel;
