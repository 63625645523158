import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { debounce } from 'lodash-es';

import { updateMedicalDepartureInfo } from 'api';
import CalendarImg from 'assets/uploadImage/calendar.png';
import CalendarModal from 'components/MedicalPassportValidate/CalendarModal';
import Input from 'components/_common/Input';
import TextButton from 'components/_common/TextButton';
import { useAppSelector } from 'hooks/useReduxHooks';
import { ISendLeafletMailPayload } from 'types/medical';

function UserInputForm() {
  const { passportNumber, nationality } = useAppSelector((state) => state);
  const [departureDate, setDepartureDate] = useState<any>('');
  const [isActveCalendar, setIsActiveCalendar] = useState(false);
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
    handleSubmit,
  } = useFormContext();

  const { email } = watch();
  const [activeBtnRef, setActiveBtnRef] = useState(true);
  const history = useHistory();
  const mutation = useMutation<string, AxiosError, ISendLeafletMailPayload>(
    (payload) => updateMedicalDepartureInfo(payload),
    {
      onError: () => {
        alert(
          'Failed to enter information.\nPlease send e-mail to\n"ktaxpay@gmail.com"'
        );
        setActiveBtnRef(true);
      },
      onSuccess: () => {
        history.push({
          pathname: routes.medicalHome,
        });
      },
    }
  );
  const onSubmit = debounce(() => {
    setActiveBtnRef(false);
    const emailRegex =
      /[a-zA-Z0-9]{1,}@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!emailRegex.test(email)) {
      setError('email', {
        message:
          nationality === 'KOR'
            ? '이메일 형식이 아닙니다.'
            : 'Please enter a valid email address.',
      });
      setActiveBtnRef(true);
      return;
    }
    const payload = {
      passportNumber,
      departureDate: format(departureDate, 'yyyy-MM-dd'),
      email,
    };
    mutation.mutate(payload);
  }, 500);

  const onClick = (e: any) => {
    e.preventDefault();
    setIsActiveCalendar(!isActveCalendar);
  };
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper onClick={onClick}>
          <Input
            onMouseDown={onClick}
            value={departureDate && format(new Date(departureDate), 'yy-MM-dd')}
            label={nationality === 'KOR' ? '출국예정일' : 'Departure date'}
            isRequired
            placeholder='YY-MM-DD'
            width='335px'
            style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}
          />
          <CalendarIcon src={CalendarImg} />
        </InputWrapper>
        <Input
          {...register('email', {
            onChange: (e) => setValue('email', e.target.value),
          })}
          label='E-Mail'
          isRequired
          placeholder='ex) abc@email.com'
          width='335px'
          margin={[0, 0, 20]}
          error={errors.email}
        />
        <TextButton
          isActive={email && departureDate && activeBtnRef}
          title='CHECK'
          bottomFixed
          onClick={onSubmit}
          disabled={!(email && departureDate && activeBtnRef)}
        />
      </Form>
      {isActveCalendar && (
        <CalendarModal
          onChange={setDepartureDate}
          departureDate={departureDate}
          //@ts-ignore
          onClose={onClick}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;
const InputWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
`;
const CalendarIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: 55px;
  cursor: pointer;
`;
export default UserInputForm;
