import styled from 'styled-components';
import ICON_QUESTION from 'assets/common/icon_question_gray.png';
import { useAppDispatch } from 'hooks/useReduxHooks';

function CashPickupGuideButton() {
	const dispatch = useAppDispatch();
	const openModal = (e: any) => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: {
				type: 'CASH_PICKUP_GUIDE',
				isActive: true,
			},
		});
	};

	return (
		<CashPickupGuide id="guideButton" type="button" onClick={openModal}>
			<QuestionIcon id="guideButton" src={ICON_QUESTION} alt="" />
			What is Cash Pickup?
		</CashPickupGuide>
	);
}

export default CashPickupGuideButton;

const CashPickupGuide = styled.button`
	display: flex;
	align-items: center;
	gap: 8px;
	width: fit-content;
	color: #5f6165;
	text-decoration: underline;
	font-size: 14px;
`;

const QuestionIcon = styled.img`
	width: 18px;
	height: 18px;
`;
