import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import questionIcon from 'assets/home/question.png';
import Icon from 'components/_common/Icon';

function ProgressBar() {
  const [isToolTip, setIsToolTip] = useState(false);

  const {
    isRefundInfoRegistered,
    paymentStatus,
    nationality,
    maximumTransferDate,
  } = useAppSelector((state) => state) as IGlobalState;

  const isShowDate =
    Boolean(maximumTransferDate) && paymentStatus !== 'PAYMENT_COMPLETE';

  const currentStep =
    paymentStatus === 'PAYMENT_COMPLETE' && isRefundInfoRegistered
      ? 3
      : isRefundInfoRegistered
      ? 2
      : 1;

  const getProgressPercent = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return 27;
      case 2:
        return 77;
      case 3:
        return 100;
      default:
        return 0;
    }
  };
  return (
    <Container>
      <BarWrapper>
        <Bar progress={getProgressPercent(currentStep)} />
        <Dot left='24%' status={currentStep === 1 ? 'ACTIVE' : 'COMPLETE'} />
        <Dot
          left='74%'
          status={
            currentStep === 2
              ? 'ACTIVE'
              : currentStep === 3
              ? 'COMPLETE'
              : 'NOT_YET'
          }
        />
      </BarWrapper>
      <FlexWrap justifyContent='space-around'>
        <ProgressLabel active={currentStep === 1}>
          {nationality === 'KOR' ? '송금 정보 입력' : `Enter\ninformation`}
        </ProgressLabel>
        <ProgressLabel
          active={currentStep === 2}
          isMaximumTransferDate={isShowDate}
          onMouseOver={() => {
            if (!isShowDate) return;
            setIsToolTip(true);
          }}
          onMouseOut={() => setIsToolTip(false)}
          onClick={(e) => {
            if (!isShowDate) return;
            e.stopPropagation();
            setIsToolTip(!isToolTip);
          }}
          onBlur={() => setIsToolTip(false)}
        >
          {isShowDate
            ? '~ ' + maximumTransferDate
            : nationality === 'KOR' && paymentStatus === 'PAYMENT_WAIT'
            ? '환급액 수취'
            : nationality === 'KOR' && paymentStatus === 'PAYMENT_COMPLETE'
            ? '환급액\n수취완료'
            : paymentStatus === 'PAYMENT_WAIT'
            ? 'Receive\nrefund'
            : `Receive\nCompleted`}
          {isShowDate && (
            <Icon imgUrl={questionIcon} width='10px' height='10px' />
          )}
        </ProgressLabel>
      </FlexWrap>
      {isToolTip && (
        <ToolTip>
          {nationality === 'KOR'
            ? '늦어도 이 날까지는 송금이 될 거예요\n조금만 기다려주세요'
            : 'Refund will be completed by indicated date\nat the latest'}
        </ToolTip>
      )}
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  position: relative;
`;
const BarWrapper = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  border-radius: 100px;
  background-color: #e5e6e8;
`;
const Bar = styled.div<{ progress: number }>`
  height: 12px;
  background-color: #246cf6;
  width: ${(props) => props.progress}%;
  border-radius: 100px;
  transition: all ease-in 500ms;
`;
const Dot = styled.div<{
  status: 'NOT_YET' | 'ACTIVE' | 'COMPLETE';
  left: string;
}>`
  width: 12px;
  height: 12px;
  top: 0;
  border-radius: 50%;
  background-color: ${(props) =>
    props.status === 'NOT_YET'
      ? '#CBCCCE'
      : props.status === 'ACTIVE'
      ? '#A7C4FB'
      : '#5089F8'};
  position: absolute;
  left: ${(props) => props.left};
  transition: all ease-in 500ms;
`;
const ProgressLabel = styled.div<{
  active: boolean;
  isMaximumTransferDate?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 150%;
  margin-top: 5px;
  color: #3a3b3e;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${(props) => props.active && props.theme.ktpPrimary10};
  padding: 3px 5px;
  border-radius: 6px;
  ${(props) =>
    props.isMaximumTransferDate &&
    css`
      max-width: 150px;
      height: 22px;
      white-space: nowrap;
    `}
`;
const ToolTip = styled.p`
  position: absolute;
  max-width: 88%;
  font-size: 12px;
  line-height: 150%;
  bottom: -52px;
  right: 0;
  padding: 8px;
  color: #fff;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  white-space: pre-wrap;
`;

export default ProgressBar;
