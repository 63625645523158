import CryptoJS from 'crypto-js';

export const bankCardOwnershipInquiry = async (bankCard: string) => {
  const secretType = 'MD5';
  const api_url = 'https://qayz.api.storeapi.net/pyi/84/202';
  const appid = process.env.REACT_APP_WAPI_APP_ID;
  let secret = process.env.REACT_APP_WAPI_SECRET;

  const data: any = {
    appid: '1',
    bank_card: bankCard,
    format: 'json',
    time: '1545829466',
  };

  data['appid'] = appid;
  data['time'] = Math.round(new Date().getTime() / 1000);

  if (secretType === 'MD5') {
    const sortedData = Object.keys(data)
      .sort()
      .reduce((acc: any, key) => {
        if (data[key]) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

    let md5String = '';
    for (const key in sortedData) {
      if (Object.hasOwnProperty.call(sortedData, key)) {
        md5String += key + sortedData[key];
      }
    }
    secret = CryptoJS.MD5(md5String + secret).toString();
  }
  data['sign'] = secret;

  const sendUrl = `${api_url}?${new URLSearchParams(data).toString()}`;
  let result = '';
  await fetch(sendUrl)
    .then((response) => response.json())
    .then((data) => {
      result = data;
      sendLog(data);
    })
    .catch((error) => {
      console.error(error);
    });
  return result;
};

const sendLog = async (data: any) => {
  const sendLogURL =
    process.env.NODE_ENV === 'development'
      ? 'http://3.38.108.25:50944/admin/receipt/log'
      : 'https://medical.successmode.co.kr/admin/receipt/log';
  const payload = {
    codeid: data?.codeid,
    message: data?.message,
    retdata: {
      abbreviation: data?.retdata?.abbreviation,
      adcode: data?.retdata?.adcode,
      bankcard: data?.retdata?.bankcard,
      bankname: data?.retdata?.bankname,
      cardtype: data?.retdata?.cardtype,
      city: data?.retdata?.city,
      keft: data?.retdata?.keft,
      nature: data?.retdata?.nature,
    },
  };

  await fetch(sendLogURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'successmode806',
    },
    body: JSON.stringify(payload),
  });
};
