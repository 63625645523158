import React, { InputHTMLAttributes } from 'react';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { CalculateBox } from 'utils/common';
import ErrorAlert from 'assets/common/alert.png';
import { IComponentProps } from 'model/types';
import FlexWrap from './FlexWrap';
import { IErrorState } from 'pages/MedicalPassportValidate';

interface IStyleProps {
	margin: string;
	padding: string;
}

type InputProps = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>;

interface IProps
	extends InputHTMLAttributes<HTMLInputElement>,
		IComponentProps {
	error?: FieldError | IErrorState | FieldErrors<FieldValues> | undefined;
	label?: string;
	isRequired?: boolean;
	isActiveOutline?: boolean;
	disabled?: boolean;
	isFile?: boolean;
	isAbsolute?: boolean;
	onClick?: () => void;
	fileInputRef?: any;
	height?: string;
	bottom?: string;
	fileBtnText?: string;
}

const Input = React.forwardRef<HTMLInputElement, IProps & InputProps>(
	(
		{
			error,
			label,
			isRequired = false,
			margin = [0],
			padding = [0],
			isActiveOutline = true,
			disabled,
			isFile,
			onClick,
			fileInputRef,
			isAbsolute,
			height,
			bottom,
			...inputAttributes
		}: IProps,
		ref
	): JSX.Element => {
		return (
			<Container margin={CalculateBox(margin)} padding={CalculateBox(padding)}>
				<InputWrapper>
					{isFile ? (
						<>
							{Boolean(label) && (
								<LabelWrapper>
									<Label>{label}</Label>
									{isRequired && <RequiredDot>*</RequiredDot>}
								</LabelWrapper>
							)}
							<ReceiptUploadBtn onClick={onClick}>
								Uploade Image
							</ReceiptUploadBtn>
							<StyledInput
								ref={fileInputRef}
								isError={Boolean(error)}
								isActiveOutline={isActiveOutline}
								style={{ display: 'none' }}
								height={height}
								{...inputAttributes}
							/>
						</>
					) : (
						<>
							{Boolean(label) && (
								<LabelWrapper>
									<Label>{label}</Label>
									{isRequired && <RequiredDot>*</RequiredDot>}
								</LabelWrapper>
							)}
							<StyledInput
								ref={ref}
								isError={Boolean(error)}
								isActiveOutline={isActiveOutline}
								disabled={disabled}
								height={height}
								{...inputAttributes}
							/>
						</>
					)}
				</InputWrapper>
				{error && (
					<ErrorWrapper isAbsolute={isAbsolute} bottom={bottom}>
						<ErrorIcon src={ErrorAlert} alt="에러" />
						<Error>{error.message}</Error>
					</ErrorWrapper>
				)}
			</Container>
		);
	}
);

const Container = styled.div<IStyleProps>`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin: ${(props) => props.margin};
	padding: ${(props) => props.padding};
`;

const StyledInput = styled.input<{
	isError: boolean;
	isActiveOutline: boolean;
	height?: string;
}>`
	display: block;
	width: 100%;
	border: 2px solid
		${(props) =>
			props.isError && props.isActiveOutline ? '#f13e4b' : '#c4c4c4'};
	border-radius: 4px;
	padding: 12px 16px;
	font-size: 16px;
	line-height: 24px;
	height: ${(props) => props.height};
	::placeholder {
		color: #cbccce;
		font-weight: 300;
	}
	outline-color: ${(props) => props.theme.primaryColor};
	background-color: #ffffff;
	border: 1px solid #cbccce;
	:disabled {
		background-color: #f5f6f7;
	}
	::placeholder {
		font-size: 14px;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 14px 16px;
		font-size: 16px;
		line-height: 22px;
	}
`;

const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 8px;
`;

const InputWrapper = styled(FlexWrap)`
	width: 100%;
	flex-direction: column;
	@media ${(props) => props.theme.desktop} {
		align-items: flex-start;
	}
`;

const Label = styled.label`
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	@media ${(props) => props.theme.mobile} {
		font-size: 16px;
		line-height: 24px;
	}
`;

const RequiredDot = styled.div`
	color: #f13e4b;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	margin-left: 4px;
	padding-bottom: 6px;
`;

const ErrorWrapper = styled.div<{ isAbsolute?: boolean; bottom?: string }>`
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	${(props) =>
		props.isAbsolute &&
		css`
			position: absolute;
			bottom: ${props.bottom || '0'};
		`}
`;

const ErrorIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`;

const Error = styled.p`
	color: #f13e4b;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-align: left;
`;
Input.displayName = 'Input';
const ReceiptUploadBtn = styled.button`
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 11px 110px;
	width: 100%;
	border-radius: 4px;
	background-color: #0054a2;
	color: #ffffff;
	@media ${(props) => props.theme.mobile} {
		padding: 11px 0px;
	}
`;

export default Input;
