import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import UserInputForm from 'components/MedicalRefundInfo/UserInputForm';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import FlexWrap from 'components/_common/FlexWrap';
import { useAppSelector } from 'hooks/useReduxHooks';
import Typography from 'components/_common/Typography';

function MedicalDeparture() {
  const { passportNumber } = useAppSelector((state) => state);
  const formMethod = useForm({
    defaultValues: {
      name: '',
      nation: '',
      email: '',
    },
  });
  return (
    <ReceiptLayout>
      <Container dir='column' gap='20px'>
        <Header>
          <Typography
            size='1.375rem'
            fontWeight='400'
            lineHeight='1.875rem'
            color='#3A3B3E'
          >
            Welcome! <Typography color='#246CF6'>{passportNumber}</Typography>
            <Typography fontWeight='500'>
              Please enter the departure date
              <br />& Refund information.
            </Typography>
          </Typography>
          <Typography
            size='1rem'
            fontWeight='400'
            margin='1.25rem 0 0 0'
            color='#3A3B3E'
          >
            If your email address is incorrect to the email,
            <br />
            you will not be able to get a tax refund.​
          </Typography>
        </Header>
        <DivdeLine />
        <FormProvider {...formMethod}>
          <UserInputForm />
        </FormProvider>
      </Container>
    </ReceiptLayout>
  );
}

const Container = styled(FlexWrap)`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 60px;
`;
const Header = styled.div`
  white-space: pre-wrap;
`;
const DivdeLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbccce;
`;
export default MedicalDeparture;
