import FaqButton from './FaqButton';
import ICON_BADGE_BLUE from '../../assets/common/icon_badge_blue.png';
import ICON_BADGE_WHITE from '../../assets/common/icon_badge_white.png';
import ICON_COMMENTS_BLUE from '../../assets/common/icon_comments_blue.png';
import ICON_COMMENTS_WHITE from '../../assets/common/icon_comments_white.png';
import styled from 'styled-components';
import { IGlobalState } from 'store';
import { useAppSelector } from 'hooks/useReduxHooks';

type Props = {
	value: number;
	onChange: (index: number) => void;
};

function FaqTabs({ value, onChange }: Props) {
	const { nationality } = useAppSelector((state) => state) as IGlobalState;

	return (
		<TabWrapper>
			<FaqButton
				status={value === 0 ? 'active' : 'default'}
				width='100%'
				padding='6px 0'
				onClick={() => onChange(0)}
			>
				<IconStyle
					src={value === 0 ? ICON_BADGE_WHITE : ICON_BADGE_BLUE}
					alt=''
				/>
				{nationality === 'KOR' ? '자주 묻는 질문' : 'FAQ'}
			</FaqButton>
			<FaqButton
				status={value === 1 ? 'active' : 'default'}
				width='100%'
				padding='6px 0'
				onClick={() => onChange(1)}
			>
				<IconStyle
					src={value === 1 ? ICON_COMMENTS_WHITE : ICON_COMMENTS_BLUE}
					alt=''
				/>
				{nationality === 'KOR' ? '나의 문의' : 'My Inquiry'}
			</FaqButton>
		</TabWrapper>
	);
}

export default FaqTabs;

const TabWrapper = styled.div`
	position: fixed;
	top: 80px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 12px;
	width: 375px;
	padding: 28px 20px 18px;
	background-color: #fff;
	z-index: 10;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		left: 0;
		transform: translateX(0);
	}
`;

const IconStyle = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 6px;
`;
