import FaqButton from 'components/_common/FaqButton';
import ModalFrame from 'components/_common/ModalFrame';
import styled from 'styled-components';
import ICON_BADGE from 'assets/common/icon_badge.png';
import { useAppDispatch } from 'hooks/useReduxHooks';

function DepartureCompleteModal() {
	const dispatch = useAppDispatch();

	const closeModal = () => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: {
				isDepartureCheckRequired: false,
			},
		});
	};

	return (
		<ModalFrame
			title={
				<TitleWrapper>
					<img src={ICON_BADGE} alt="" width={20} height={20} />
					<Title>출국 여부 확인</Title>
				</TitleWrapper>
			}
			content={
				<GuideWrapper>
					<GuideText>출국확인이 완료되었습니다.</GuideText>
					<GuideText>
						입력된 계좌 정보로 21일 이내 환급금이 송금됩니다.
					</GuideText>
					<WarningText>
						*송금 정보 수정이 필요할 경우 송금이 지연될 수 있습니다.
					</WarningText>
				</GuideWrapper>
			}
			buttons={
				<>
					<FaqButton
						status="active"
						width="100%"
						padding="10px 0"
						fontSize={16}
						onClick={closeModal}
					>
						확인
					</FaqButton>
				</>
			}
		/>
	);
}

export default DepartureCompleteModal;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	margin-bottom: 8px;
`;
const Title = styled.strong`
	display: block;
	font-size: 16px;
`;

const GuideWrapper = styled.ul`
	display: flex;
	margin-bottom: 16px;
	flex-direction: column;
`;

const GuideText = styled.p`
	color: #5f6165;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 21px */
`;

const WarningText = styled(GuideText)`
	color: #80848a;
	margin-top: 16px;
`;
