import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { UseFormGetValues } from 'react-hook-form';
import { Spin } from 'animation';
import {
	checkEmailAuth,
	registerMedicalRefundInfo,
	sendMedicalVerificationMail,
} from 'api';
import TextButton from 'components/_common/TextButton';
import Input from 'components/_common/Input';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import {
	IRefundInfoForm,
	IRegisterRefundInfoPayload,
	ISendVerificationMailPayload,
} from 'model/types';
import Typography from 'components/_common/Typography';
import FlexWrap from 'components/_common/FlexWrap';
import useCheckEmailAuth from 'hooks/useCheckEmailAuth';

type Props = {
	setIsBtnActive: Dispatch<SetStateAction<boolean>>;
	isBankCardInquiry: boolean;
	getValues: UseFormGetValues<IRefundInfoForm>;
	isHkgNation: boolean;
	isVerified: boolean;
	setIsVerified: Dispatch<SetStateAction<boolean>>;
};
function EmailVerification({
	setIsBtnActive,
	isBankCardInquiry,
	getValues,
	isHkgNation,
	isVerified,
	setIsVerified,
}: Props) {
	const {
		email: prevEmail,
		nationality,
		passportNumber,
		verifiedEmail,
	} = useAppSelector((state) => state);
	const dispatch = useAppDispatch();

	const [error, setError] = useState<any>();
	const [email, setEmail] = useState(prevEmail || '');
	const [isEditMode, setIsEditMode] = useState(false);
	const [sentMail, setSentMail] = useState(false);
	const [isEmailCheckLoading, setIsEmailCheckLoading] = useState(false);

	const isLogin = localStorage.getItem('login');

	const { mutateAsync: checkEmailAuthMutate } = useCheckEmailAuth(
		email,
		nationality
	);

	// 이메일 수정
	const { mutate: updateEmail } = useMutation<
		any,
		AxiosError,
		IRegisterRefundInfoPayload
	>((payload) => registerMedicalRefundInfo(payload), {
		retry: false,
		onSuccess: () => {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					email,
				},
			});
		},
	});

	// 인증 이메일 보내기
	const { mutate: sendMail, isLoading } = useMutation<
		any,
		AxiosError,
		ISendVerificationMailPayload
	>((payload) => sendMedicalVerificationMail(payload), {
		retry: false,
		onSuccess: () => {
			setSentMail(true);
			alert(
				nationality === 'KOR'
					? '인증 메일을 전송했습니다.\n메일 확인 후 [인증]을 눌러주세요.'
					: nationality === 'CHN'
					? '已发送一封验证电子邮件。验证地址后, 请点击 [验证]。'
					: nationality === 'JPN'
					? '確認メールが送信されました。アドレスを確認後、[検証]を押してください。'
					: 'A verification email has been sent. After verifying the address, please press on [Verify].'
			);
		},
		onError: () => {
			alert(
				nationality === 'KOR'
					? '메일 전송에 실패하였습니다.'
					: 'Email transmission failed.'
			);
		},
	});

	useEffect(() => {
		if (!isLogin || isVerified) return;
		sendMail({
			passportNumber,
			email,
			redirectUrl:
				process.env.NODE_ENV === 'development'
					? `http://192.168.0.113:3001/email-verification/${passportNumber}/${email}`
					: `https://meditour.ktaxpay.com/email-verification/${passportNumber}/${email}`,
		});
	}, []);

	const onClickSendingMail = () => {
		if (!verifyEmailFormat(email)) return;
		updateEmail({
			passportNumber,
			accountInfo: { ...getValues(), email, isBankCardInquiry, isHkgNation },
			register: false,
		});

		sendMail({
			passportNumber,
			email,
			redirectUrl:
				process.env.NODE_ENV === 'development'
					? `http://192.168.0.113:3001/email-verification/${passportNumber}/${email}`
					: `https://meditour.ktaxpay.com/email-verification/${passportNumber}/${email}`,
		});
		setIsEditMode(false);
	};
	const onClickEdit = () => {
		setIsEditMode((prev) => !prev);
		setSentMail((prev) => !prev);
	};
	const verifyEmailFormat = (text: string): boolean => {
		if (
			text.match(
				/[a-zA-Z0-9]{1,}@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
			)
		) {
			setError(undefined);
			return true;
		} else {
			setError({
				message:
					nationality === 'KOR'
						? '이메일 형식이 유효하지 않습니다.'
						: 'The email format is not valid.',
			});
			return false;
		}
	};

	const onClickEmailCheck = () => {
		setIsEmailCheckLoading(true);
		setTimeout(() => {
			setIsEmailCheckLoading(false);
			checkEmailAuthMutate({ email, passportNumber }).then((res) => {
				if (res) {
					setIsVerified(true);
					setIsBtnActive(true);
				}
			});
		}, 1000);
	};

	useEffect(() => {
		setIsVerified(email === verifiedEmail);
	}, [verifiedEmail, email]);

	return (
		<Container>
			<Wrapper>
				<InputWrapper>
					<Input
						onChange={(e) => setEmail(e.target.value.trim())}
						value={email}
						label={nationality === 'KOR' ? '이메일' : 'Email'}
						error={error}
						placeholder="E-mail"
						margin={[0, 0, 24]}
						disabled={!isEditMode}
						isAbsolute
						height="52px"
						bottom="-20px"
					/>
				</InputWrapper>

				<BtnWrapper>
					{isLoading || isEmailCheckLoading ? (
						<>
							<LoadingButton>
								<LoadingSpinner />
							</LoadingButton>
						</>
					) : (
						<TextButton
							title={
								nationality === 'KOR'
									? isVerified
										? '인증완료'
										: '인증'
									: nationality === 'CHN'
									? isVerified
										? '验证完成'
										: '验证'
									: nationality === 'JPN'
									? isVerified
										? '認証済'
										: '検証'
									: isVerified
									? 'Verified'
									: 'Verify'
							}
							disabled={isVerified}
							onClick={sentMail ? onClickEmailCheck : onClickSendingMail}
							isActive={email}
							color={isVerified ? '#246CF6' : '#FFFFFF'}
							bgColor={isVerified ? '#E9F0FE' : '#246CF6'}
							width="80px"
						/>
					)}
				</BtnWrapper>
			</Wrapper>

			{!isVerified &&
				(sentMail && !isEditMode ? (
					<>
						<GuideText>
							{nationality === 'KOR'
								? '인증 메일을 전송했습니다. 인증 후 [인증]을 눌러주세요.'
								: nationality === 'CHN'
								? '已发送一封验证电子邮件。验证地址后, 请点击 [确认]。'
								: nationality === 'JPN'
								? '確認メールが送信されました。アドレスを確認後、[検証]を押してください。'
								: 'A verification email has been sent. After verifying the address, please press on [Verify].'}
						</GuideText>
						<GuideTextGray>
							{nationality === 'KOR'
								? '인증 메일을 받지못했다면 스팸 메일함을 확인해주세요. '
								: nationality === 'CHN'
								? '如果您没有收到邮件，请检查垃圾邮件箱。'
								: nationality === 'JPN'
								? 'メールが届かない場合は迷惑メールボックスをご確認ください。'
								: "If you didn't receive the email, please check spam box."}
						</GuideTextGray>
						<FlexWrap margin="4px 0 0 0">
							<EmailButton onClick={onClickSendingMail}>
								{nationality === 'KOR'
									? '인증 메일 재전송'
									: nationality === 'CHN'
									? '重新发送验证电子邮件'
									: nationality === 'JPN'
									? '認証メールを再送する'
									: 'Resend the verification email'}
							</EmailButton>
							<Typography size="14px" lineHeight="150%" color="#80848A">
								{' '}
								/
							</Typography>
							<EmailButton onClick={onClickEdit}>
								{' '}
								{nationality === 'KOR'
									? '메일 주소 수정하기'
									: nationality === 'CHN'
									? '编辑电子邮件地址'
									: nationality === 'JPN'
									? 'メールアドレスの編集'
									: 'Edit email address'}
							</EmailButton>
						</FlexWrap>
					</>
				) : (
					<GuideTextGray>
						{nationality === 'KOR'
							? '다음 단계를 진행하려면 [인증]을 눌러 이메일을 인증해 주세요.'
							: nationality === 'CHN'
							? '请按[验证]键验证您的电子邮件，以便进行下一步操作。'
							: nationality === 'JPN'
							? '次のステップに進むには、[検証]を押してEメールを確認してください。'
							: 'Please verify your email by pressing on the [verify] to proceed with the next step.'}
					</GuideTextGray>
				))}
		</Container>
	);
}
const Container = styled.div`
	margin-bottom: 28px;
	width: 335px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		padding: 0 20px;
	}
`;
const Wrapper = styled.div`
	position: relative;
`;
const InputWrapper = styled.div`
	width: 247px;
`;
const GuideText = styled.p`
	font-size: 14px;
	line-height: 150%;
	color: #5f6165;
	text-align: left;
	margin-top: 4px;
	white-space: pre-wrap;
`;
const GuideTextGray = styled(GuideText)`
	color: #a7a7a7;
	font-size: 12px;
	font-weight: 400;
	margin-top: 4px;
`;
const LoadingSpinner = styled.div`
	animation: ${Spin} 600ms linear infinite;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	border: 5px solid #fff;
	margin: 0 auto;
	border-top: 5px solid #246cf6;
`;
const BtnWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
`;
const EmailButton = styled.button`
	font-size: 14px;
	line-height: 150%;
	color: #246cf6;
	white-space: pre-wrap;
	text-decoration-line: underline;
`;

const LoadingButton = styled.div`
	width: 80px;
	height: 52px;
	background-color: #246cf6;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
`;
export default EmailVerification;
