import FaqTabs from 'components/MedicalFaq/FaqTabs';
import MedicalQna from 'components/MedicalFaq/MedicalQna';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ICON_EDIT from 'assets/common/icon_edit.png';
import FaqButton from 'components/MedicalFaq/FaqButton';
import { IGlobalState } from 'store';
import { useAppSelector } from 'hooks/useReduxHooks';
import FaqModal from 'components/MedicalFaq/Faq.modal';
import MedicalMyQna from 'components/MedicalFaq/MedicalMyQna';

function MedicalFaq() {
	const { nationality, existDirectRefund } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const [selectedIndex, setSelectedIndex] = useState(existDirectRefund ? 0 : 1);
	const [isOpenFaqModal, setIsOpenFaqModal] = useState(false);

	const selectTab = (index: number) => {
		setSelectedIndex(index);
	};

	return (
		<ReceiptLayout hasPadding={false}>
			{existDirectRefund && (
				<FaqTabs value={selectedIndex} onChange={selectTab} />
			)}
			<ContentWrapper>
				{selectedIndex === 0 && <MedicalQna />}
				{selectedIndex === 1 && <MedicalMyQna />}
			</ContentWrapper>
			<ButtonWrapper>
				<FaqButton
					status='opacity'
					width='100%'
					padding='12px 10px'
					fontSize={16}
					onClick={() => setIsOpenFaqModal(!isOpenFaqModal)}
				>
					<IconEdit src={ICON_EDIT} alt='' />
					{nationality === 'KOR' ? '문의하기' : 'Send inquiry'}
				</FaqButton>
			</ButtonWrapper>
			{isOpenFaqModal && <FaqModal onClose={() => setIsOpenFaqModal(false)} />}
		</ReceiptLayout>
	);
}

export default MedicalFaq;

const ContentWrapper = styled.section`
	width: 100%;
	padding-bottom: 100px;
`;

const ButtonWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 375px;
	padding: 40px 20px;
	background: linear-gradient(
		to top,
		rgb(255, 255, 255, 1) 50%,
		rgb(255, 255, 255, 0.5) 70%,
		rgb(255, 255, 255, 0.2) 85%,
		rgba(255, 255, 255, 0)
	);
	@media ${(props) => props.theme.mobile} {
		width: 100%;
		left: 0;
		transform: translateX(0);
	}
`;

const IconEdit = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 6px;
`;
