import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { format } from 'date-fns';

import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import Close from 'assets/common/close.png';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

interface IProps {
	onChange: Dispatch<SetStateAction<any>>;
	departureDate?: any;
	onClose: () => void;
}
function CalendarModal({ onChange, departureDate, onClose }: IProps) {
	const { nationality, firstRefundDate } = useAppSelector(
		(state) => state
	) as IGlobalState;

	return (
		<BackContainer>
			<ModalContainer>
				<CloseBtn src={Close} onClick={onClose} />
				<Calendar
					onChange={onChange}
					value={departureDate}
					formatDay={(_locale, date) => format(date, 'dd')}
					showNeighboringMonth={false}
					maxDetail="month"
					minDetail="month"
					next2Label={null}
					prev2Label={null}
					calendarType="US"
					minDate={new Date(firstRefundDate || '')}
				/>
				<Wrapper dir="column">
					<Title>
						{nationality === 'KOR'
							? '출국예정일 주의사항'
							: 'Expected Departure Date Precautions'}
					</Title>
					<SubTitle>
						{nationality === 'KOR' ? '① 환급대상' : '① Eligible shoppers'}
					</SubTitle>
					<Desc>
						{nationality === 'KOR'
							? `환급대상 : 입국일로부터 3개월 이내 출국자\n출국일은 본인의 입국일로부터 3개월이 초과되지 않도록 지정해주세요.`
							: 'Leave the country within three months from the purchase date, and meet the following qualifications: Foreigner (Stayed less than six months in Korea)'}
					</Desc>
					<SubTitle>
						{nationality === 'KOR'
							? '② 출국예정일 수정'
							: '② Modifying the departure date.'}
					</SubTitle>
					<Desc>
						{nationality === 'KOR'
							? '최초 환급일 기준으로 이전 일은 선택이 불가합니다. 수정은 출국일 최소 1~3일 전에 수정 바랍니다.'
							: 'You cannot select the past date based on the first refund date. Please correct it at least 1 days before the departure date'}
					</Desc>
					<TextButton
						title={nationality === 'KOR' ? '선택' : 'CHECK'}
						onClick={onClose}
						width="100%"
						margin="36px 0 16px"
						bgColor="#246CF6"
					/>
					<TextButton
						title={nationality === 'KOR' ? '취소' : 'CANCEL'}
						onClick={onClose}
						outline
						bgColor="#ffffff"
						color="#000000"
						width="100%"
						margin="0 0 20px"
					/>
				</Wrapper>
			</ModalContainer>
		</BackContainer>
	);
}
const BackContainer = styled(FlexWrap)`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 200;
	top: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.4);
	align-items: center;
`;
const ModalContainer = styled.div`
	position: relative;
	width: 335px;
	height: 790px;
	margin: 0 auto;
	background-color: #ffffff;
	padding: 60px 0;
	overflow-y: scroll;
	border-radius: 4px;
	@media ${(props) => props.theme.mobile} {
		height: 80%;
	}
`;
const CloseBtn = styled.img`
	position: absolute;
	top: 30px;
	right: 20px;
	width: 18px;
	height: 18px;
	cursor: pointer;
`;
const Wrapper = styled(FlexWrap)`
	padding: 0 20px;
`;
const Title = styled.h3`
	font-size: 16px;
	font-weight: 500;
	margin-top: 24px;
`;
const SubTitle = styled.h4`
	font-size: 14px;
	line-height: 20px;
	margin: 12px 0 6px;
`;
const Desc = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	white-space: pre-wrap;
`;
export default CalendarModal;
