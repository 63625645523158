import React, { ReactNode } from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import Close from 'assets/common/close.png';
import Icon from './Icon';

interface IProps {
	title: string;
	content: string | ReactNode;
	btnDir?: 'column' | 'row';
	btnText: string;
	subBtnText?: string;
	icon?: string;
	btnCallback?: () => void; //버튼 동작: 기본 동작(onClose)+btnCallback 실행
	subBtnCallback?: () => void; //버튼 동작: 기본 동작(onClose)+subBtnCallback 실행
	onClose: () => void;
	btnActive?: boolean;
	subBtnActive?: boolean;
	preventDefault?: boolean; //true: 버튼 기본동작 막기
}
function CustomModal({
	title,
	content,
	btnDir = 'row',
	btnText,
	subBtnText,
	icon,
	btnCallback,
	subBtnCallback,
	onClose,
	btnActive = true,
	subBtnActive = true,
	preventDefault = false,
}: IProps) {
	return (
		<Container>
			<Modal>
				<CloseIcon src={Close} onClick={onClose} />
				<FlexWrap alignItems="center">
					{icon && (
						<Icon imgUrl={icon} width="24px" height="24px" margin="0 2px 0 0" />
					)}
					<ModalTitle>{title}</ModalTitle>
				</FlexWrap>{' '}
				<Description>{content}</Description>
				<BtnContainer dir={btnDir} gap="12px">
					{subBtnText && (
						<Button
							onClick={() => {
								!preventDefault && onClose();
								subBtnCallback && subBtnCallback();
							}}
							active={subBtnActive}
							disabled={!subBtnActive}
						>
							{subBtnText}
						</Button>
					)}
					<Button
						onClick={() => {
							!preventDefault && onClose();
							btnCallback && btnCallback();
						}}
						colored
						active={btnActive}
						disabled={!btnActive}
					>
						{btnText}
					</Button>
				</BtnContainer>
			</Modal>
		</Container>
	);
}
const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Modal = styled(FlexWrap)`
	padding: 20px;
	flex-direction: column;
	align-items: center;
	width: 330px;
	background-color: white;
	border-radius: 8px;
	min-width: 400px;
`;
const ModalTitle = styled.h3`
	font-size: 22px;
	line-height: 150%;
	font-weight: 700;
	color: #1856cd;
`;
const Description = styled.p`
	font-size: 16px;
	line-height: 150%;
	margin: 12px 0 16px;
	text-align: center;
	white-space: pre-wrap;
	color: #3a3b3e;
`;
const BtnContainer = styled(FlexWrap)`
	width: 100%;
`;
const Button = styled.button<{ colored?: boolean; active: boolean }>`
	flex: 1;
	background-color: ${(props) =>
		props.active ? (props.colored ? '#246cf6' : '#fff') : '#D3E2FD'};
	color: ${(props) =>
		props.active ? (props.colored ? '#fff' : '#246cf6') : '#fff'};
	border: 1px solid #246cf6;
	border: 1px solid ${(props) => (props.active ? '#246cf6' : '#D3E2FD')};
	border-radius: 4px;
	font-size: 20px;
	line-height: 150%;
	padding: 12px 20px;
	:hover {
		opacity: 0.85;
	}
`;
const CloseIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-left: auto;
	cursor: pointer;
`;
export default CustomModal;
