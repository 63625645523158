import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';

import CheckCircle from 'assets/receipt/medical_check_circle.png';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import TextButton from 'components/_common/TextButton';
import ProgressBar from 'components/_common/ProgressBar';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { getIsFrequentCountry } from 'utils/common';
import BankInquiryGuide from 'components/MedicalRefundResult/BankInquiryGuide';

function MedicalRefundResult() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { nationality } = useAppSelector((state) => state) as IGlobalState;

	// const goToRecipt = () => {
	// 	dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: true } });
	// 	history.push(routes.medicalReceipt);
	// };
	const goToHome = () => {
		history.push(routes.medicalHome);
	};

	useEffect(() => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: { isRefundInfoRegistered: true },
		});
	}, []);

	return (
		<ReceiptLayout>
			<Img src={CheckCircle} />
			<ProgressBar percent={100} />
			<Title>
				{nationality === 'KOR'
					? `송금 정보 입력이\n완료되었습니다.`
					: `Completed`}
			</Title>
			<Description>
				{nationality === 'KOR'
					? `환급액 지급까지 21일 정도 소요됩니다.`
					: getIsFrequentCountry(nationality)
					? "We'll send your refunds\nto the information you provided.\nOn average, it takes about 21 business days."
					: "We'll send an email to request your information\nfor processing refunds within 10 days.\nPlease check your mailbox."}
				{nationality === 'JPN' && (
					<BankInquiryGuide
						title={
							<>
								<InfoTitle>
									海外送金のために銀行から問い合わせが来ることもあります。
								</InfoTitle>
								<InfoTitle>そういう時はこう答えてください。</InfoTitle>
							</>
						}
						questionTitle="ぎんこう"
						questionText="Successmodeとはどのような関係ですか?"
						answerTitle="あなた"
						answerText='Successmodeは還付窓口運営事業者として 「TAX REFUND」を代行します。 (代表者 "Ju Byeong-Cheon" に入金されることがあります。)'
					/>
				)}
				{nationality === 'CHN' && (
					<BankInquiryGuide
						title={
							<>
								<InfoTitle>因为海外汇款，银行可能会来咨询。</InfoTitle>
								<InfoTitle>那种时候请这样回答。</InfoTitle>
							</>
						}
						questionTitle="银行"
						questionText="与Successmode是什么关系?"
						answerTitle="您"
						answerText='Successmode作为退税窗口事业者代理 Tax Refund。(代表人名"JuByeong-Cheon")'
					/>
				)}
			</Description>
			<TextButton
				title={nationality === 'KOR' ? '홈으로' : 'Home'}
				color="#246CF6"
				bgColor="#FFFFFF"
				outline
				onClick={goToHome}
			/>
		</ReceiptLayout>
	);
}

export default MedicalRefundResult;

const Img = styled.img`
	width: 66px;
	height: 66px;
	margin: 200px 0 30px;

	transform: translateX(-4px);
`;

const Title = styled.h2`
	font-size: 28px;
	line-height: 36px;
	width: 410px;
	text-align: center;
	white-space: pre-wrap;
	color: #1856cd;
	font-weight: 700;
`;

const Description = styled.p`
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	margin: 12px 0 100px;
	white-space: pre-wrap;
	color: #3a3b3e;
`;

const InfoTitle = styled.span`
	display: block;
	color: #246cf6;
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
`;
