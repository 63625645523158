import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { routes } from 'routes';

import {
	fetchMedicalHome,
	medicalPassportValidate,
	verifyMedicalEmail,
} from 'api';
import LoadingView from 'components/_common/LoadingView';
import { useAppDispatch } from 'hooks/useReduxHooks';
import {
	IMedicalPassportValidateRespones,
	IVerifyEmailPayload,
} from 'model/types';
import { IMedicalHomePayload, IMedicalHomeResponse } from 'types/medical';

type Props = {
	id: string;
	verifiedEmail: string;
};
function MedicalVerifiactionRedirect() {
	const { id, verifiedEmail } = useParams() as Props;
	const passportNumber = id.toUpperCase();
	const dispatch = useAppDispatch();
	const history = useHistory();

	const passportMutation = useMutation<
		IMedicalPassportValidateRespones,
		AxiosError,
		string
	>((payload) => medicalPassportValidate(payload), {
		retry: false,
		onSuccess: ({ departureDate, customerNation, ...rest }) => {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					passportNumber,
					nationality: customerNation,
					...rest,
				},
			});
			departureDate &&
				localStorage.setItem('medicalDepartureDate', departureDate);
			customerNation &&
				localStorage.setItem('medicalCustomerNation', customerNation);
			localStorage.setItem('login', passportNumber);
			verifyMutation.mutate({ passportNumber, verifiedEmail });
		},
	});
	const verifyMutation = useMutation<number, AxiosError, IVerifyEmailPayload>(
		(payload) => verifyMedicalEmail(payload),
		{
			retry: false,
			onSuccess: () => {
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: {
						verifiedEmail,
					},
				});
				history.push(routes.medicalCustomerRefund);
			},
			onError: () => {
				alert('이메일 인증에 실패하였습니다. 다시 시도해주세요.');
			},
		}
	);

	const { mutate: homeMutation } = useMutation<
		IMedicalHomeResponse,
		AxiosError,
		IMedicalHomePayload
	>((payload) => fetchMedicalHome(payload), {
		retry: false,
		onSuccess: ({
			isRegister,
			isRead,
			paymentStatus,
			isVincentJpn,
			...rest
		}) => {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					isRefundInfoRegistered: isRegister,
					haveSeenReceipt: isRead,
					paymentStatus,
					...rest,
				},
			});
		},
	});

	const fetchHome = () => {
		homeMutation({ passportNumber: passportNumber as string });
	};

	useEffect(() => {
		passportMutation.mutate(passportNumber as string);
		fetchHome();
	}, []);

	return <LoadingView />;
}

export default MedicalVerifiactionRedirect;
