export const routes = {
	// Medical
	medical: "/",
	medicalHome: "/home",
	medicalReceipt: "/receipt",
	medicalDeparture: "/departure",
	medicalRefundInfo: "/refund-info",
	medicalCustomerRefund: "/refund",
	medicalRefundResult: "/refund-result",
	medicalFaq: "/faq",
	medicalLeaflet: "/refund-info-leaflet",
};
