import React from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { IFormattedExcel } from 'model/types';
import { comma } from 'utils/format';

interface IProps {
  data: IFormattedExcel;
}
function ExcelTable({ data }: IProps) {
  return (
    <Container>
      <Title>
        ■ 조세특례제한법 시행규칙[별지 제 68호의2서식]
        <Colored>{`<신설 2016. 3. 14>`}</Colored>
      </Title>
      <Table>
        <Row>
          <Cell flex='4' bold>
            {`의료용역공급확인서\nCertificate of Selling Medical Service for Overseas Patient`}
          </Cell>
          <Cell bold>일련번호 Serial No.</Cell>
          <Cell>{data.serialNum}</Cell>
        </Row>
        <Row height={200}>
          <Cell bold>{`의료기관\nMedical Institution`}</Cell>
          <Col flex='4'>
            <Row>
              <Cell>{` 의료기관 명칭\nName of Institution`}</Cell>
              <Cell>{`사업자 등록번호\nRegistration No.`}</Cell>
              <Cell>{`외국인환자 유치기관 등록번호\nRegistration No.`}</Cell>
              <Cell>{`전화번호\nTelephone No.`}</Cell>
            </Row>
            <Row>
              <Cell>{data.medicalName}</Cell>
              <Cell>{data.medicalBusinessNum}</Cell>
              <Cell>{data.medicalRegistNum}</Cell>
              <Cell>{data.medicalPhoneNum}</Cell>
            </Row>
            <Row>
              <Cell>{`대표자 명\nRepresentative’s Name`}</Cell>
              <Cell flex='3.02'>{`의료기관 소재지\nAddress`}</Cell>
            </Row>
            <Row>
              <Cell>{data.medicalRepresentative}</Cell>
              <Cell flex='3.02'>{data.medicalAddress}</Cell>
            </Row>
          </Col>
        </Row>
        <Row height={100}>
          <Cell bold>
            {`외국인환자 유치업자\nFacilitator\nof Foreign Patient\n(거래한 경우)`}
          </Cell>
          <Col flex='4'>
            <Row>
              <Cell>{` 유치업자 명칭\nName of Facilitator`}</Cell>
              <Cell>{`사업자 등록번호\nRegistration No.`}</Cell>
              <Cell flex='2' bold>
                {`외국인환자 유치기관 등록번호\nRegistration No.`}
              </Cell>
            </Row>
            <Row>
              <Cell></Cell>
              <Cell></Cell>
              <Cell flex='2'></Cell>
            </Row>
          </Col>
        </Row>
        <Row height={100}>
          <Cell bold>{`환자\nPatient`}</Cell>
          <Col flex='4'>
            <Row>
              <Cell>{`성명\nName in full`}</Cell>
              <Cell>{`생년월일\nDate of Birth`}</Cell>
              <Cell bold>{`국적\nNationality`}</Cell>
              <Cell bold>{`여권 등의 번호\nPassport or ID Card No.`}</Cell>
            </Row>
            <Row>
              <Cell>{data.patientName}</Cell>
              <Cell>{data.patientBirth}</Cell>
              <Cell>{data.patientNation}</Cell>
              <Cell>{data.patientPassport}</Cell>
            </Row>
          </Col>
        </Row>
        <Row>
          <Cell flex='3.015'>
            {`의료보건 용역 공급 내역\nTypes of Medical Service`}
          </Cell>
          <Cell>
            {`공급가격(부가가치세 포함)\nTotal Payment Including V.A.T`}
          </Cell>
          <Cell>{`부가가치세\nV.A.T`}</Cell>
        </Row>
        {data.productAllDto.map((item, idx) => (
          <Row key={idx}>
            <LeftCell flex='2.96'>{item.label}</LeftCell>
            <Cell flex='0.998'>{item.price && comma(item.price)}</Cell>
            <Cell>{item.vat && comma(item.vat)}</Cell>
          </Row>
        ))}
        <Row>
          <Cell flex='3.015'>합계 Total</Cell>
          <Cell>
            {data.serviceTotalPayment && comma(data.serviceTotalPayment)}
          </Cell>
          <Cell>{data.serviceTotalVat && comma(data.serviceTotalVat)}</Cell>
        </Row>
        <Row>
          <Cell>{`의료보건용역 공급일\nDate of Sale`}</Cell>
          <Cell>결제금액</Cell>
          <Cell flex='0.5'>{` 현금\nCash`}</Cell>
          <Cell flex='0.5'>{`신용카드\nCredit Card`}</Cell>
          <Cell>환급액 Amound of Refund</Cell>
          <Cell>{`구입자 서명\nConsumer's Signature`}</Cell>
        </Row>
        <Row>
          <Cell>{data.saleDate}</Cell>
          <Cell>{data.totalPayment && comma(data.totalPayment)}</Cell>
          <Cell flex='0.5'>{data.cash && comma(data.cash)}</Cell>
          <Cell flex='0.5'>{data.credit && comma(data.credit)}</Cell>
          <Cell>{data.totalRefund && comma(data.totalRefund)}</Cell>
          <Cell></Cell>
        </Row>
      </Table>
    </Container>
  );
}
const Container = styled.div`
  height: 1680px;
  padding: 70px;
  width: 100%;
`;
const Title = styled.div`
  font-size: 18px;
  display: inline;
`;
const Colored = styled(Title)`
  color: ${(props) => props.theme.darkPrimaryColor};
  margin-left: 8px;
`;
const Table = styled.div`
  width: 100%;
  margin-top: 12px;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
`;
const Row = styled(FlexWrap)<{ height?: number; style?: any }>`
  height: ${(props) => props.height || 50}px;
  width: 100%;
`;
const Col = styled(FlexWrap)<{ flex?: string }>`
  flex-direction: column;
  flex: ${(props) => props.flex || 1};
`;
const Cell = styled(FlexWrap)<{
  height?: number;
  flex?: string;
  bold?: boolean;
}>`
  height: 100%;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex || 1};
  font-weight: ${(props) => (props.bold ? 700 : 500)};
  white-space: pre-line;
`;
const LeftCell = styled(Cell)`
  justify-content: flex-start;
  padding-left: 12px;
  text-align: left;
`;
export default ExcelTable;
