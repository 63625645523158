import React, { MouseEventHandler, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getMedicalRefundList } from 'api';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import FlexWrap from 'components/_common/FlexWrap';
import LoadingView from 'components/_common/LoadingView';
import ReceiptItem from 'components/MedicalReceipt/ReceiptItem';
import ScrollToTopBtn from 'components/_common/ScrollToTopBtn';
import { routes } from 'routes';
import ICON_CS from 'assets/common/icon_cs.png';
import FaqButton from 'components/MedicalFaq/FaqButton';
import { IGlobalState } from 'store';

function MedicalRecipt() {
	const dispatch = useAppDispatch();
	const { passportNumber, existDirectRefund, nationality } = useAppSelector(
		(state) => state
	) as IGlobalState;

	const [isLatest, setIsLatest] = useState(true);
	const history = useHistory();
	const { data: refundList, isLoading } = useQuery(
		['getMedicalRefundList', passportNumber],
		() => getMedicalRefundList(passportNumber!)
	);

	const onClickFilter: MouseEventHandler<HTMLButtonElement> = (e: any) => {
		setIsLatest(e.target.name === 'LATEST');
	};

	const onClickOtherPassport = () => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: {
				passportNumber: null,
				refundAfter: true,
				printData: null,
				departureDate: null,
				nationality: null,
				isUploadRequired: false,
				isRefundInfoRegistered: false,
				isReceiptImageUploaded: false,
				isCustomerLeft: false,
				haveSeenReceipt: false,
			},
		});
		localStorage.removeItem('medicalDepartureDate');
		localStorage.removeItem('medicalCustomerNation');
		localStorage.removeItem('login');
		history.push(routes.medical);
	};

	const goToFaq = () => {
		history.push(routes.medicalFaq);
	};

	return (
		<ReceiptLayout>
			<FaqButtonWrapper>
				<FaqButton type='button' onClick={goToFaq}>
					<FaqImage src={ICON_CS} alt='' />
					{nationality === 'KOR' ? '문의하기' : 'Inquiry'}
				</FaqButton>
			</FaqButtonWrapper>
			<Title>Mobile Tax Refund Receipt</Title>
			<Description>※ No need to get it checked by customs.</Description>
			{!existDirectRefund && (
				<Button onClick={onClickOtherPassport}>Other passport inqiury</Button>
			)}
			{isLoading ? (
				<LoadingView />
			) : (
				<>
					<FilterBox justifyContent='flex-end'>
						<FilterBtn name='LATEST' active={isLatest} onClick={onClickFilter}>
							Latest
						</FilterBtn>
						<FilterBtn name='PAST' active={!isLatest} onClick={onClickFilter}>
							Past
						</FilterBtn>
					</FilterBox>
					<RefundItemList isLatest={isLatest}>
						{refundList ? (
							refundList.map((item, idx) => <ReceiptItem {...item} key={idx} />)
						) : (
							<NoContent>There is no purchase history.</NoContent>
						)}
					</RefundItemList>
					<ScrollToTopBtn />
				</>
			)}
		</ReceiptLayout>
	);
}

const FaqButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 28px;
`;

const FaqImage = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 6px;
`;

const Title = styled.h2`
	font-size: 22px;
	line-height: 150%;
	width: 100%;
	margin: 20px 0;
	font-weight: 700;
`;
const Button = styled.button`
	padding: 12px 0;
	width: 100%;
	color: #ffffff;
	background-color: #246cf6;
	margin: 8px 0 24px;
	border-radius: 40px;
	font-size: 14px;
`;
const Description = styled.p`
	font-size: 14px;
	line-height: 150%;
	text-align: left;
	width: 100%;
	margin-bottom: 12px;
	font-weight: 400;
`;
const FilterBox = styled(FlexWrap)`
	width: 100%;
	padding-top: 20px;
	border-top: 1px solid #cbccce;
`;
const FilterBtn = styled.button<{ active: boolean }>`
	color: ${(props) => (props.active ? '#000000' : '#9FA1A7')};
	font-size: 14px;
	:last-child {
		::before {
			content: ' | ';
			margin: 0 8px;
			color: '#9FA1A7';
		}
	}
`;
const RefundItemList = styled(FlexWrap)<{ isLatest: boolean }>`
	width: 100%;
	align-items: center;
	flex-direction: ${(props) => (props.isLatest ? 'column' : 'column-reverse')};
`;
const NoContent = styled.p`
	font-size: 18px;
	line-height: 26px;
	color: #9fa1a7;
	margin: 222px 0;
	@media ${(props) => props.theme.mobile} {
		font-size: 14px;
		line-height: 22px;
		text-align: left;
		margin: 222px 8px;
	}
`;

export default MedicalRecipt;
