import axios from 'axios';
import { axiosInstance, axiosRequestConfiguration } from './config';
import {
	IRegisterRefundInfoPayload,
	IGetMedicalRefundListResponse,
	IExcelBody,
	IExcelResponse,
	IUploadExcelImagePayload,
	IMedicalPassportValidateRespones,
	ISendVerificationMailPayload,
	IVerifyEmailPayload,
	IGetVerifiedEmailPayload,
	IRegisterRefundInfoResponse,
	ISendInquiryPayload,
	IUpdateKorDeparture,
} from 'model/types';
import { getFormattedTableData } from 'utils/format';
import {
	ICheckKorDepartureRespones,
	IMedicalDepartureInfoPayload,
	IMedicalHomePayload,
	IMedicalHomeResponse,
	IMyQna,
} from 'types/medical';

// const axiosInstance = axios.create(axiosRequestConfiguration);

const ADMIN_SERVER =
	process.env.NODE_ENV === 'development'
		? 'https://medicaltest.successmode.co.kr'
		: 'https://medical.successmode.co.kr';

const ERP_SERVER = 'https://erp.successmode.co.kr';

const getAxios = async <ResponseType, ParamType>(
	url: string,
	queryParams?: ParamType,
	config?: any
) =>
	axiosInstance.get<ResponseType>(url, {
		...(queryParams && { params: queryParams }),
		...config,
	});
const postAxios = async <ReturnType, BodyType, ParamType>(
	url: string,
	body: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.post<ReturnType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const patchAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.patch<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const putAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.put<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});

const deleteAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.delete<ResponseType>(url, {
		...(body && { data: body }),
		...(queryParams && { params: queryParams }),
	});

//================medical========================
export const fetchMedicalHome = async (payload: IMedicalHomePayload) => {
	const res = await postAxios<IMedicalHomeResponse, IMedicalHomePayload, null>(
		`${ADMIN_SERVER}/admin/receipt/home`,
		payload
	);
	return res.data;
};
//여권조회
export const medicalPassportValidate = async (passportNumber: string) => {
	const res = await getAxios(`${ADMIN_SERVER}/admin/receipt/${passportNumber}`);
	return res.data as IMedicalPassportValidateRespones;
};
//검증 메일 전송
export const sendMedicalVerificationMail = async (
	payload: ISendVerificationMailPayload
) => {
	const res = await postAxios<number, ISendVerificationMailPayload, null>(
		`${ADMIN_SERVER}/mail/receipt`,
		payload
	);
	return res.status;
};
//검증된 이메일 저장
export const verifyMedicalEmail = async (payload: IVerifyEmailPayload) => {
	const res = await patchAxios<number, IVerifyEmailPayload, null>(
		`${ADMIN_SERVER}/admin/receipt/mail`,
		payload
	);
	return res.status;
};
//검증된 이메일 조회
export const getMedicalVerifiedEmail = async (
	payload: IGetVerifiedEmailPayload
) => {
	const res = await postAxios<number, IGetVerifiedEmailPayload, null>(
		`${ADMIN_SERVER}/admin/receipt/mail`,
		payload
	);
	return res.status;
};
//환급 내역 조회
export const getMedicalRefundList = async (passportNumber: string) => {
	const res = await postAxios(`${ADMIN_SERVER}/admin/receipt`, {
		passportNumber,
	});
	return res.data as IGetMedicalRefundListResponse;
};
// 메디컬 계좌 정보 등록
export const registerMedicalRefundInfo = async ({
	isForTemporaryStorage,
	...payload
}: IRegisterRefundInfoPayload) => {
	await postAxios<
		IRegisterRefundInfoResponse,
		IRegisterRefundInfoPayload,
		null
	>(`${ADMIN_SERVER}/admin/receipt/account`, payload);
	return { isForTemporaryStorage: isForTemporaryStorage };
};
// 메디컬 송금정보 불러오기
export const getMedicalRefundInfo = async (passportNumber: string | null) => {
	const res = await getAxios<any, string>(
		`${ADMIN_SERVER}/admin/receipt/account/${passportNumber}`
	);
	return res.data;
};
//엑셀 데이터 조회
export const fetchExcelData = async (payload: IExcelBody) => {
	const res = await postAxios<IExcelResponse, IExcelBody, null>(
		`${ADMIN_SERVER}/admin/excel`,
		payload
	);
	return getFormattedTableData(res.data);
};
//의료용역공급확인서 이미지 변환 후 서버에 저장
export const uploadExel = async (payload: IUploadExcelImagePayload) => {
	const res = await postAxios<string, FormData, null>(
		`${ADMIN_SERVER}/admin/refund/upload`,
		payload.formData
	);
	return res.data;
};

// 전액결제 출국날짜 이메일 입력
export const updateMedicalDepartureInfo = async (
	payload: IMedicalDepartureInfoPayload
) => {
	const res = await postAxios<string, IMedicalDepartureInfoPayload, null>(
		`${ADMIN_SERVER}/admin/receipt/departure`,
		payload
	);
	return res.data;
};
//이메일 인증여부 확인
export const checkEmailAuth = async (payload: any) => {
	const res = await postAxios<any, any, null>(
		`${ADMIN_SERVER}/admin/receipt/mail-check`,
		payload
	);
	return res.data;
};
// 리플렛 고객 송금 정보 입력
export const getRefundInfoData = async (payload: FormData) => {
	const res = await postAxios<string, FormData, null>(
		`${ERP_SERVER}/customer/fault/leaflet`,
		payload
	);
	return res.data;
};
// 나의 문의 내역 조회
export const getMyQnaList = async (passportNumber: string) => {
	const res = await getAxios(`${ADMIN_SERVER}/question/${passportNumber}`);
	return res.data as IMyQna[];
};
// 문의하기
export const sendMedicalInquiry = async (payload: ISendInquiryPayload) => {
	const res = await postAxios(`${ADMIN_SERVER}/question`, payload);
	return res.status;
};
// 문의 답변 읽음처리
export const updateNewAnswer = async (passportNumber: string) => {
	const res = await patchAxios(`${ADMIN_SERVER}/question/${passportNumber}`);
	return res.data;
};
// 한국인 출국확인 필요여부 조회
export const checkKorDeparture = async (passportNumber: string) => {
	const res = await postAxios(
		`${ADMIN_SERVER}/admin/receipt/check-kor-departure`,
		{ passportNumber }
	);
	return res.data as ICheckKorDepartureRespones;
};
// 한국인 출국여부 업데이트
export const updateKorDeparture = async (payload: IUpdateKorDeparture) => {
	const res = await postAxios(
		`${ADMIN_SERVER}/admin/receipt/kor-departure`,
		payload
	);
	return res.data;
};
