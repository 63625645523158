import React, { ChangeEventHandler, FormEventHandler } from 'react';
import styled from 'styled-components';

import Input from 'components/_common/Input';
import TextButton from 'components/_common/TextButton';
import PassportImage from 'assets/receipt/passport.png';
import Typography from 'components/_common/Typography';
import { IErrorState } from 'pages/MedicalPassportValidate';

interface IProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: IErrorState;
  passportNumber: string;
}
function PassportInputForm({
  onSubmit,
  onChange,
  error,
  passportNumber,
}: IProps) {
  return (
    <>
      <Typography
        size='1.375rem'
        lineHeight='1.875rem'
        fontWeight='400'
        color='#3A3B3E'
      >
        Please enter your
        <br />
        <Typography fontWeight='500'>passport number</Typography>
      </Typography>
      <PassportImg src={PassportImage} />
      <Form onSubmit={onSubmit}>
        <Input
          value={passportNumber}
          onChange={onChange}
          label='Passport No'
          isRequired
          placeholder='ex) M123A4567'
          maxLength={13}
          error={error}
          width='335px'
        />
      </Form>
      <TextButton
        isActive={!!passportNumber}
        title='CHECK'
        bottomFixed
        bgColor='#246CF6'
        onClick={onSubmit}
      />
    </>
  );
}
const Form = styled.form`
  position: relative;
`;
const PassportImg = styled.img`
  width: 100%;
  margin: 1.75rem 0 1.25rem;
`;
export default PassportInputForm;
