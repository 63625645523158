import { Fragment, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { fetchMedicalHome } from 'api';
import ReceiptLayout from 'components/MedicalReceipt/ReceiptLayout';
import TopSection from 'components/MedicalHome/TopSection';
import BottomSection from 'components/MedicalHome/BottomSection';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { IMedicalHomePayload, IMedicalHomeResponse } from 'types/medical';
import ICON_RESTART from 'assets/common/icon_restart_refund.png';
import LoadingView from 'components/_common/LoadingView';
import FaqAnswerModal from 'components/MedicalFaq/FaqAnswer.modal';
import DepartureConfirmModal from 'components/MedicalHome/DepartureModal/DepartureConfirm.modal';

function MedicalHome() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const {
		passportNumber,
		nationality,
		hasNewAnswer,
		isDepartureCheckRequired,
	} = useAppSelector((state) => state) as IGlobalState;

	const onCloseModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};
	//@ts-ignore
	const { mutate: homeMutation, isLoading } = useMutation<
		IMedicalHomeResponse,
		AxiosError,
		IMedicalHomePayload
	>((payload) => fetchMedicalHome(payload), {
		retry: false,
		onSuccess: ({
			isRegister,
			isRead,
			paymentStatus,
			isVincentJpn,
			...rest
		}) => {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					isRefundInfoRegistered: isRegister,
					haveSeenReceipt: isRead,
					paymentStatus,
					...rest,
				},
			});

			// 빈센트 병원만 송금중 상태일때 모달
			// if (
			// 	nationality === 'JPN' &&
			// 	isRegister &&
			// 	paymentStatus === 'PAYMENT_WAIT' &&
			// 	isVincentJpn
			// ) {
			// 	dispatch({
			// 		type: 'UPDATE_HOME_MODAL_STATE',
			// 		payload: {
			// 			isActive: true,
			// 			type: 'CUSTOM',
			// 			data: {
			// 				title: '送金案内',
			// 				content: `8月1日付けで大量送金が再開され\n遅延した払い戻しの件は8月1日から順次\n返金される予定です。`,
			// 				btnText: '確認',
			// 				icon: ICON_RESTART,
			// 				btnCallback: () => {},
			// 			},
			// 		},
			// 	});
			// }
		},
	});
	const fetchHome = () => {
		homeMutation({ passportNumber: passportNumber as string });
	};

	useEffect(() => {
		fetchHome();
	}, [passportNumber]);

	useEffect(() => {
		const preventGoBack = () => {
			onCloseModal();
			history.go(1);
		};
		window.addEventListener('popstate', preventGoBack);
		return () => window.removeEventListener('popstate', preventGoBack);
	}, []);

	return (
		<ReceiptLayout hasPadding={false}>
			{isLoading ? (
				<LoadingView />
			) : (
				<Fragment>
					<TopSection />
					<BottomSection />
				</Fragment>
			)}
			{hasNewAnswer && <FaqAnswerModal />}
			{nationality === 'KOR' && isDepartureCheckRequired && (
				<DepartureConfirmModal />
			)}
		</ReceiptLayout>
	);
}
export default MedicalHome;
