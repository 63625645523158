import styled from 'styled-components';

import ArrowIcon from 'assets/common/chevron_right_gray.png';
import ActiveArrowIcon from 'assets/common/chevron_right_blue.png';
import CheckActive from 'assets/home/check_circle_active.png';
import CheckInactive from 'assets/home/check_circle_inactive.png';
import FlexWrap from 'components/_common/FlexWrap';
import { ReactNode, useState } from 'react';

interface IProps {
  title: string;
  description: string;
  onClick?: () => void;
  isCompleted: boolean;
  margin?: string;
  isToggle?: boolean;
  subContentComponent?: ReactNode;
}
function BtnWithIcon({
  title,
  description,
  onClick,
  isCompleted,
  margin,
  isToggle,
  subContentComponent,
}: IProps) {
  const [isToggleActive, setIsToggleActive] = useState(false);

  const onClickToggle = () => {
    setIsToggleActive((prev) => !prev);
  };

  return (
    <Container active={isCompleted} margin={margin}>
      <ButtonSection onClick={isToggle ? onClickToggle : onClick}>
        <FlexWrap
          alignItems='center'
          justifyContent='space-between'
          margin={description === '' ? '0' : '0 0 8px'}
        >
          <Icon src={isCompleted ? CheckActive : CheckInactive} />
          <Title active={isCompleted}>{title}</Title>
          <Arrow
            src={isCompleted ? ActiveArrowIcon : ArrowIcon}
            isOpen={isToggleActive}
          />
        </FlexWrap>
        <Description active={isCompleted}>{description}</Description>
      </ButtonSection>
      {isToggleActive && subContentComponent}
    </Container>
  );
}

const Container = styled.div<{
  active?: boolean;
  margin?: string;
}>`
  position: relative;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.2);
  border: 1.5px solid ${(props) => (props.active ? '#E9F0FE' : '#80848A')};
  background-color: ${(props) => (props.active ? '#E9F0FE' : '#fff')};
  transition: all 150ms ease-in;
  padding: 16px 14px;
  width: 100%;
  margin: ${(props) => props.margin || 0};
`;
const ButtonSection = styled.button`
  width: 100%;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
const Arrow = styled.img<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  transition: 300ms all ease-in;
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;
const Title = styled.h4<{ active: boolean }>`
  font-size: 16px;
  line-height: 150%;
  flex: 1;
  text-align: left;
  margin-left: 8px;
  font-weight: 600;
  color: ${(props) => (props.active ? '#1856CD' : '#4D4D4D')};
`;
const Description = styled.p<{ active: boolean }>`
  text-align: left;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => (props.active ? '#5F6165' : '#666666')};
  white-space: pre-wrap;
`;
export default BtnWithIcon;
