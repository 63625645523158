import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import Refresh from 'assets/common/refresh.png';
import { fetchExcelData, uploadExel } from 'api';
import { Spin } from 'animation';
import NoImageIcon from 'assets/receipt/no_image.png';
import FlexWrap from 'components/_common/FlexWrap';
import ImagePreviewModal from 'components/_common/ImagePreviewModal';
import ExcelTable from 'components/MedicalReceipt/ExcelTable';
import {
  IMedicalRefund,
  IFormattedExcel,
  IUploadExcelImagePayload,
  IExcelBody,
} from 'model/types';
import {
  convertComponentToImage,
  dataURItoBlob,
  getProductType,
} from 'utils/common';
import { comma } from 'utils/format';

function ReceiptItem({
  hospitalName,
  mainProductType,
  productCount,
  refundIndex,
  price,
  refund,
  receiptPath,
  touristIndex,
}: IMedicalRefund) {
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const excelRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(receiptPath);
  const priceInfo = [
    [
      'Types of Medical Service',
      `${getProductType(mainProductType)} ${
        productCount > 1 ? `외 ${productCount - 1}건` : ''
      }`,
    ],
    ['Payment', `₩ ` + comma(price)],
    ['Amount of Refund', `₩ ` + comma(refund)],
  ];

  const { data, mutate: fetchMutate } = useMutation<
    IFormattedExcel,
    AxiosError,
    IExcelBody
  >('excelData', (payload) => fetchExcelData(payload), {
    retry: false,
    onError: () => {
      alert(`이미지를 불러오는데 실패하였습니다.\n잠시 후 다시 시도해주세요`);
      setIsLoading(false);
    },
    onSuccess: () => {
      setTimeout(uploadExcelImage, 500);
    },
  });

  const onTogglePreview = () => {
    setIsPreviewModal(!isPreviewModal);
  };

  const uploadExcelImage = async () => {
    if (excelRef) {
      const dataUrl = await convertComponentToImage(
        excelRef.current as HTMLElement
      );
      const formData = new FormData();
      formData.append('file', dataURItoBlob(dataUrl));
      formData.append('refundId', refundIndex.toString());
      uploadMutate({ formData });
    }
  };

  const { mutate: uploadMutate } = useMutation<
    string,
    AxiosError,
    IUploadExcelImagePayload
  >((payload) => uploadExel(payload), {
    retry: false,
    onError: () => {
      alert(`이미지를 불러오는데 실패하였습니다.\n잠시 후 다시 시도해주세요`);
      setIsLoading(false);
    },
    onSuccess: (s3Url) => {
      setImageUrl(s3Url);
      setIsLoading(false);
    },
  });

  const makeReceiptFile = () => {
    setIsLoading(true);
    fetchMutate({
      touristIndex: Number(touristIndex),
      refundIndex: Number(refundIndex),
    });
  };

  useEffect(() => {
    if (!imageUrl) {
      makeReceiptFile();
    }
  }, []);

  return (
    <>
      <Container dir='column'>
        {imageUrl && isPreviewModal && (
          <ImagePreviewModal
            imgUrl={imageUrl + `?${Math.random().toString()}`}
            onTogglePreview={onTogglePreview}
            setIsPreviewModal={setIsPreviewModal}
          />
        )}
        <Title>{hospitalName}</Title>
        <ContentContainer>
          <PriceItem dir='column' justifyContent='center'>
            {priceInfo.map(([key, value]) => (
              <PriceItemText key={key} dir='column'>
                <BoldText>{key}</BoldText>
                <Text>{value}</Text>
              </PriceItemText>
            ))}
          </PriceItem>
          {isLoading && (
            <NoImageContainer
              dir='column'
              justifyContent='center'
              alignItems='center'
            >
              <LoadingSpinner />
            </NoImageContainer>
          )}
          {!isLoading && imageUrl && (
            <ProductImage
              src={imageUrl + `?${Math.random().toString()}`}
              onClick={onTogglePreview}
            />
          )}
          {!isLoading && !imageUrl && (
            <NoImageContainer
              dir='column'
              justifyContent='center'
              alignItems='center'
            >
              <NoImage src={NoImageIcon} />
              <NoImageText>No image</NoImageText>
            </NoImageContainer>
          )}
        </ContentContainer>
        <RefreshBtn onClick={makeReceiptFile}>
          <RefreshIcon src={Refresh} />
          Refresh image
        </RefreshBtn>
      </Container>
      {data && (
        <Hidden>
          <ExcelContainer ref={excelRef}>
            <ExcelTable data={data} />
          </ExcelContainer>
        </Hidden>
      )}
    </>
  );
}
const Container = styled(FlexWrap)`
  padding: 30px 0;
  border-bottom: 1px solid #cbccce;
  width: 100%;
  :last-child {
    margin-bottom: 24px;
  }
`;

const ContentContainer = styled(FlexWrap)``;
const PriceItem = styled(FlexWrap)`
  flex: 1;
`;
const PriceItemText = styled(FlexWrap)``;
const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 700;
`;
const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;
const BoldText = styled(Text)`
  font-weight: 500;
  margin: 0;
`;

const ProductImage = styled.img`
  width: 152px;
  height: 126px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #9fa1a7;
  :hover {
    opacity: 70%;
  }
  transition: all 300ms ease-in;
`;
const NoImageContainer = styled(FlexWrap)`
  background-color: #ececec;
  border: 1px solid #9fa1a7;
  border-radius: 4px;
  width: 152px;
  height: 126px;
`;
const NoImage = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 8px;
`;
const NoImageText = styled.span`
  font-size: 11px;
  color: #9fa1a7;
  text-align: center;
  line-height: 20px;
`;
const RefreshBtn = styled.button`
  font-size: 14px;
  display: flex;
  width: 140px;
  align-items: center;
  margin: 0 auto;
`;
const RefreshIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
const Hidden = styled.div`
  opacity: 0;
`;
const ExcelContainer = styled.div<{ ref: any }>`
  width: 1200px;
  position: absolute;
  top: -2500px;
  left: 400px;
`;
const LoadingSpinner = styled.div`
  animation: ${Spin} 700ms linear infinite;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 4px solid #9fa1a7;
  border-top: 4px solid #ececec;
  margin: 12px 0;
`;
export default ReceiptItem;
